import React from "react";
import { marked } from "marked";
import DOMPurify from "dompurify";
import { CSSProperties } from "react";
import { useTheme } from "@mui/material/styles";

interface MarkdownViewerProps {
	markdownText: string;
}

const MarkdownViewer: React.FC<MarkdownViewerProps> = ({ markdownText }) => {
	const theme = useTheme();
	// Convert markdown text to HTML
	const createMarkup = () => {
		const rawMarkup = marked(markdownText) as string;
		const sanitizedMarkup = DOMPurify.sanitize(rawMarkup);
		return { __html: sanitizedMarkup };
	};

	// Styles to prevent overflow
	const containerStyle: CSSProperties = {
		overflowWrap: "break-word", // Correctly typed
		overflowY: "auto", // Correctly typed
		maxHeight: "100%", // Correctly typed
		color: theme.palette.text.primary,
	};

	return (
		<div style={containerStyle} dangerouslySetInnerHTML={createMarkup()} />
	);
};

export default MarkdownViewer;
