import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
	TextField,
	Slider,
	FormGroup,
	Box,
	Paper,
	Container,
	IconButton,
	Tooltip,
	CircularProgress,
	useTheme,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import * as userActions from "../../../actions/userActions";
import {
	ChatButtonStyled,
	ChatItemContainer,
	HeaderTypography,
	StyledFieldTypography,
} from "../../../ux/chatStyles";
import chatHeaderCloseIcon from "../../../assets/chatHeaderClose.svg";
import buildASurveyIcon from "../../../assets/buildASurveyNew.svg";
import buildASurveyIconLight from "../../../assets/buildASurveyLight.svg";
import { bindActionCreators } from "../../../actions/actionCreators";
import { TodoContext } from "../../todoContext";
import MarkdownViewer from "../../../utils/MarkdownRenderer";
import { ToggleButton } from "./components/ToggleButton";
import { copyToClipboard } from "../../../utils/chatUtils";
import Loader from "../../loader";
import { formatMessage } from "../../../utils/chatUtils";

interface BuildSurveyItemProps {
	onSendMessage: (message: any) => void;
	onClose: () => void;
}

const BuildSurveyItem: React.FC<BuildSurveyItemProps> = ({
	onSendMessage,
	onClose,
}) => {
	const { dispatch } = useContext(TodoContext);
	const [loading, setLoading] = useState<boolean>(false); // New loading state
	const [surveyPurpose, setSurveyPurpose] = useState<string[]>([]);
	const [surveyPurposeProductReview, setSurveyPurposeProductReview] =
		useState<string>(""); // New state for "Which product?"
	const [surveyPurposeOther, setSurveyPurposeOther] = useState<string>("");
	const [audience, setAudience] = useState<string[]>([]);
	const [audienceOther, setAudienceOther] = useState<string>("");
	const [questionsCount, setQuestionsCount] = useState<number>(3);
	const [toneOfVoice, setToneOfVoice] = useState<string[]>([]);
	const [toneOfVoiceOther, setToneOfVoiceOther] = useState<string>("");
	const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
	const [response, setResponse] = useState<string | null>(null);
	const [copied, setCopied] = useState<boolean>(false);
	const [currentStreamId, setCurrentStreamId] = useState<string | null>(null);
	const [streamedResponse, setStreamedResponse] = useState<string>("");
	const responseRef = useRef<HTMLDivElement>(null);
	const [threadId, setThreadId] = useState<string | null>(null);

	const theme = useTheme();
	const themeType = theme.palette.mode;

	const actions = useMemo(
		() => ({
			users: bindActionCreators(
				userActions,
				dispatch
			) as unknown as userActions.UserActions,
		}),
		[dispatch]
	);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const handleSelectionChange = (
		value: string,
		currentValues: string[],
		setValues: React.Dispatch<React.SetStateAction<string[]>>,
		setOtherValue?: React.Dispatch<React.SetStateAction<string>>,
		setProductReviewValue?: React.Dispatch<React.SetStateAction<string>> // Add new parameter for product review
	) => {
		if (currentValues.includes(value)) {
			setValues(currentValues.filter((item) => item !== value));
			if (value === "Other") {
				setOtherValue && setOtherValue("");
			}
			if (value === "Product Review") {
				// Check for "Product Review" deselection
				setProductReviewValue && setProductReviewValue(""); // Clear the product review input
			}
		} else {
			setValues([...currentValues, value]);
			if (value === "Product Review") {
				// Check for "Product Review" selection
				// No need to set anything here, the input field visibility is handled by its inclusion in surveyPurpose
			}
		}
	};

	// Form submission function
	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setLoading(true);

		const modifiedSurveyPurpose = surveyPurpose.map((purpose) => {
			if (purpose === "Product Review" && surveyPurposeProductReview) {
				return `Product Review: ${surveyPurposeProductReview}`;
			}
			return purpose;
		});

		if (surveyPurposeOther) {
			modifiedSurveyPurpose.push(`Other: ${surveyPurposeOther}`);
		}

		const surveyData = {
			surveyPurpose: modifiedSurveyPurpose,
			audience: audienceOther
				? [...audience, `Other: ${audienceOther}`]
				: audience,
			questionsCount,
			toneOfVoice: toneOfVoiceOther
				? [...toneOfVoice, `Other: ${toneOfVoiceOther}`]
				: toneOfVoice,
		};

		const streamId = Date.now().toString();
		setCurrentStreamId(streamId);

		try {
			await actions.users.createSurveyStream(
				surveyData,
				(chunk) => {
					console.log("Chunk received", chunk);
					setStreamedResponse((prev) => {
						const threadIdMatch = chunk.match(/threadId:([^\s]+)/);
						if (threadIdMatch) {
							setThreadId(threadIdMatch[1]);
							return prev + chunk.replace(/threadId:[^\s]+/, "");
						}
						return prev + chunk;
					});
					if (responseRef.current) {
						responseRef.current.scrollTop = responseRef.current.scrollHeight;
					}
				},
				() => {
					setIsSubmitted(true);
					setLoading(false);
					setCurrentStreamId(null);
				},
				(error) => {
					console.error("Submission failed", error);
					setLoading(false);
					setCurrentStreamId(null);
				}
			);
		} catch (error) {
			console.error("Submission failed", error);
			setLoading(false);
			setCurrentStreamId(null);
		}
	};

	const surveyPurposes = [
		"Product Review",
		"Brand Presence",
		"Support Review",
		"CSAT",
		"Other",
	];
	const audiences = [
		"Everyone",
		"Never purchased",
		"Loyal customer",
		"Recent 30-day order",
		"120-day engagement",
		"Other",
	];
	const toneOfVoiceOptions = [
		"Informative",
		"Inspational",
		"Formal",
		"Persuasive",
		"Friendly",
		"Humorous",
		"Professional",
		"Other",
	];

	if (isSubmitted || streamedResponse) {
		return (
			<ChatItemContainer id="chat-container">
				<Paper
					style={{
						marginTop: "40px",
						marginBottom: "20px",
						borderRadius: "0.75rem",
						padding: "20px",
					}}
				>
					<Container
						sx={{ display: "flex", alignItems: "center", padding: "10px" }}
					>
						<img
							src={
								themeType === "light" ? buildASurveyIconLight : buildASurveyIcon
							}
							alt="Build a Survey"
							style={{ marginBottom: "2px" }}
						/>
						<HeaderTypography
							variant="h6"
							sx={{ display: "flex", alignItems: "center" }}
						>
							Survey Submission
						</HeaderTypography>
						<IconButton onClick={onClose} sx={{ marginLeft: "auto" }}>
							<img src={chatHeaderCloseIcon} alt="Minimize" />
						</IconButton>
					</Container>
					<div
						ref={responseRef}
						style={{
							color: "#FFF",
							wordWrap: "break-word",
							whiteSpace: "pre-wrap",
							display: "flex",
							alignItems: "flex-start",
							maxHeight: "70vh",
							marginTop: "20px",
							overflowY: "auto",
						}}
					>
						<MarkdownViewer markdownText={formatMessage(streamedResponse)} />
						<Tooltip title="Copy">
							<IconButton
								onClick={() => copyToClipboard(streamedResponse, setCopied)}
								size="small"
							>
								{copied ? (
									<CheckCircleIcon
										style={{ color: theme.palette.success.main }}
									/>
								) : (
									<ContentCopyIcon
										style={{ color: theme.palette.primary.main }}
									/>
								)}
							</IconButton>
						</Tooltip>
					</div>
				</Paper>
			</ChatItemContainer>
		);
	}

	return (
		<ChatItemContainer id="chat-container">
			<Paper
				style={{
					marginTop: "20px",
					marginBottom: "20px",
					// background: "#1c1a21",
					borderRadius: "0.75rem",
					padding: "20px",
				}}
			>
				<Container
					sx={{
						display: "flex",
						alignItems: "center",
						padding: "10px",
					}}
				>
					<img
						src={
							themeType === "light" ? buildASurveyIconLight : buildASurveyIcon
						}
						alt="Build a Survey"
						style={{ marginBottom: "2px" }}
					/>
					<HeaderTypography
						variant="h6"
						sx={{ display: "flex", alignItems: "center" }}
					>
						Build a Survey
					</HeaderTypography>
					<IconButton onClick={onClose} sx={{ marginLeft: "auto" }}>
						<img src={chatHeaderCloseIcon} alt="Minimize" />
					</IconButton>
				</Container>
				{loading ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							width: "100%",
							padding: "20px",
						}}
					>
						<Loader />
					</div>
				) : (
					<FormGroup>
						<Box>
							<StyledFieldTypography gutterBottom>
								What is the purpose of the survey?
							</StyledFieldTypography>
							{surveyPurposes.map((purpose) => (
								<ToggleButton
									key={purpose}
									selected={surveyPurpose.includes(purpose)}
									onClick={() =>
										handleSelectionChange(
											purpose,
											surveyPurpose,
											setSurveyPurpose,
											setSurveyPurposeOther
										)
									}
								>
									{purpose}
								</ToggleButton>
							))}
							{surveyPurpose.includes("Product Review") && ( // Conditionally render input for "Which product?"
								<div>
									<StyledFieldTypography gutterBottom>
										Which product?
									</StyledFieldTypography>
									<TextField
										label="Please specify"
										variant="outlined"
										value={surveyPurposeProductReview}
										onChange={(e) =>
											setSurveyPurposeProductReview(e.target.value)
										}
										sx={{ mt: 2, width: "100%" }}
									/>
								</div>
							)}
							{surveyPurpose.includes("Other") && (
								<div>
									<StyledFieldTypography gutterBottom>
										Which survey would you like to create?
									</StyledFieldTypography>
									<TextField
										label="Please specify"
										variant="outlined"
										value={surveyPurposeOther}
										onChange={(e) => setSurveyPurposeOther(e.target.value)}
										// take the whole row
										sx={{ mt: 2, width: "100%" }}
									/>
								</div>
							)}
						</Box>

						<Box mt={2}>
							<StyledFieldTypography gutterBottom>
								Now, let's select our audience:
							</StyledFieldTypography>
							{audiences.map((audienceOption) => (
								<ToggleButton
									key={audienceOption}
									selected={audience.includes(audienceOption)}
									onClick={() =>
										handleSelectionChange(
											audienceOption,
											audience,
											setAudience,
											setAudienceOther
										)
									}
								>
									{audienceOption}
								</ToggleButton>
							))}
							{audience.includes("Other") && (
								<div>
									<StyledFieldTypography gutterBottom>
										Which audience?
									</StyledFieldTypography>
									<TextField
										label="Please specify"
										variant="outlined"
										value={audienceOther}
										onChange={(e) => setAudienceOther(e.target.value)}
										sx={{ mt: 2, width: "100%" }}
									/>
								</div>
							)}
						</Box>

						<Box mt={2}>
							<StyledFieldTypography gutterBottom>
								How many questions would you like the survey to have?
							</StyledFieldTypography>
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<Slider
									aria-label="Questions Count"
									value={questionsCount}
									onChange={(_, newValue) =>
										setQuestionsCount(newValue as number)
									}
									step={1}
									marks
									min={1}
									max={10}
									valueLabelDisplay="auto"
									color="secondary"
								/>
								<Box
									sx={{
										minWidth: 55,
										marginLeft: 2,
										textAlign: "center",
										color: "#fff",
									}}
									// make it not selectable or clickable
									style={{ pointerEvents: "none" }}
								>
									<TextField
										id="questions-count-display"
										value={questionsCount}
										InputProps={{
											readOnly: true,
											style: { textAlign: "center" },
										}}
										variant="outlined"
										size="small"
										style={{ maxWidth: "3.2em" }}
										sx={{
											marginLeft: "auto",
											marginRight: "auto",
											border: "1px solid #938F99",
											borderRadius: "0.5rem",
											input: { textAlign: "center", marginTop: "0.1rem" },
										}}
										// make is not selectable or clickable
										inputProps={{ style: { pointerEvents: "none" } }}
									/>
								</Box>
							</Box>
						</Box>

						<Box mt={2}>
							<StyledFieldTypography gutterBottom>
								What should be the tone of voice?
							</StyledFieldTypography>
							{toneOfVoiceOptions.map((tone) => (
								<ToggleButton
									key={tone}
									selected={toneOfVoice.includes(tone)}
									onClick={() =>
										handleSelectionChange(
											tone,
											toneOfVoice,
											setToneOfVoice,
											setToneOfVoiceOther
										)
									}
								>
									{tone}
								</ToggleButton>
							))}
							{toneOfVoice.includes("Other") && (
								<div>
									<StyledFieldTypography gutterBottom>
										Please specify the tone of voice
									</StyledFieldTypography>
									<TextField
										label="Please specify"
										variant="outlined"
										value={toneOfVoiceOther}
										onChange={(e) => setToneOfVoiceOther(e.target.value)}
										sx={{ mt: 2, width: "100%" }}
									/>
								</div>
							)}
						</Box>

						<Box mt={2}>
							<ChatButtonStyled
								variant="contained"
								onClick={handleSubmit}
								disabled={loading} // Disable the button when loading
							>
								{loading ? (
									<CircularProgress size={24} /> // Show loading spinner when loading
								) : (
									"Submit"
								)}
							</ChatButtonStyled>
						</Box>
					</FormGroup>
				)}
			</Paper>
		</ChatItemContainer>
	);
};

export default BuildSurveyItem;
