// Import necessary components and functions
import { createTheme } from "@mui/material";

// Create a custom theme
export const theme = createTheme({
	typography: {
		fontFamily: "Gabarito, sans-serif",
	},
	palette: {
		mode: "dark",
		primary: {
			main: "#DCF032", // primary color (orange)
			contrastText: "#fff", // text color on primary color
		},
		secondary: {
			main: "#1B1B21",
			contrastText: "#000", // text color on secondary color
		},
		error: {
			main: "#ff0000", // error color (red)
		},
		warning: {
			main: "#ffaa00", // warning color (yellow)
		},
		info: {
			main: "#0000ff", // info color (blue)
		},
		success: {
			main: "#00ff00", // success color (green)
		},
		text: {
			primary: "#FFFFFF", // Set default text color to white
			secondary: "#29273E", // Set secondary text color to black
		},
		background: {
			default: "#131318", // Set default background color to black
			paper: "#1c1a21", // Set paper background color to dark grey
		},
		grey: {
			100: "#202029", // Set grey color to dark grey
			200: "#7777AB", // Set grey color to light grey
			500: "#090910",
		},
	},
	components: {
		// Override styles for the InputLabel component globally
		MuiInputLabel: {
			styleOverrides: {
				root: {
					color: "#7777AB",
					// When the label is shrunk (e.g., TextField is focused or filled), ensure it remains white
					"&.Mui-focused": {
						color: "#fff",
					},
				},
			},
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					minWidth: "5rem",
				},
			},
		},
		// change the color of border on focused input
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					"& .MuiOutlinedInput-notchedOutline": {
						borderColor: "#29273E", // replace with your desired color
						border: "1px solid #29273E", // replace with your desired color
						borderRadius: "0.7rem",
					},
					"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
						borderColor: "#DCF032", // replace with your desired color
					},
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: "none",
					fontWeight: 600,
					color: "#FFFFFF",
					borderRadius: "0.7rem",
					minWidth: "7rem",
					height: "2.5rem",
				},
			},
		},
		// change default color of all typography components to white
		MuiTypography: {
			styleOverrides: {
				root: {
					color: "#fff",
				},
				subtitle1: {
					color: "#7777AB",
				},
			},
		},

		// change slider color
		MuiSlider: {
			styleOverrides: {
				root: {
					color: "#DCF032",
				},
			},
		},
	},
});

export const lightTheme = createTheme({
	typography: {
		fontFamily: "Gabarito, sans-serif",
	},
	palette: {
		mode: "light",
		primary: {
			main: "#DCF032", // primary color (orange)
			contrastText: "#000", // text color on primary color
		},
		secondary: {
			main: "#F8F8FF",
			contrastText: "#000000", // text color on secondary color
		},
		error: {
			main: "#ff0000", // error color (red)
		},
		warning: {
			main: "#ffaa00", // warning color (yellow)
		},
		info: {
			main: "#0000ff", // info color (blue)
		},
		success: {
			main: "#00ff00", // success color (green)
		},
		text: {
			primary: "#000000", // Set default text color to black
			secondary: "#29273E", // Set secondary text color to dark grey
		},
		background: {
			default: "#F1F1FF", // Set default background color to white
			paper: "#FFFFFF", // Set paper background color to light grey
			// set something else to #131318
		},
		grey: {
			100: "#FFFFFF", // Set grey color to light grey
			200: "#7777AB", // Set grey color to light grey
			500: "#F1F1FF",
		},
	},
	components: {
		// Override styles for the InputLabel component globally
		MuiInputLabel: {
			styleOverrides: {
				root: {
					color: "#29273E",
					// When the label is shrunk (e.g., TextField is focused or filled), ensure it remains black
					"&.Mui-focused": {
						color: "#29273E",
					},
				},
			},
		},

		MuiListItemIcon: {
			styleOverrides: {
				root: {
					minWidth: "5rem",
				},
			},
		},
		// change the color of border on focused input
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					"& .MuiOutlinedInput-notchedOutline": {
						borderColor: "#29273E", // replace with your desired color
						border: "1px solid #29273E", // replace with your desired color
						borderRadius: "0.7rem",
					},
					"&.Mui-focused .MuiOutlinedInput-notchedOutline": {
						borderColor: "#DCF032", // replace with your desired color
					},
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: "none",
					fontWeight: 600,
					color: "#29273E",
					borderRadius: "0.7rem",
					minWidth: "7rem",
					height: "2.5rem",
				},
				// add active color
			},
		},
		// change default color of all typography components to black
		MuiTypography: {
			styleOverrides: {
				root: {
					color: "#000",
				},
				subtitle1: {
					color: "#29273E",
				},
				body1: {
					// typically used for paragraph text
					color: "black", // replace with your desired color
				},
				body2: {
					// typically used for paragraph text
					color: "black", // replace with your desired color
				},
			},
		},

		// change slider color
		MuiSlider: {
			styleOverrides: {
				root: {
					color: "#DCF032",
				},
			},
		},
	},
});
