import React, { useState, useEffect, useRef } from "react";
import {
	Container,
	List,
	ListItem,
	Paper,
	IconButton,
	Tooltip,
	useTheme,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Added import for CheckCircle icon
import {
	BotListItemText,
	ChatItemContainer,
	HeaderTypography,
	// PromptBarBackdrop,
	UserListItemText,
} from "../../../ux/chatStyles";

import freeTextHeaderIcon from "../../../assets/freeTextNew.svg";
import freeTextHeaderIconLight from "../../../assets/freeTextLight.svg";
import chatHeaderCloseIcon from "../../../assets/chatHeaderClose.svg";
import { Message } from "../../../services/restService";
import MarkdownViewer from "../../../utils/MarkdownRenderer";
import { PromptBar } from "../PromptBar";

export type ChatMessage = {
	sender: "user" | "bot";
	content: string;
	streamId?: string;
};

export type FreeTextItemProps = {
	onSendMessage: (message: Message) => void;
	messages: ChatMessage[];
	onClose: () => void;
	setFileIds?: React.Dispatch<React.SetStateAction<string[] | null>>;
	isSendingMessage?: boolean;
	fileIds?: string[] | null;
};

const FreeTextItem: React.FC<FreeTextItemProps> = ({
	onSendMessage,
	messages,
	onClose,
	setFileIds,
	isSendingMessage,
	fileIds,
}) => {
	const [copiedMessageIndex, setCopiedMessageIndex] = useState<number | null>(
		null
	);

	const theme = useTheme();
	const themeType = theme.palette.mode;
	const chatContainerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (chatContainerRef.current) {
			chatContainerRef.current.scrollTop =
				chatContainerRef.current.scrollHeight;
		}
	}, [messages]);

	const copyToClipboard = async (text: string, index: number) => {
		try {
			await navigator.clipboard.writeText(text);
			setCopiedMessageIndex(index); // Set the index of the copied message
			setTimeout(() => {
				setCopiedMessageIndex(null); // Reset after a few seconds
			}, 2000); // Adjust time as needed
		} catch (err) {
			console.error("Failed to copy: ", err);
		}
	};

	const handleSendMessage = (message: Message) => {
		onSendMessage(message);
		// Reset fileIds after sending
		if (setFileIds) {
			setFileIds(null);
		}
	};

	return (
		<ChatItemContainer id="chat-container" style={{ height: "fit-content" }}>
			<Paper
				style={{
					marginTop: "20px",
					marginBottom: "20px",
					// background: "#1c1a21",
					borderRadius: "0.75rem",
					maxWidth: "100%",
					// alignItems: "center",
					// justifyContent: "center",
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Container
					sx={{
						display: "flex",
						alignItems: "center",
						padding: "10px",
					}}
				>
					<img
						src={
							themeType === "dark"
								? freeTextHeaderIcon
								: freeTextHeaderIconLight
						}
						alt="Free Text"
						style={{ marginBottom: "2px" }}
					/>
					<HeaderTypography
						variant="h6"
						sx={{ display: "flex", alignItems: "center" }}
					>
						Free Text
					</HeaderTypography>
					<IconButton onClick={onClose} sx={{ marginLeft: "auto" }}>
						<img src={chatHeaderCloseIcon} alt="Minimize" />
					</IconButton>
				</Container>

				<div
					ref={chatContainerRef}
					style={{
						height: "fit-content",
						minHeight: "80vh",
						maxHeight: "calc(100vh - 200px)", // Adjust this value as needed
						overflowY: "auto",
					}}
				>
					<List>
						{messages.map((message, index) => (
							<ListItem
								key={index}
								sx={{
									justifyContent:
										message.sender === "user" ? "flex-end" : "flex-start",
									display: "flex",
									alignItems: "center",
								}}
							>
								{message.sender === "user" ? (
									<UserListItemText primary={message.content} />
								) : (
									<div
										style={{
											display: "flex",
											alignItems: "flex-start",
											width: "100%",
										}}
									>
										<BotListItemText
											primary={
												<MarkdownViewer markdownText={message.content} />
											}
										/>
										<Tooltip title="Copy">
											<IconButton
												onClick={() => copyToClipboard(message.content, index)}
												size="small"
												sx={{ marginLeft: "8px" }}
											>
												{copiedMessageIndex === index ? (
													<CheckCircleIcon
														fontSize="small"
														style={{ color: theme.palette.primary.main }}
													/>
												) : (
													<ContentCopyIcon
														fontSize="small"
														style={{ color: theme.palette.primary.main }}
													/>
												)}
											</IconButton>
										</Tooltip>
									</div>
								)}
							</ListItem>
						))}
					</List>
				</div>

				<div
					style={{
						display: "flex",
						justifyContent: "center",
					}}
				>
					<PromptBar
						onSendMessage={handleSendMessage}
						activeItem={{ primary: "Free Text", icon: "", secondary: "" }}
						setActiveItemAsFreeText={() => {
							// setActiveItem(menuItems[8]);
						}}
						setFileIds={
							setFileIds ||
							(() => {
								// Do nothing
							})
						}
						isSendingMessage={isSendingMessage || false} // Provide a default value
						fileIds={fileIds || null} // Provide a default value
					/>
				</div>
			</Paper>
		</ChatItemContainer>
	);
};

export default FreeTextItem;
