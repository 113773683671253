import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import {
	FormGroup,
	Box,
	Paper,
	Container,
	IconButton,
	CircularProgress,
	TextField,
	Tooltip,
	useTheme,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import * as userActions from "../../../actions/userActions";
import chatHeaderCloseIcon from "../../../assets/chatHeaderClose.svg";
import createSocialContentIcon from "../../../assets/createSocialContentNew.svg";
import createSocialContentIconLight from "../../../assets/createSocialContentLight.svg";
import { ToggleButton } from "./components/ToggleButton";
import {
	ChatButtonStyled,
	ChatItemContainer,
	HeaderTypography,
	StyledFieldTypography,
} from "../../../ux/chatStyles";
import { bindActionCreators } from "../../../actions/actionCreators";
import { TodoContext } from "../../todoContext";
import MarkdownViewer from "../../../utils/MarkdownRenderer";
import { copyToClipboard, formatMessage } from "../../../utils/chatUtils";
import ImageGeneration from "./components/ImageGeneration";
import Loader from "../../loader";

interface CreateSocialContentProps {
	onSendMessage: (message: any) => void;
	onClose: () => void;
}

const CreateSocialContentItem: React.FC<CreateSocialContentProps> = ({
	onSendMessage,
	onClose,
}) => {
	const { dispatch } = useContext(TodoContext);
	const [platform, setPlatform] = useState<string>("");
	const [content, setContent] = useState<{ [key: string]: any }>({
		mainMessage: "",
		toneOfVoice: [],
		CTA: false,
		feedOrStory: null,
		postOrArticle: null,
		toneOfVoiceOther: "", // For "Other" tone of voice
	});
	const [threadId, setThreadId] = useState<string>("");
	const [about, setAbout] = useState<string>("");
	const [aboutOther, setAboutOther] = useState<string>("");
	const [otherDetails, setOtherDetails] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);
	const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
	const [response, setResponse] = useState<string | null>(null);
	const [copied, setCopied] = useState<boolean>(false);
	const [currentStreamId, setCurrentStreamId] = useState<string | null>(null);
	const [streamedResponse, setStreamedResponse] = useState<string>("");
	const responseRef = useRef<HTMLDivElement>(null);

	const theme = useTheme();
	const themeType = theme.palette.mode;

	const actions = useMemo(
		() => ({
			users: bindActionCreators(
				userActions,
				dispatch
			) as unknown as userActions.UserActions,
		}),
		[dispatch]
	);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const handlePlatformSelection = (value: string) => {
		setPlatform(value);
		setContent({ ...content, feedOrStory: null, postOrArticle: null });
	};

	const handleContentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value, checked } = event.target;
		setContent({ ...content, [name]: name === "CTA" ? checked : value });
	};

	const toggleToneOfVoice = (tone: string) => {
		let updatedTones;
		if (tone === "Other") {
			updatedTones = content.toneOfVoice.includes(tone)
				? content.toneOfVoice
				: [...content.toneOfVoice, tone];
		} else {
			updatedTones = content.toneOfVoice.includes(tone)
				? content.toneOfVoice.filter((t: string) => t !== tone)
				: [...content.toneOfVoice, tone].filter((t: string) => t !== "Other");
		}
		setContent({ ...content, toneOfVoice: updatedTones });
	};

	const handleAboutChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setAbout(value);
		// If "Other" is selected, reset the "aboutOther" state
		if (value !== "Other") {
			setAboutOther("");
		}
	};

	const handleOtherDetailsChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setOtherDetails(event.target.value);
	};

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		setLoading(true);

		const submissionData = {
			mainMessage: content.mainMessage,
			CTA: content.CTA,
			feedOrStory: content.feedOrStory,
			postOrArticle: content.postOrArticle,
			toneOfVoice: content.toneOfVoice.includes("Other")
				? [
						...content.toneOfVoice.filter((t: string) => t !== "Other"),
						content.toneOfVoiceOther,
						// eslint-disable-next-line no-mixed-spaces-and-tabs
				  ]
				: content.toneOfVoice,
			toneOfVoiceOther: content.toneOfVoiceOther,
			platform,
			about: about === "Other" ? aboutOther : about,
			otherDetails,
		};

		const streamId = Date.now().toString();
		setCurrentStreamId(streamId);

		try {
			await actions.users.createSocialContentStream(
				submissionData,
				(chunk) => {
					console.log("Chunk received", chunk);
					setStreamedResponse((prev) => {
						const threadIdMatch = chunk.match(/threadId:([^\s]+)/);
						if (threadIdMatch) {
							setThreadId(threadIdMatch[1]);
							return prev + chunk.replace(/threadId:[^\s]+/, "");
						}
						return prev + chunk;
					});
					if (responseRef.current) {
						responseRef.current.scrollTop = responseRef.current.scrollHeight;
					}
				},
				() => {
					setIsSubmitted(true);
					setLoading(false);
					setCurrentStreamId(null);
				},
				(error) => {
					console.error("Submission failed", error);
					setLoading(false);
					setCurrentStreamId(null);
				}
			);
		} catch (error) {
			console.error("Failed to send social content data", error);
			setLoading(false);
			setCurrentStreamId(null);
		}
	};

	const socialContentOptions = [
		"Instagram",
		"Facebook",
		"LinkedIn",
		"X",
		"Youtube",
		"TikTok",
	];

	const toneOfVoiceOptions = [
		"Informative",
		"Inspirational",
		"Formal",
		"Persuasive",
		"Friendly",
		"Humorous",
		"Professional",
		"Other",
	];

	if (isSubmitted || streamedResponse) {
		return (
			<ChatItemContainer id="chat-container">
				<Paper
					style={{
						marginTop: "20px",
						marginBottom: "20px",
						// background: "#1c1a21",
						borderRadius: "0.75rem",
						padding: "20px",
					}}
				>
					<Container
						sx={{ display: "flex", alignItems: "center", padding: "10px" }}
					>
						<img
							src={
								themeType === "dark"
									? createSocialContentIcon
									: createSocialContentIconLight
							}
							alt="Create Social Content"
							style={{ marginBottom: "2px" }}
						/>
						<HeaderTypography
							variant="h6"
							sx={{ display: "flex", alignItems: "center" }}
						>
							Create Social Content
						</HeaderTypography>
						<IconButton onClick={onClose} sx={{ marginLeft: "auto" }}>
							<img src={chatHeaderCloseIcon} alt="Minimize" />
						</IconButton>
					</Container>
					<div
						ref={responseRef}
						style={{
							color: "#FFF",
							wordWrap: "break-word",
							whiteSpace: "pre-wrap",
							display: "flex",
							alignItems: "flex-start",
							height: "70vh",
							overflowY: "auto",
						}}
					>
						<MarkdownViewer markdownText={formatMessage(streamedResponse)} />
						<Tooltip title="Copy">
							<IconButton
								onClick={() => copyToClipboard(streamedResponse, setCopied)}
								size="small"
							>
								{copied ? (
									<CheckCircleIcon
										style={{ color: theme.palette.success.main }}
									/>
								) : (
									<ContentCopyIcon
										style={{ color: theme.palette.primary.main }}
									/>
								)}
							</IconButton>
						</Tooltip>
					</div>
					<ImageGeneration
						threadId={threadId}
						platform={platform}
						contentDetail={content.feedOrStory || content.postOrArticle}
						assistantId="createSocialMediaContentAssistant"
						generateImageBasedOnThread={
							actions.users.generateImageBasedOnThread
						}
					/>
				</Paper>
			</ChatItemContainer>
		);
	}

	return (
		<ChatItemContainer id="chat-container">
			<Paper
				style={{
					marginTop: "20px",
					marginBottom: "20px",
					// background: "#1c1a21",
					borderRadius: "0.75rem",
					padding: "20px",
				}}
			>
				<Container
					sx={{
						display: "flex",
						alignItems: "center",
						padding: "10px",
					}}
				>
					<img
						src={
							themeType === "dark"
								? createSocialContentIcon
								: createSocialContentIconLight
						}
						alt="Create Social Content"
						style={{ marginBottom: "2px" }}
					/>
					<HeaderTypography
						variant="h6"
						sx={{ display: "flex", alignItems: "center" }}
					>
						Create Social Content
					</HeaderTypography>
					<IconButton onClick={onClose} sx={{ marginLeft: "auto" }}>
						<img src={chatHeaderCloseIcon} alt="Minimize" />
					</IconButton>
				</Container>
				{loading ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							width: "100%",
							padding: "20px",
						}}
					>
						<Loader />
					</div>
				) : (
					<FormGroup onSubmit={handleSubmit}>
						<Box>
							<StyledFieldTypography gutterBottom>
								which platform?
							</StyledFieldTypography>

							{socialContentOptions.map((option) => (
								<ToggleButton
									key={option}
									selected={platform === option}
									onClick={() => handlePlatformSelection(option)}
								>
									{option}
								</ToggleButton>
							))}
						</Box>

						{/* Conditional rendering for Instagram and Facebook */}
						{(platform === "Instagram" || platform === "Facebook") && (
							<Box>
								<StyledFieldTypography gutterBottom>
									And which type?
								</StyledFieldTypography>
								<ToggleButton
									selected={content.feedOrStory === "Feed"}
									onClick={() =>
										setContent({ ...content, feedOrStory: "Feed" })
									}
								>
									Feed
								</ToggleButton>
								<ToggleButton
									selected={content.feedOrStory === "Story"}
									onClick={() =>
										setContent({ ...content, feedOrStory: "Story" })
									}
								>
									Story
								</ToggleButton>
							</Box>
						)}

						{/* Conditional rendering for LinkedIn */}
						{platform === "LinkedIn" && (
							<Box>
								<StyledFieldTypography gutterBottom>
									And which type?
								</StyledFieldTypography>
								<ToggleButton
									selected={content.postOrArticle === "Post"}
									onClick={() =>
										setContent({ ...content, postOrArticle: "Post" })
									}
								>
									Post
								</ToggleButton>
								<ToggleButton
									selected={content.postOrArticle === "Article"}
									onClick={() =>
										setContent({ ...content, postOrArticle: "Article" })
									}
								>
									Article
								</ToggleButton>
							</Box>
						)}

						{/* What is it about? section */}
						<Box mt={2}>
							<StyledFieldTypography gutterBottom>
								What is it about?
							</StyledFieldTypography>
							{[
								"Learning",
								"General",
								"Sale",
								"Launch",
								"Service",
								"Product",
								"Other",
							].map((option) => (
								<ToggleButton
									key={option}
									selected={about === option}
									onClick={() =>
										handleAboutChange({
											target: { name: "about", value: option },
										} as React.ChangeEvent<HTMLInputElement>)
									}
								>
									{option}
								</ToggleButton>
							))}
							{about === "Other" && (
								<TextField
									label="Please specify"
									name="aboutOther"
									value={aboutOther}
									onChange={(e) => setAboutOther(e.target.value)}
									fullWidth
									variant="outlined"
									margin="normal"
								/>
							)}
						</Box>

						{/* Main Message Input */}
						<StyledFieldTypography gutterBottom>
							What's our main message?
						</StyledFieldTypography>
						<TextField
							label="Main Message"
							name="mainMessage"
							value={content.mainMessage}
							onChange={handleContentChange}
							fullWidth
							variant="outlined"
							margin="normal"
						/>

						{/* Tone of Voice Selection */}
						<Box mt={2}>
							<StyledFieldTypography gutterBottom>
								What should be the tone of voice?
							</StyledFieldTypography>

							{toneOfVoiceOptions.map((tone) => (
								<ToggleButton
									key={tone}
									selected={content.toneOfVoice.includes(tone)}
									onClick={() => toggleToneOfVoice(tone)}
								>
									{tone}
								</ToggleButton>
							))}
							{content.toneOfVoice.includes("Other") && (
								<TextField
									label="Specify Other Tone of Voice"
									name="toneOfVoiceOther"
									value={content.toneOfVoiceOther}
									onChange={(e) =>
										setContent({ ...content, toneOfVoiceOther: e.target.value })
									}
									fullWidth
									variant="outlined"
									margin="normal"
								/>
							)}
						</Box>

						{/* Any other details to consider? section */}
						<Box mt={2}>
							<StyledFieldTypography gutterBottom>
								Any other details to consider?
							</StyledFieldTypography>
							<TextField
								label="Other Details"
								name="otherDetails"
								value={otherDetails}
								onChange={handleOtherDetailsChange}
								fullWidth
								variant="outlined"
								margin="normal"
							/>
						</Box>

						{/* CTA Toggle */}

						<Box mt={2}>
							<StyledFieldTypography gutterBottom>
								Should we add a CTA?
							</StyledFieldTypography>
							<ToggleButton
								selected={content.CTA}
								onClick={() => setContent({ ...content, CTA: true })}
							>
								Yes
							</ToggleButton>
							<ToggleButton
								selected={!content.CTA}
								onClick={() => setContent({ ...content, CTA: false })}
							>
								No
							</ToggleButton>
						</Box>

						{/* Submit Button */}
						<Box mt={2}>
							<ChatButtonStyled
								type="submit"
								variant="contained"
								onClick={handleSubmit}
								disabled={loading}
							>
								{loading ? <CircularProgress size={24} /> : "Submit"}
							</ChatButtonStyled>
						</Box>
					</FormGroup>
				)}
			</Paper>
		</ChatItemContainer>
	);
};

export default CreateSocialContentItem;
