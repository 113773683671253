import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import {
	TextField,
	Slider,
	FormGroup,
	Box,
	Paper,
	Container,
	IconButton,
	CircularProgress,
	Tooltip,
	useTheme,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import * as userActions from "../../../actions/userActions";
import {
	ChatButtonStyled,
	ChatItemContainer,
	HeaderTypography,
	StyledFieldTypography,
} from "../../../ux/chatStyles";
import chatHeaderCloseIcon from "../../../assets/chatHeaderClose.svg";
import writeANewsletterIcon from "../../../assets/writeANewsletterNew.svg";
import writeANewsletterIconLight from "../../../assets/writeANewsletterLight.svg";
import { bindActionCreators } from "../../../actions/actionCreators";
import { TodoContext } from "../../todoContext";
import { ToggleButton } from "./components/ToggleButton";
import MarkdownViewer from "../../../utils/MarkdownRenderer";
import { copyToClipboard, formatMessage } from "../../../utils/chatUtils";
import ImageGeneration from "./components/ImageGeneration";
import Loader from "../../loader";

interface WriteNewsletterItemProps {
	onSendMessage: (message: any) => void;
	onClose: () => void;
}

const WriteNewsletterItem: React.FC<WriteNewsletterItemProps> = ({
	onSendMessage,
	onClose,
}) => {
	const { dispatch } = useContext(TodoContext);
	const [threadId, setThreadId] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);
	const [newsletterType, setNewsletterType] = useState<string>("");
	const [flowType, setFlowType] = useState<string>("");
	const [emailsCount, setEmailsCount] = useState<number>(3);
	const [mainMessage, setMainMessage] = useState<string>("");
	const [toneOfVoice, setToneOfVoice] = useState<string[]>([]);
	const [toneOfVoiceOther, setToneOfVoiceOther] = useState<string>("");
	const [otherDetails, setOtherDetails] = useState<string>("No");
	const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
	const [response, setResponse] = useState<string | null>(null);
	const [copied, setCopied] = useState<boolean>(false);
	const [currentStreamId, setCurrentStreamId] = useState<string | null>(null);
	const [streamedResponse, setStreamedResponse] = useState<string>("");
	const responseRef = useRef<HTMLDivElement>(null);

	const theme = useTheme();
	const themeType = theme.palette.mode;

	const actions = useMemo(
		() => ({
			users: bindActionCreators(
				userActions,
				dispatch
			) as unknown as userActions.UserActions,
		}),
		[dispatch]
	);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setLoading(true);

		const newsletterData = {
			newsletterType,
			flowType,
			emailsCount,
			mainMessage,
			toneOfVoice: toneOfVoiceOther
				? [...toneOfVoice, `Other: ${toneOfVoiceOther}`]
				: toneOfVoice,
			otherDetails,
		};

		const streamId = Date.now().toString();
		setCurrentStreamId(streamId);

		try {
			await actions.users.writeNewsletterStream(
				newsletterData,
				(chunk) => {
					console.log("Chunk received", chunk);
					setStreamedResponse((prev) => {
						const threadIdMatch = chunk.match(/threadId:([^\s]+)/);
						if (threadIdMatch) {
							setThreadId(threadIdMatch[1]);
							return prev + chunk.replace(/threadId:[^\s]+/, "");
						}
						return prev + chunk;
					});
					if (responseRef.current) {
						responseRef.current.scrollTop = responseRef.current.scrollHeight;
					}
				},
				() => {
					setIsSubmitted(true);
					setLoading(false);
					setCurrentStreamId(null);
				},
				(error) => {
					console.error("Submission failed", error);
					setLoading(false);
					setCurrentStreamId(null);
				}
			);
		} catch (error) {
			console.error("Failed to send newsletter data", error);
			setLoading(false);
			setCurrentStreamId(null);
		}
	};

	const toneOfVoiceOptions = [
		"Informative",
		"Inspirational",
		"Formal",
		"Persuasive",
		"Friendly",
		"Humorous",
		"Professional",
		"Other",
	];

	if (isSubmitted || streamedResponse) {
		return (
			<ChatItemContainer id="chat-container">
				<Paper
					style={{
						marginTop: "20px",
						marginBottom: "20px",
						borderRadius: "0.75rem",
						padding: "20px",
					}}
				>
					<Container
						sx={{ display: "flex", alignItems: "center", padding: "10px" }}
					>
						<img
							src={
								themeType === "dark"
									? writeANewsletterIcon
									: writeANewsletterIconLight
							}
							alt="Write a Newsletter"
							style={{ marginBottom: "2px" }}
						/>
						<HeaderTypography
							variant="h6"
							sx={{ display: "flex", alignItems: "center" }}
						>
							Write a Newsletter
						</HeaderTypography>
						<IconButton onClick={onClose} sx={{ marginLeft: "auto" }}>
							<img src={chatHeaderCloseIcon} alt="Minimize" />
						</IconButton>
					</Container>
					<div
						ref={responseRef}
						style={{
							color: "#FFF",
							wordWrap: "break-word",
							whiteSpace: "pre-wrap",
							display: "flex",
							alignItems: "flex-start",
							maxHeight: "70vh",
							overflowY: "auto",
						}}
					>
						<MarkdownViewer markdownText={formatMessage(streamedResponse)} />
						<Tooltip title="Copy">
							<IconButton
								onClick={() => copyToClipboard(streamedResponse, setCopied)}
								size="small"
							>
								{copied ? (
									<CheckCircleIcon
										style={{ color: theme.palette.success.main }}
									/>
								) : (
									<ContentCopyIcon
										style={{ color: theme.palette.primary.main }}
									/>
								)}
							</IconButton>
						</Tooltip>
					</div>
					<ImageGeneration
						threadId={threadId}
						platform={"newsletter"}
						contentDetail={""}
						assistantId="createSocialMediaContentAssistant"
						generateImageBasedOnThread={
							actions.users.generateImageBasedOnThread
						}
					/>
				</Paper>
			</ChatItemContainer>
		);
	}

	return (
		<ChatItemContainer id="chat-container">
			<Paper
				style={{
					marginTop: "20px",
					marginBottom: "20px",
					borderRadius: "0.75rem",
					padding: "20px",
				}}
			>
				<Container
					sx={{
						display: "flex",
						alignItems: "center",
						padding: "10px",
					}}
				>
					<img
						src={
							themeType === "dark"
								? writeANewsletterIcon
								: writeANewsletterIconLight
						}
						alt="Write a Newsletter"
						style={{ marginBottom: "2px" }}
					/>
					<HeaderTypography
						variant="h6"
						sx={{ display: "flex", alignItems: "center" }}
					>
						Write a Newsletter
					</HeaderTypography>
					<IconButton onClick={onClose} sx={{ marginLeft: "auto" }}>
						<img src={chatHeaderCloseIcon} alt="Minimize" />
					</IconButton>
				</Container>
				{loading ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							width: "100%",
							padding: "20px",
						}}
					>
						<Loader />
					</div>
				) : (
					<FormGroup>
						<Box>
							<StyledFieldTypography gutterBottom>
								What kind of email should we compose?
							</StyledFieldTypography>
							<ToggleButton
								selected={newsletterType === "Flow / Sequence"}
								onClick={() => setNewsletterType("Flow / Sequence")}
							>
								Flow / Sequence
							</ToggleButton>
							<ToggleButton
								selected={newsletterType === "Campaign"}
								onClick={() => setNewsletterType("Campaign")}
							>
								Campaign
							</ToggleButton>
						</Box>

						{newsletterType === "Flow / Sequence" && (
							<Box mt={2}>
								<StyledFieldTypography gutterBottom>
									Which type of flow?
								</StyledFieldTypography>
								<TextField
									label="Flow Type"
									value={flowType}
									onChange={(e) => setFlowType(e.target.value)}
									SelectProps={{
										native: true,
									}}
									variant="outlined"
									sx={{ mt: 2, width: "100%" }}
								/>
							</Box>
						)}
						{newsletterType === "Flow / Sequence" && (
							<Box mt={2}>
								<StyledFieldTypography gutterBottom>
									How many emails will be sent in this sequence?
								</StyledFieldTypography>
								<Slider
									aria-label="Emails Count"
									value={emailsCount}
									onChange={(_, newValue) => setEmailsCount(newValue as number)}
									step={1}
									marks
									min={1}
									max={10}
									valueLabelDisplay="auto"
									color="secondary"
								/>
							</Box>
						)}

						<Box mt={2}>
							<StyledFieldTypography gutterBottom>
								What's our main message or offer?
							</StyledFieldTypography>
							<TextField
								label="Main Message"
								variant="outlined"
								value={mainMessage}
								onChange={(e) => setMainMessage(e.target.value)}
								sx={{ mt: 2, width: "100%" }}
							/>
						</Box>

						<Box mt={2}>
							<StyledFieldTypography gutterBottom>
								What should be the tone of voice?
							</StyledFieldTypography>
							{toneOfVoiceOptions.map((tone) => (
								<ToggleButton
									key={tone}
									selected={toneOfVoice.includes(tone)}
									onClick={() =>
										setToneOfVoice((prev) =>
											prev.includes(tone)
												? prev.filter((item) => item !== tone)
												: [...prev, tone]
										)
									}
								>
									{tone}
								</ToggleButton>
							))}
							{toneOfVoice.includes("Other") && (
								<div>
									<StyledFieldTypography gutterBottom>
										Please specify the tone of voice
									</StyledFieldTypography>
									<TextField
										label="Please specify"
										variant="outlined"
										value={toneOfVoiceOther}
										onChange={(e) => setToneOfVoiceOther(e.target.value)}
										sx={{ mt: 2, width: "100%" }}
									/>
								</div>
							)}
						</Box>

						<Box mt={2}>
							<StyledFieldTypography gutterBottom>
								Any other details to consider?
							</StyledFieldTypography>
							<TextField
								label="Other Details"
								variant="outlined"
								value={otherDetails}
								onChange={(e) => setOtherDetails(e.target.value)}
								sx={{ mt: 2, width: "100%" }}
							/>
						</Box>

						<Box mt={2}>
							<ChatButtonStyled
								variant="contained"
								onClick={handleSubmit}
								disabled={loading}
							>
								{loading ? <CircularProgress size={24} /> : "Submit"}
							</ChatButtonStyled>
						</Box>
					</FormGroup>
				)}
			</Paper>
		</ChatItemContainer>
	);
};

export default WriteNewsletterItem;
