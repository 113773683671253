/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useContext, useMemo, useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as userActions from "../../actions/userActions";
import { MenuItem, MenuList } from "./MenuList";
import { BottomBar } from "./BottomBar";
import createSocialContentIconDark from "../../assets/createSocialContentNew.svg";
import createSocialContentIconLight from "../../assets/createSocialContentLight.svg";
import writeANewsletterIconDark from "../../assets/writeANewsletterNew.svg";
import writeANewsletterIconLight from "../../assets/writeANewsletterLight.svg";
import writeABlogPostIconDark from "../../assets/writeABlogPostNew.svg";
import writeABlogPostIconLight from "../../assets/writeABlogPostLight.svg";
import buildASurveyIconDark from "../../assets/buildASurveyNew.svg";
import buildASurveyIconLight from "../../assets/buildASurveyLight.svg";
import writeAWebsiteCopyIconDark from "../../assets/writeWebsiteCopyNew.svg";
import writeAWebsiteCopyIconLight from "../../assets/writeWebsiteCopyLight.svg";
import writeASlideDeckIconDark from "../../assets/createASlideDeckNew.svg";
import writeASlideDeckIconLight from "../../assets/createASlideDeckLight.svg";
import createGoogleAdsIconDark from "../../assets/createGoogleAdsNew.svg";
import createGoogleAdsIconLight from "../../assets/createGoogleAdsLight.svg";
import createDocumentsIconDark from "../../assets/createDocumentsNew.svg";
import createDocumentsIconLight from "../../assets/createDocumentsLight.svg";
import freeTextIconDark from "../../assets/freeTextNew.svg";
import freeTextIconLight from "../../assets/freeTextLight.svg";
import bottomBarCockpitIcon from "../../assets/bottomBarCockpit.svg";
import bottomBarProfileIcon from "../../assets/bottomBarProfile.svg";
import bottomBarLoadFileIcon from "../../assets/bottomBarLoadFile.svg";
import bottomBarSettingIcon from "../../assets/bottomBarSetting.svg";
import createImageIconDark from "../../assets/createAnImageNew.svg";
import createImageIconLight from "../../assets/createAnImageLight.svg";
import FreeTextItem, {
	ChatMessage,
	FreeTextItemProps,
} from "./menuItems/freeText";
import { bindActionCreators } from "../../actions/actionCreators";
import { TodoContext } from "../todoContext";
import { Message } from "../../services/restService";
import { formatMessage } from "../../utils/chatUtils";
import { Alert, Container, Snackbar } from "@mui/material";
import BuildSurveyItem from "./menuItems/buildASurvey";
import WriteBlogPostItem from "./menuItems/writeABlogPost";
import CreateNewsletterItem from "./menuItems/writeANewsletter";
import CreateGoogleAdItem from "./menuItems/createGoogleAd";
import CreateDocumentItem from "./menuItems/createDocument";
import CreateSlideDeckItem from "./menuItems/createASlideDeck";
import CreateSocialContentItem from "./menuItems/createSocialContent";
import WriteWebsiteCopyItem from "./menuItems/writeAWebsiteCopy";
import CreateImageItem from "./menuItems/createImage";
import FreeTextWrapper from "./menuItems/components/FreeTextWrapper";
import logo from "../../assets/loader.svg";

const ChatHomePage: React.FC = () => {
	const { dispatch } = useContext(TodoContext);
	const [activeItem, setActiveItem] = useState<MenuItem | null>(null);
	const [messages, setMessages] = useState<ChatMessage[]>([]);
	const [error, setError] = useState<string | null>(null);
	const [threadId, setThreadId] = useState<string | null>(null);
	const [fileIds, setFileIds] = useState<string[] | null>(null);
	const [isSendingMessage, setIsSendingMessage] = useState(false);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [initialLoad, setInitialLoad] = useState(true);
	const [currentStreamId, setCurrentStreamId] = useState<string | null>(null);

	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
	const themeType = theme.palette.mode;

	// Utility function to select the correct icon based on the theme
	const selectIcon = (
		darkIcon: string,
		lightIcon: string,
		themeType: "light" | "dark"
	) => {
		return themeType === "light" ? lightIcon : darkIcon;
	};

	const actions = useMemo(
		() => ({
			users: bindActionCreators(
				userActions,
				dispatch
			) as unknown as userActions.UserActions,
		}),
		[dispatch]
	);

	const menuItems = [
		{
			icon: selectIcon(
				createSocialContentIconDark,
				createSocialContentIconLight,
				themeType
			),
			primary: "Create Social Content",
			secondary: "Engage with clients and give your brand a boost",
			component: CreateSocialContentItem as React.ComponentType<any>,
		},
		{
			icon: selectIcon(
				writeANewsletterIconDark,
				writeANewsletterIconLight,
				themeType
			),
			primary: "Write a Newsletter",
			secondary: "Connect with clients through insightful, ongoing updates",
			component: CreateNewsletterItem as React.ComponentType<any>,
		},
		{
			icon: selectIcon(
				writeABlogPostIconDark,
				writeABlogPostIconLight,
				themeType
			),
			primary: "Write a Blog Post",
			secondary: "Share your expertise and gain credibility",
			component: WriteBlogPostItem as React.ComponentType<any>,
		},
		{
			icon: selectIcon(buildASurveyIconDark, buildASurveyIconLight, themeType),
			primary: "Build a Survey",
			secondary: "Gather feedback to improve your products and services",
			component: BuildSurveyItem as React.ComponentType<any>,
		},
		{
			icon: selectIcon(
				writeAWebsiteCopyIconDark,
				writeAWebsiteCopyIconLight,
				themeType
			),
			primary: "Write Website Copy",
			secondary: "Craft compelling content that turns visitors into customers",
			component: WriteWebsiteCopyItem as React.ComponentType<any>,
		},
		{
			icon: selectIcon(
				writeASlideDeckIconDark,
				writeASlideDeckIconLight,
				themeType
			),
			primary: "Create a Slide Deck",
			secondary: "Impress clients with sleek, informative presentations",
			component: CreateSlideDeckItem as React.ComponentType<any>,
		},
		{
			icon: selectIcon(
				createGoogleAdsIconDark,
				createGoogleAdsIconLight,
				themeType
			),
			primary: "Create Google Ads",
			secondary: "Boost visibility and reach your ideal audience efficiently",
			component: CreateGoogleAdItem as React.ComponentType<any>,
		},
		{
			icon: selectIcon(
				createDocumentsIconDark,
				createDocumentsIconLight,
				themeType
			),
			primary: "Create Documents",
			secondary: "Convey detailed messages in attractive formats",
			component: CreateDocumentItem as React.ComponentType<any>,
		},
		{
			icon: selectIcon(freeTextIconDark, freeTextIconLight, themeType),
			primary: "Free Text",
			secondary:
				"Let your creativity fly and ask cockpit for anything you need",
			component: FreeTextItem as React.ComponentType<any>,
		},
		{
			icon: selectIcon(createImageIconDark, createImageIconLight, themeType),
			primary: "Create an Image",
			secondary: "Convey detailed messages in attractive formats",
			component: CreateImageItem as React.ComponentType<any>,
		},
	];

	const bottomBarActions = [
		{ label: "Main", icon: bottomBarCockpitIcon },
		{ label: "Load File", icon: bottomBarLoadFileIcon },
		{ label: "Profile", icon: bottomBarProfileIcon },
		{ label: "Settings", icon: bottomBarSettingIcon },
	];

	const handleItemClick = (item: MenuItem) => {
		setActiveItem(item);
		setInitialLoad(false); // Change layout after first item click
	};

	const handleCloseActiveItem = () => {
		setActiveItem(null);
	};

	const ActiveItemComponent = activeItem?.component;

	const handleSendMessage = async (message: Message) => {
		setIsSendingMessage(true);

		// Include fileIds in the message object
		const updatedMessage = {
			...message,
			threadId: threadId || undefined,
			fileIds: message.file_ids, // Add this line
		};

		// Add the user's message to the chat immediately
		setMessages((prev) => [
			...prev,
			{ sender: "user", content: updatedMessage.content },
		]);

		// Generate a unique ID for this stream
		const streamId = Date.now().toString();
		setCurrentStreamId(streamId);

		// Handle streamed response
		try {
			let currentStreamedMessage = ""; // To accumulate the streamed message

			await actions.users.chatMessageStream(
				updatedMessage, // Use the updated message with the threadId
				(chunk) => {
					// Check if the chunk contains threadId
					const threadIdMatch = chunk.match(/threadId:(\w+)/);
					if (threadIdMatch) {
						setThreadId(threadIdMatch[1]); // Update threadId in state
					}

					// Update the current streamed message with the new chunk
					currentStreamedMessage += chunk;

					// Filter out threadId before displaying it in the message
					const cleanedMessage = currentStreamedMessage.replace(
						/threadId:\w+/,
						""
					);

					// Add the chunk to the UI
					setMessages((prev) => [
						...prev.filter((msg) => msg.streamId !== streamId),
						{ sender: "bot", content: cleanedMessage, streamId },
					]);
				},
				() => {
					// Completed streaming
					setIsSendingMessage(false);
					setCurrentStreamId(null);
				},
				(error) => {
					setError("An error occurred while sending the message.");
					setSnackbarOpen(true);
					setIsSendingMessage(false);
					setCurrentStreamId(null);
				}
			);
		} catch (error) {
			setError("An error occurred while sending the message.");
			setSnackbarOpen(true);
			setIsSendingMessage(false);
			setCurrentStreamId(null);
		}
	};

	const handleCloseSnackbar = () => {
		setSnackbarOpen(false);
	};

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				width: "100%",
				height: "calc(100vh - 4rem)",
				alignItems: "center",
				justifyContent: "space-between",
				paddingTop: isDesktop ? "1rem" : "none",
				background: theme.palette.background.default,
			}}
		>
			{error && <div>Error: {error}</div>}
			<div
				style={{
					display: "flex",
					flexDirection: isDesktop ? "row" : "column",
					width: "100%",
				}}
			>
				{!activeItem ? (
					<>
						<div
							style={{
								flex: "0 0 auto",
								maxHeight: "calc(100vh - 4rem)",
								overflowY: "auto",
								display: "flex",
								flexDirection: initialLoad ? "row" : "column",
								justifyContent: initialLoad ? "center" : "flex-start",
								alignItems: initialLoad ? "center" : "flex-start",
								width: initialLoad ? "100%" : "auto",
								height: initialLoad ? "100%" : "auto",
								flexWrap: initialLoad ? "wrap" : "nowrap",
								marginTop: initialLoad ? "4.5%" : "",
							}}
						>
							<MenuList
								items={menuItems}
								onItemClick={handleItemClick}
								activeItem={activeItem}
								initialLoad={initialLoad}
								isDesktop={isDesktop}
							/>
						</div>
						{isDesktop && !initialLoad && (
							<Container
								sx={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									overflowY: "hidden",
								}}
							>
								<img
									src={logo}
									alt="Logo"
									style={{ width: "8rem", marginTop: "2rem" }}
								/>
							</Container>
						)}
					</>
				) : (
					<>
						{isDesktop && (
							<div
								style={{
									flex: "0 0 auto",
									maxHeight: "calc(100vh - 4rem)",
									overflowY: "auto",
								}}
							>
								<MenuList
									items={menuItems}
									onItemClick={handleItemClick}
									activeItem={activeItem}
									initialLoad={initialLoad}
									isDesktop={isDesktop}
								/>
							</div>
						)}
						<div
							style={{
								flex: "1 1 auto",
								maxHeight: "calc(100vh - 4rem)",
								overflowY: "auto",
							}}
						>
							{ActiveItemComponent &&
								(activeItem?.primary === "Free Text"
									? React.createElement(
											FreeTextWrapper as React.ComponentType<FreeTextItemProps>,
											{
												onSendMessage: handleSendMessage,
												messages: messages,
												onClose: handleCloseActiveItem,
												setFileIds,
												isSendingMessage,
											}
									  )
									: React.createElement(
											ActiveItemComponent as React.ComponentType<FreeTextItemProps>,
											{
												onSendMessage: handleSendMessage,
												messages: messages,
												onClose: handleCloseActiveItem,
											}
									  ))}
						</div>
					</>
				)}
			</div>
			<Snackbar
				open={snackbarOpen}
				autoHideDuration={6000}
				onClose={handleCloseSnackbar}
			>
				<Alert
					onClose={handleCloseSnackbar}
					severity="error"
					sx={{ width: "100%" }}
				>
					{error}
				</Alert>
			</Snackbar>
		</div>
	);
};

export default ChatHomePage;
