import { TextField, FormControlLabel, Button, Alert, Box } from "@mui/material";
import { Unstable_NumberInput as NumberInput } from "@mui/base/Unstable_NumberInput";
import { theme } from "./theme";

import { styled } from "@mui/material/styles";

export const TextFieldStyled = styled(TextField)({
	color: theme.palette.text.primary,
	marginTop: 20,
	width: "90%",

	// Responsive styling for desktop
	"@media (min-width: 600px)": {
		width: "60%",
	},
});

export const NumberInputStyled = styled(NumberInput)({
	borderRadius: 8,
	border: "2px solid var(--Black-Black-100, #333)",
	background: "#FEFEFE",
	marginTop: 20,
	width: "80%",
	// Responsive styling for desktop
	"@media (min-width: 600px)": {
		width: "60%",
	},
});

export const FormControlLabelStyled = styled(FormControlLabel)({
	// marginTop: 130,
	width: "80%",
	// set label font size to 0.8rem
	"& .MuiTypography-body1": {
		fontSize: "0.8rem",
	},
	// Responsive styling for desktop
	"@media (min-width: 600px)": {
		width: "60%",
	},
});

export const SignupButtonStyled = styled(Button)({
	borderRadius: 8,
	border: "2px solid var(--Black-Black-100, #333)",
	// background: "#000",
	color: "#fff",
	marginTop: 20,
	width: "80%",
	height: 40,
	// stick to bottom of screen with 20px margin
	position: "absolute",
	bottom: 40,
	// Responsive styling for desktop
	"@media (min-width: 600px)": {
		width: "60%",
	},
});

export const AlertStyled = styled(Alert)({
	width: "80%",
	// Responsive styling for desktop
	"@media (min-width: 600px)": {
		width: "60%",
	},
});

export const FormContainerStyled = styled(Box)({
	width: "100%",
	// height: "100vh",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",

	// Responsive styling for desktop
	"@media (min-width: 600px)": {
		justifyContent: "flex-start",
		paddingTop: "5%",
	},
});
