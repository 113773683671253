import {
	AppBar,
	Container,
	Paper,
	Select,
	Table,
	TableCell,
	TableContainer,
	TableRow,
	Tabs,
	TextField,
	Toolbar,
	tableCellClasses,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledContainer = styled(Container)`
	padding: 1rem;

	@media (max-width: 768px) {
		padding: 0.5rem;
	}
`;

export const StyledAppBar = styled(AppBar)`
	display: flex;
	align-items: center;
	justify-content: space-between;

	@media (max-width: 768px) {
		flex-direction: column;
		align-items: flex-start;
	}
`;

export const StyledToolbar = styled(Toolbar)`
	display: flex;
	align-items: center;

	@media (max-width: 768px) {
		width: 100%;
	}
`;

export const StyledTabs = styled(Tabs)(
	({ theme }) => `
    .MuiTabs-textColorPrimary {
        color: #fff;
    }
    .MuiTabs-indicator {
        background-color: ${theme.palette.primary.main};
    }

    .MuiTabs-flexContainer {
        justify-content: left;
    }

    .MuiTab-root {
        min-width: 0;
    }

    .MuiTab-root {
        color: #fff;
    }

    .MuiTab-root.Mui-selected {
        color: #fff;
    }

    .MuiTab-root.Mui-selected:hover {
        background-color: transparent;
    }

    .MuiTab-root:hover {
        background-color: transparent;
    }
`
);

export const StyledPaper = styled(Paper)`
	padding: 20px;
	margin-top: 20px;

	@media (max-width: 768px) {
		padding: 10px;
		margin-top: 10px;
	}
`;

export const StyledTableContainer = styled(TableContainer)`
	max-height: 440px; // Adjust based on your preference

	@media (max-width: 768px) {
		max-height: 220px;
	}
`;

export const StyledTable = styled(Table)`
	min-width: 650px; // Adjust for responsiveness

	@media (max-width: 768px) {
		min-width: 320px;
	}
`;

export const ResponsiveAppBar = styled(AppBar)`
	${({ theme }) => theme.breakpoints.down("md")} {
		// Styles for small screens
	}
	${({ theme }) => theme.breakpoints.up("md")} {
		// Styles for larger screens
	}
`;

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
	color: theme.palette.common.black,
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
	cursor: "pointer",
	"&:nth-of-type(odd)": {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	"&:last-child td, &:last-child th": {
		border: 0,
	},
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
	marginTop: "1rem",
	marginBottom: "1rem",
	input: {
		color: theme.palette.common.black,
	},
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
	color: theme.palette.common.black,
}));
