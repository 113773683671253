// src/App.tsx
import React, { useReducer, FC } from "react";
import { BrowserRouter } from "react-router-dom";
import Layout from "./layout/layout";
import "./App.css";
import {
	AppContext,
	ApplicationState,
	getDefaultState,
} from "./models/applicationState";
import appReducer from "./reducers";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { AuthProvider } from "./context/AuthContext";
import { FormProvider } from "./context/OnboardingContext";
import { UserProvider } from "./context/UserDataContext";
import { ThemeProvider as CustomThemeProvider } from "./context/ThemeContext";

export const App: FC = () => {
	const defaultState: ApplicationState = getDefaultState();
	const [applicationState, dispatch] = useReducer(appReducer, defaultState);
	const initialContext: AppContext = {
		state: applicationState,
		dispatch: dispatch,
	};

	initializeIcons();

	return (
		<CustomThemeProvider>
			<UserProvider>
				<AuthProvider>
					<FormProvider>
						<BrowserRouter>
							<Layout />
						</BrowserRouter>
					</FormProvider>
				</AuthProvider>
			</UserProvider>
		</CustomThemeProvider>
	);
};
