import React, { useState } from "react";
import {
	Tab,
	Toolbar,
	Typography,
	Box,
	Container,
	useMediaQuery,
	useTheme,
	ThemeProvider,
} from "@mui/material";
import UserList from "./UserList";
import {
	ResponsiveAppBar,
	StyledContainer,
	StyledTabs,
} from "../../ux/adminDashboardStyles";
import WaitingListUserList from "./WaitingList";
import { theme, lightTheme } from "../../ux/theme";

const AdminHomePage: React.FC = () => {
	const [selectedTab, setSelectedTab] = useState(0);

	const handleTabChange = (
		event: React.ChangeEvent<object>,
		newValue: number
	) => {
		setSelectedTab(newValue);
	};

	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

	const pages = ["Users", "Waitlist"];

	return (
		<ThemeProvider theme={lightTheme}>
			<StyledContainer
				maxWidth={isSmallScreen ? "sm" : false}
				sx={{ p: 0, backgroundColor: "#fff" }}
				style={{ overflowY: "auto", maxHeight: "calc(100vh - 34px)" }}
			>
				<ResponsiveAppBar position="static">
					<Toolbar
						sx={{
							backgroundColor: theme.palette.primary.main,
							borderBottom: "1px solid #e0e0e0",
						}}
					>
						<Typography
							variant="h6"
							component="div"
							sx={{ flexGrow: 1, color: "#fff", display: "flex" }}
						>
							Admin Dashboard
						</Typography>
						<StyledTabs value={selectedTab} onChange={handleTabChange} centered>
							{pages.map((page) => (
								<Tab key={page} label={page} />
							))}
						</StyledTabs>
					</Toolbar>
				</ResponsiveAppBar>

				<Container>
					{selectedTab === 0 && <Box mt={3}>{<UserList />}</Box>}
					{selectedTab === 1 && <Box mt={3}>{<WaitingListUserList />}</Box>}
				</Container>
			</StyledContainer>
		</ThemeProvider>
	);
};

export default AdminHomePage;
