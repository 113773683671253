import React, { useState, useRef, useMemo, useContext, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import * as userActions from "../../actions/userActions";
import promptBarSendIcon from "../../assets/promptBarSend.svg";
import promptBarAttachFileIcon from "../../assets/promptBarAttach.svg";
import { PromptBarContainer, PromptInput } from "../../ux/chatStyles";
import { MenuItem } from "./MenuList";
import { Message } from "../../services/restService";
import { bindActionCreators } from "../../actions/actionCreators";
import { TodoContext } from "../todoContext";
import { CircularProgress, Chip } from "@mui/material";

export const PromptBar: React.FC<{
	activeItem: MenuItem | null;
	setActiveItemAsFreeText: () => void;
	onSendMessage: (message: Message) => void;
	setFileIds: React.Dispatch<React.SetStateAction<string[] | null>>;
	isSendingMessage: boolean;
	fileIds: string[] | null; // Add this line
}> = ({
	activeItem,
	setActiveItemAsFreeText,
	onSendMessage,
	setFileIds,
	isSendingMessage,
	fileIds: parentFileIds,
}) => {
	const { dispatch } = useContext(TodoContext);
	const [inputValue, setInputValue] = useState("");
	const [isUploadingFile, setIsUploadingFile] = useState(false);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
		"success"
	);
	const [uploadedFileName, setUploadedFileName] = useState("");
	const fileInputRef = useRef<HTMLInputElement>(null);

	const [localFileIds, setLocalFileIds] = useState<string[] | null>(
		parentFileIds
	);

	useEffect(() => {
		setLocalFileIds(parentFileIds);
	}, [parentFileIds]);

	const actions = useMemo(
		() => ({
			users: bindActionCreators(
				userActions,
				dispatch
			) as unknown as userActions.UserActions,
		}),
		[dispatch]
	);

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setInputValue(event.target.value);
	};

	const handleSend = () => {
		console.log("localFileIds", localFileIds);
		if (inputValue.trim()) {
			onSendMessage({
				content: inputValue,
				threadId: "",
				file_ids: localFileIds || undefined,
			});
			setInputValue("");
			setLocalFileIds(null);
			setFileIds(null);
			setUploadedFileName("");
		}
	};

	const handleAttachFile = () => {
		fileInputRef.current?.click();
		if (!activeItem) {
			setActiveItemAsFreeText();
		}
	};

	const handleFileChange = async (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const file = event.target.files?.[0];
		if (file) {
			// Implement file uploading here
			await uploadFile(file);
		}
	};

	const uploadFile = async (file: File) => {
		setIsUploadingFile(true);
		const formData = new FormData();
		formData.append("file", file);

		try {
			// Adjust the URL to your API endpoint
			const { fileId } = await actions.users.uploadFile(file);
			console.log("fileId", fileId);

			setLocalFileIds((prevFileIds) => {
				const newFileIds = prevFileIds ? [...prevFileIds, fileId] : [fileId];
				console.log("New localFileIds:", newFileIds);
				setFileIds(newFileIds); // Update parent state
				return newFileIds;
			});

			setUploadedFileName(file.name);
			setSnackbarMessage(`File "${file.name}" uploaded successfully!`);
			setSnackbarSeverity("success");
		} catch (error) {
			console.error("Error uploading file:", error);
			setSnackbarMessage("Failed to upload file.");
			setSnackbarSeverity("error");
		}
		setSnackbarOpen(true);
		setIsUploadingFile(false);
	};

	const handleSnackbarClose = (
		event?: React.SyntheticEvent | Event,
		reason?: string
	) => {
		if (reason === "clickaway") {
			return;
		}
		setSnackbarOpen(false);
	};

	return (
		<PromptBarContainer>
			<input
				type="file"
				ref={fileInputRef}
				onChange={handleFileChange}
				style={{ display: "none" }}
				accept="
					text/x-c, text/x-c++, application/csv, 
					application/vnd.openxmlformats-officedocument.wordprocessingml.document, 
					text/html, text/x-java, application/json, text/markdown, 
					application/pdf, text/x-php, 
					application/vnd.openxmlformats-officedocument.presentationml.presentation, 
					text/x-python, text/x-script.python, text/x-ruby, text/x-tex, 
					text/plain, text/css, image/jpeg, text/javascript, 
					image/gif, image/png, application/x-tar, application/typescript, 
					application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, 
					application/xml, text/xml, application/zip
				"
			/>

			<IconButton
				color="inherit"
				aria-label="attach file"
				onClick={handleAttachFile}
				disabled={isSendingMessage || isUploadingFile}
			>
				{isUploadingFile ? (
					<CircularProgress size={24} style={{ color: "secondary.main" }} />
				) : (
					<img src={promptBarAttachFileIcon} alt="attach file" />
				)}
			</IconButton>
			{uploadedFileName && (
				<Chip
					label={uploadedFileName}
					onDelete={() => setUploadedFileName("")}
					style={{ marginRight: 8 }}
					sx={{ color: "text.primary" }}
					variant="outlined"
				/>
			)}
			<PromptInput
				placeholder="Prompt"
				value={inputValue}
				onChange={handleInputChange}
				onKeyDown={(event) => {
					if (event.key === "Enter") {
						handleSend();
					}
				}}
				disableUnderline
				disabled={isSendingMessage || isUploadingFile}
			/>
			<IconButton
				color="inherit"
				aria-label="send prompt"
				onClick={handleSend}
				disabled={isSendingMessage || isUploadingFile}
			>
				{isSendingMessage ? (
					<CircularProgress size={24} style={{ color: "secondary.main" }} />
				) : (
					<img src={promptBarSendIcon} alt="send prompt" />
				)}
			</IconButton>

			<Snackbar
				open={snackbarOpen}
				autoHideDuration={6000}
				onClose={handleSnackbarClose}
			>
				<Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
					{snackbarMessage}
				</Alert>
			</Snackbar>
		</PromptBarContainer>
	);
};
