export const formatMessage = (message: string) => {
	const formattedMessage = message.replace(
		/```(.*?)```/gs,
		"<pre><code>$1</code></pre>"
	);
	return formattedMessage;
};

export const copyToClipboard = async (
	text: string,
	setCopied: React.Dispatch<React.SetStateAction<boolean>>
) => {
	try {
		await navigator.clipboard.writeText(text);
		setCopied(true);
		setTimeout(() => {
			setCopied(false);
		}, 2000); // Reset copy status after 2 seconds
	} catch (err) {
		console.error("Failed to copy: ", err);
	}
};
