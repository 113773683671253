import { Reducer } from "react";
import { ActionTypes, TodoActions } from "../actions/common";
import { User } from "../models/user";

export const userReducer: Reducer<User, TodoActions> = (
	state: User,
	action: TodoActions
): User => {
	switch (action.type) {
		case ActionTypes.COMPLETE_SIGN_UP:
			// Update state based on the action
			return { ...state /* updated state properties */ };
		// Handle other user actions
		default:
			return state;
	}
};
