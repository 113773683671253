import React, { useState, useContext, useMemo } from "react";
import {
	Button,
	TextField,
	Snackbar,
	Container,
	useTheme,
} from "@mui/material";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import PhoneInput, { type Value } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import * as userActions from "../../actions/userActions";
import { TodoContext } from "../todoContext";
import { bindActionCreators } from "../../actions/actionCreators";

interface PersonalInfoFormProps {
	initialData: {
		name: string;
		phone: string;
		email: string;
		companyName: string;
	};
}

const PersonalInfoForm: React.FC<
	PersonalInfoFormProps & { onUserDataUpdated?: (data: any) => void }
> = ({ initialData, onUserDataUpdated }) => {
	const [formData, setFormData] = useState(initialData);
	const [phone, setPhone] = useState<Value>(initialData.phone as Value);
	const [message, setMessage] = useState("");
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const { dispatch } = useContext(TodoContext);
	const auth = getAuth();
	const theme = useTheme();

	const actions = {
		users: bindActionCreators(
			userActions,
			dispatch
		) as unknown as userActions.UserActions,
	};

	const handleResetPassword = async () => {
		try {
			await sendPasswordResetEmail(auth, formData.email);
			setMessage("Password reset email sent!");
			setOpenSnackbar(true);
		} catch (error: any) {
			setMessage(error.message || "Failed to send password reset email");
			setOpenSnackbar(true);
		}
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const handlePhoneChange = (value?: Value) => {
		setPhone(value || ("" as Value));
		setFormData((prev) => ({ ...prev, phone: value ? value.toString() : "" }));
	};

	const handleSubmit = async () => {
		try {
			await actions.users.completeSignUp(formData);
			setMessage("Information updated successfully!");
			setOpenSnackbar(true);
			onUserDataUpdated && onUserDataUpdated(formData); // Call the callback function after success
		} catch (error: any) {
			setMessage(error.message || "Failed to update information");
			setOpenSnackbar(true);
		}
	};

	return (
		<Container
			component="form"
			sx={{
				display: "flex",
				flexDirection: "column",
				gap: 2,
				width: "100%",
				maxWidth: "400px",
				margin: "auto",
				// background: "#202029",
				borderRadius: "0.7rem",
				marginTop: "0.5rem",
				padding: "0.7rem",
				paddingTop: "1.5rem",
				border: "1px solid #29273E",
			}}
		>
			<TextField
				label="Name"
				name="name"
				value={formData.name}
				onChange={handleChange}
			/>
			<PhoneInput
				placeholder="Enter phone number"
				value={phone}
				onChange={handlePhoneChange}
				countryCallingCodeEditable={false}
				international
				withCountryCallingCode
				defaultCountry="US"
			/>
			<TextField
				label="Company Name"
				name="companyName"
				value={formData.companyName}
				onChange={handleChange}
			/>
			<TextField
				label="Email"
				name="email"
				value={formData.email}
				onChange={handleChange}
				disabled
				style={{
					WebkitTextFillColor: "inherit", // This attempts to override the text fill color
				}}
				InputProps={{
					style: {
						WebkitTextFillColor: "#fff", // This attempts to override the text fill color
					},
					inputProps: {
						"aria-label": "email",
						style: {
							WebkitTextFillColor: "#fff", // Ensures the override works for disabled inputs
						},
					},
				}}
			/>
			<Button
				onClick={handleResetPassword}
				variant="outlined"
				sx={{
					mr: 1,
					borderColor: "#29273E",
					color: "primary.contrastText",
				}}
			>
				Reset Password
			</Button>
			<Button
				onClick={handleSubmit}
				variant="contained"
				sx={{ color: theme.palette.secondary.contrastText }}
			>
				Update
			</Button>
			<Snackbar
				open={openSnackbar}
				autoHideDuration={6000}
				onClose={() => setOpenSnackbar(false)}
				message={message}
			/>
		</Container>
	);
};

export default PersonalInfoForm;
