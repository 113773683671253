import React from "react";
import { Typography, Button, Container, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
// import successImage from "../assets/success.png";

const Success = () => {
	const navigate = useNavigate();
	const handlePaymentSuccess = () => {
		navigate("/chat");
	};

	return (
		<Container
			maxWidth="sm"
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				justifyContent: "center",
				height: "100vh",
			}}
		>
			<Box sx={{ textAlign: "center" }}>
				{/* <img src={successImage} alt="Success" width={220} height={220} /> */}
				<Typography
					variant="h4"
					component="h3"
					// color="success.main"
					marginTop={5}
				>
					Payment Successful
				</Typography>
				<Button
					variant="contained"
					// color="primary"
					onClick={handlePaymentSuccess}
					sx={{ marginTop: 4, color: "black" }}
				>
					Proceed to Cockpit AI
				</Button>
			</Box>
		</Container>
	);
};

export default Success;
