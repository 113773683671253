import { useContext, useMemo, useState } from "react";
import {
	createUserWithEmailAndPassword,
	sendEmailVerification,
} from "firebase/auth";
import { auth } from "../firebase/firebase-config";
import { useNavigate } from "react-router-dom";
import * as userActions from "../actions/userActions";
import { AlertStyled, TextFieldStyled } from "../ux/formStyles";
import { BoxStyled } from "../ux/mainAuthPageStyles";
import { StartButtonStyled } from "../ux/onboardingStyles";
import { bindActionCreators } from "../actions/actionCreators";
import { TodoContext } from "./todoContext";
import { IconButton, InputAdornment } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const SignUpForm = () => {
	const { dispatch } = useContext(TodoContext);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [agreeToTerms, setAgreeToTerms] = useState(false);
	const [error, setError] = useState("");
	const navigate = useNavigate();
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const actions = useMemo(
		() => ({
			users: bindActionCreators(
				userActions,
				dispatch
			) as unknown as userActions.UserActions,
		}),
		[dispatch]
	);

	const signUp = async () => {
		if (password !== confirmPassword) {
			setError("Passwords do not match");
			return;
		}
		try {
			const userCredential = await createUserWithEmailAndPassword(
				auth,
				email,
				password
			);
			const user = userCredential.user;
			await sendEmailVerification(user);
			actions.users.signUp({
				email: userCredential?.user?.email as string,
				name: userCredential?.user?.displayName as string,
			});
			navigate("/post-signup");
		} catch (error) {
			setError((error as Error).message);
		}
	};

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	const toggleConfirmPasswordVisibility = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};

	return (
		<BoxStyled>
			{error && <AlertStyled severity="error">{error}</AlertStyled>}
			<TextFieldStyled
				label="Email address"
				type="email"
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				inputProps={{
					autoComplete: "off",
				}}
			/>
			<TextFieldStyled
				label="Password"
				type={showPassword ? "text" : "password"}
				value={password}
				onChange={(e) => setPassword(e.target.value)}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton onClick={togglePasswordVisibility} edge="end">
								{showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
							</IconButton>
						</InputAdornment>
					),
					autoComplete: "new-password",
				}}
			/>
			<TextFieldStyled
				label="Confirm Password"
				type={showConfirmPassword ? "text" : "password"}
				value={confirmPassword}
				onChange={(e) => setConfirmPassword(e.target.value)}
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton onClick={toggleConfirmPasswordVisibility} edge="end">
								{showConfirmPassword ? (
									<VisibilityOffIcon />
								) : (
									<VisibilityIcon />
								)}
							</IconButton>
						</InputAdornment>
					),
					autoComplete: "off",
				}}
			/>
			<StartButtonStyled
				variant="contained"
				onClick={signUp}
				sx={{ marginTop: 5, width: "fit-content" }}
			>
				Create Account
			</StartButtonStyled>
		</BoxStyled>
	);
};

export default SignUpForm;
