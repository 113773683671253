import { Dispatch } from "react";
import { User } from "../models/user";
import { ActionTypes } from "./common";
import {
	createPayloadAction,
	PayloadAction,
	ActionMethod,
} from "./actionCreators";
import config from "../config";
import { AdminService } from "../services/adminService";
import {
	Conversation,
	Pagination,
	UserQueryOptions,
	chatMessage,
} from "../services/restService";
import { OnboardingData } from "../models/onboarding";
import { AxiosResponse } from "axios";

const adminService = new AdminService(config.api.baseUrl, "/admin");

interface ConversationResponse {
	conversations: Conversation[];
	pagination: Pagination;
}

export interface AdminActions {
	getUserByUid(uid: string): Promise<User>;
	// get all users accepts query params for pagination (page, limit, search)
	getAllUsers(queryOptions?: UserQueryOptions): Promise<User[]>;
	exportUsersToCSV(): Promise<AxiosResponse<Blob>>;
	deleteUserByUid(uid: string): Promise<void>;
	updateUserByUid(uid: string, entity: User): Promise<User>;
	createUser(entity: User): Promise<User>;
	getWaitingList(): Promise<User[]>;
	// approve users from waiting list. Receives a list of uids
	approveUsersFromWaitingList(uids: string[]): Promise<User[]>;
	getOnboardingDataByUid(uid: string): Promise<OnboardingData>;
	editOnboardingDataByUid(
		uid: string,
		onboardingData: OnboardingData
	): Promise<OnboardingData>;
	getConversationsByUid(
		uid: string,
		page: number,
		limit: number
	): Promise<ConversationResponse>;
	listMessages(threadId: string): Promise<chatMessage[]>;
	searchUsersByEmail(email: string): Promise<User[]>;
}

export const getUserByUid =
	(uid: string): ActionMethod<User> =>
	async (dispatch: Dispatch<GetUserByUidAction>) => {
		const user = await adminService.getUserByUid(uid);
		dispatch(getUserByUidAction(user));
		return user;
	};

export const getAllUsers =
	(queryOptions?: UserQueryOptions): ActionMethod<User[]> =>
	async (dispatch: Dispatch<GetAllUsersAction>) => {
		const users = await adminService.getAllUsers(queryOptions);
		dispatch(getAllUsersAction(users));
		return users;
	};

export const exportUsersToCSV =
	(): ActionMethod<AxiosResponse<Blob>> =>
	async (dispatch: Dispatch<ExportUsersToCSV>) => {
		const response = await adminService.exportUsersToCSV();
		dispatch(exportUsersToCSVAction());
		return response;
	};

export const deleteUserByUid =
	(uid: string): ActionMethod<void> =>
	async (dispatch: Dispatch<DeleteUserByUidAction>) => {
		await adminService.deleteUserByUid(uid);
		dispatch(deleteUserByUidAction());
	};

export const updateUserByUid =
	(uid: string, entity: User): ActionMethod<User> =>
	async (dispatch: Dispatch<UpdateUserByUidAction>) => {
		const user = await adminService.updateUserByUid(uid, entity);
		dispatch(updateUserByUidAction(user));
		return user;
	};

export const createUser =
	(entity: User): ActionMethod<User> =>
	async (dispatch: Dispatch<CreateUserAction>) => {
		const user = await adminService.createUser(entity);
		dispatch(createUserAction(user));
		return user;
	};

export const getWaitingList =
	(): ActionMethod<User[]> => async (dispatch: Dispatch<GetAllUsersAction>) => {
		const users = await adminService.getWaitingList();
		dispatch(getAllUsersAction(users));
		return users;
	};

export const approveUsersFromWaitingList =
	(uids: string[]): ActionMethod<User[]> =>
	async (dispatch: Dispatch<ApproveUsersFromWaitingListAction>) => {
		const users = await adminService.approveUsersFromWaitingList(uids);
		dispatch(appoveUsersFromWaitingListAction(users));
		return users;
	};

export const getOnboardingDataByUid =
	(uid: string): ActionMethod<OnboardingData> =>
	async (dispatch: Dispatch<GetOnboardingDataByUidAction>) => {
		const onboardingData = await adminService.getOnboardingDataByUid(uid);
		dispatch(getOnboardingDataByUidAction(onboardingData));
		return onboardingData;
	};

export const editOnboardingDataByUid =
	(uid: string, onboardingData: OnboardingData): ActionMethod<OnboardingData> =>
	async (dispatch: Dispatch<EditOnboardingDataByUidAction>) => {
		const updatedOnboardingData = await adminService.editOnboardingDataByUid(
			uid,
			onboardingData
		);
		dispatch(editOnboardingDataByUidAction(updatedOnboardingData));
		return updatedOnboardingData;
	};

export const getConversationsByUid =
	(
		uid: string,
		page: number,
		limit: number
	): ActionMethod<ConversationResponse> =>
	async (dispatch: Dispatch<GetConversationsByUidAction>) => {
		const response = await adminService.getConversationsByUid(uid, page, limit);
		dispatch(getConversationsByUidAction(response));
		return response;
	};

export const listMessages =
	(threadId: string): ActionMethod<chatMessage[]> =>
	async (dispatch: Dispatch<PayloadAction<string, chatMessage[]>>) => {
		const messages = await adminService.listMessages(threadId);
		dispatch({
			type: ActionTypes.LIST_MESSAGES,
			payload: messages,
		});
		return messages;
	};

export const searchUsersByEmail =
	(email: string): ActionMethod<User[]> =>
	async (dispatch: Dispatch<GetAllUsersAction>) => {
		const users = await adminService.searchUsersByEmail(email);
		dispatch(getAllUsersAction(users));
		return users;
	};

export interface GetUserByUidAction extends PayloadAction<string, User> {
	type: ActionTypes.GET_USER_BY_UID;
}

export interface GetAllUsersAction extends PayloadAction<string, User[]> {
	type: ActionTypes.GET_ALL_USERS;
}

export interface ExportUsersToCSV extends PayloadAction<string, void> {
	type: ActionTypes.EXPORT_USERS_TO_CSV;
}

export interface DeleteUserByUidAction extends PayloadAction<string, void> {
	type: ActionTypes.DELETE_USER_BY_UID;
}

export interface UpdateUserByUidAction extends PayloadAction<string, User> {
	type: ActionTypes.UPDATE_USER_BY_UID;
}

export interface CreateUserAction extends PayloadAction<string, User> {
	type: ActionTypes.CREATE_USER;
}

export interface GetWaitingListAction extends PayloadAction<string, User[]> {
	type: ActionTypes.GET_WAITING_LIST;
}

export interface ApproveUsersFromWaitingListAction
	extends PayloadAction<string, User[]> {
	type: ActionTypes.APPROVE_USERS_FROM_WAITING_LIST;
}

export interface GetOnboardingDataByUidAction
	extends PayloadAction<string, OnboardingData> {
	type: ActionTypes.GET_ONBOARDING_DATA_BY_UID;
}

export interface EditOnboardingDataByUidAction
	extends PayloadAction<string, OnboardingData> {
	type: ActionTypes.EDIT_ONBOARDING_DATA_BY_UID;
}

export interface GetConversationsByUidAction
	extends PayloadAction<string, ConversationResponse> {
	type: ActionTypes.GET_CONVERSATIONS_BY_UID;
}

export interface ListMessagesAction
	extends PayloadAction<string, chatMessage[]> {
	type: ActionTypes.LIST_MESSAGES;
}

export interface SearchUsersByEmailAction
	extends PayloadAction<string, User[]> {
	type: ActionTypes.SEARCH_USERS_BY_EMAIL;
}

export const getUserByUidAction = createPayloadAction<GetUserByUidAction>(
	ActionTypes.GET_USER_BY_UID
);

export const getAllUsersAction = createPayloadAction<GetAllUsersAction>(
	ActionTypes.GET_ALL_USERS
);

export const exportUsersToCSVAction = createPayloadAction<ExportUsersToCSV>(
	ActionTypes.EXPORT_USERS_TO_CSV
);

export const deleteUserByUidAction = createPayloadAction<DeleteUserByUidAction>(
	ActionTypes.DELETE_USER_BY_UID
);

export const updateUserByUidAction = createPayloadAction<UpdateUserByUidAction>(
	ActionTypes.UPDATE_USER_BY_UID
);

export const createUserAction = createPayloadAction<CreateUserAction>(
	ActionTypes.CREATE_USER
);

export const getWaitingListAction = createPayloadAction<GetWaitingListAction>(
	ActionTypes.GET_WAITING_LIST
);

export const appoveUsersFromWaitingListAction =
	createPayloadAction<ApproveUsersFromWaitingListAction>(
		ActionTypes.APPROVE_USERS_FROM_WAITING_LIST
	);

export const getOnboardingDataByUidAction =
	createPayloadAction<GetOnboardingDataByUidAction>(
		ActionTypes.GET_ONBOARDING_DATA_BY_UID
	);

export const editOnboardingDataByUidAction =
	createPayloadAction<EditOnboardingDataByUidAction>(
		ActionTypes.EDIT_ONBOARDING_DATA_BY_UID
	);

export const getConversationsByUidAction =
	createPayloadAction<GetConversationsByUidAction>(
		ActionTypes.GET_CONVERSATIONS_BY_UID
	);

export const listMessagesAction = createPayloadAction<ListMessagesAction>(
	ActionTypes.LIST_MESSAGES
);

export const searchUsersByEmailAction =
	createPayloadAction<SearchUsersByEmailAction>(
		ActionTypes.SEARCH_USERS_BY_EMAIL
	);
