import { Box, Button, Stack, Checkbox } from "@mui/material";
import { styled } from "@mui/material/styles";
import { theme } from "./theme";

export const BoxStyled = styled(Box)({
	// height: "50vh",
	width: "100%",
	// background: "#131318",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",
});

export const ButtonContainerStyled = styled(Stack)({
	// background: "#1B1B21",
	// background: theme.palette.secondary.main,
	width: "22rem",
	padding: "2rem 1rem",
	justifyContent: "space-around",
	alignItems: "center",
	display: "flex",
	flexDirection: "column",
	gap: "1rem",
	borderRadius: "1rem",
	textTransform: "none",
	marginTop: "2rem",
	marginBottom: "2rem",

	// Responsive styling for desktop
	"@media (min-width: 600px)": {
		width: "50rem",
		padding: "2rem 1rem",
		background: "none",
	},
});

export const ButtonStyled = styled(Button)({
	borderRadius: "1rem",
	border: "1px solid var(--Cockpit-AI-sys-dark-outline, #918F9A)",
	// background: "#2c2c2e",
	// color: "var(--Cockpit-AI-sys-dark-on-primary-container, #E1DFFF)",
	fontWeight: "500",
	marginTop: 10,
	padding: "0.625rem 1rem",
	height: 50,

	// change the contained style
	"&.MuiButton-contained": {
		background: "var(--Cockpit-AI-sys-dark-primary, #DCF032)",
		color: "var(--Cockpit-AI-sys-dark-on-primary-container, #0F0F3C)",
		boxShadow: "none",
	},

	// Responsive styling for desktop
	"@media (min-width: 600px)": {
		width: "60%",
	},
});

export const LoginButtonStyled = styled(ButtonStyled)({
	border: "none",
	background: "none",
	fontWeight: "600",

	// Responsive styling for desktop
	"@media (min-width: 600px)": {
		width: "60%",
	},
});

export const CheckboxStyled = styled(Checkbox)({
	color: "var(--Cockpit-AI-sys-dark-on-primary-container, #DCF032)",
	// checked color
	"&.Mui-checked": {
		color: "var(--Cockpit-AI-sys-dark-on-primary-container, #DCF032)",
	},
});
