import React, {
	createContext,
	useContext,
	useState,
	useEffect,
	FC,
} from "react";
import { ThemeProvider as MuiThemeProvider, createTheme } from "@mui/material";
import { theme, lightTheme } from "../ux/theme";

const ThemeContext = createContext({
	toggleTheme: () => {
		console.log("toggleTheme");
	},
	isDarkTheme: true,
});

export const ThemeProvider: FC = ({ children }) => {
	// Get initial theme from local storage or default to dark theme
	const initialTheme = localStorage.getItem("theme") === "dark" ? true : false;
	const [isDarkTheme, setIsDarkTheme] = useState(initialTheme);

	// Update local storage whenever theme changes
	useEffect(() => {
		localStorage.setItem("theme", isDarkTheme ? "dark" : "light");
	}, [isDarkTheme]);

	const toggleTheme = () => {
		setIsDarkTheme((prev) => !prev);
	};

	const appliedTheme = createTheme(isDarkTheme ? theme : lightTheme);

	return (
		<ThemeContext.Provider value={{ toggleTheme, isDarkTheme }}>
			<MuiThemeProvider theme={appliedTheme}>{children}</MuiThemeProvider>
		</ThemeContext.Provider>
	);
};

export const useThemeContext = () => useContext(ThemeContext);
