import React, { useState } from "react";
import {
	Box,
	CircularProgress,
	IconButton,
	ImageList,
	ImageListItem,
	ImageListItemBar,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import {
	ChatButtonStyled,
	StyledFieldTypography,
} from "../../../../ux/chatStyles";
import ImageViewer from "./ImageViewer";

interface ImageGenerationProps {
	threadId: string;
	platform: string;
	contentDetail: string; // Can be feedOrStory or postOrArticle
	assistantId: string;
	generateImageBasedOnThread: (
		threadId: string,
		assistantType: string
	) => Promise<any>;
}

const ImageGeneration: React.FC<ImageGenerationProps> = ({
	threadId,
	platform,
	contentDetail,
	assistantId,
	generateImageBasedOnThread,
}) => {
	const [askForImage, setAskForImage] = useState<boolean>(true); // Show the initial question
	const [imageLoading, setImageLoading] = useState<boolean>(false);
	const [b64JsonArray, setB64JsonArray] = useState<string[]>([]);
	const [userSatisfied, setUserSatisfied] = useState<boolean | null>(null);
	const [viewerOpen, setViewerOpen] = useState<boolean>(false);
	const [currentImage, setCurrentImage] = useState<string>("");

	const handleImageRequest = async () => {
		setImageLoading(true);
		try {
			const imageResponse = await generateImageBasedOnThread(
				threadId,
				assistantId
			);
			setB64JsonArray((prevImages) => [
				...prevImages,
				...imageResponse.images.data.map((item: any) => item.b64_json),
			]);
			setUserSatisfied(null); // Reset satisfaction question for new images
		} catch (error) {
			console.error("Image request failed", error);
		} finally {
			setImageLoading(false);
			setAskForImage(false);
		}
	};

	return (
		<>
			{!imageLoading && askForImage && (
				<Box mt={2}>
					<StyledFieldTypography gutterBottom>
						Would you like an image for your {platform} {contentDetail}?
					</StyledFieldTypography>
					<ChatButtonStyled
						type="button"
						onClick={handleImageRequest}
						variant="contained"
					>
						Yes
					</ChatButtonStyled>
					<ChatButtonStyled
						type="button"
						onClick={() => setAskForImage(false)}
						variant="contained"
						style={{ marginLeft: "10px" }}
					>
						No
					</ChatButtonStyled>
				</Box>
			)}

			{b64JsonArray.length > 0 && (
				<Box mt={2}>
					<StyledFieldTypography gutterBottom>
						Here are the images for your {platform} {contentDetail}:
					</StyledFieldTypography>
					<ImageList cols={3} gap={8}>
						{b64JsonArray.map((b64Json: string, index: number) => (
							<ImageListItem key={index}>
								<img
									src={`data:image/png;base64,${b64Json}`}
									alt={`Generated ${index + 1}`}
									loading="lazy"
									onClick={() => {
										setCurrentImage(`data:image/png;base64,${b64Json}`);
										setViewerOpen(true);
									}}
								/>
								<ImageListItemBar
									position="top"
									actionIcon={
										<IconButton
											sx={{ color: "#DCF032" }}
											aria-label={`download image ${index + 1}`}
											href={`data:image/png;base64,${b64Json}`}
											download={`generated-image-${platform}-${contentDetail}-${
												index + 1
											}.png`}
										>
											<DownloadIcon />
										</IconButton>
									}
									actionPosition="right"
								/>
							</ImageListItem>
						))}
					</ImageList>
				</Box>
			)}
			{!imageLoading && b64JsonArray.length > 0 && userSatisfied === null && (
				<Box mt={2}>
					<StyledFieldTypography gutterBottom>
						Are you satisfied with the generated images?
					</StyledFieldTypography>
					<ChatButtonStyled
						variant="contained"
						color="primary"
						onClick={() => setUserSatisfied(true)}
						disabled={userSatisfied !== null}
					>
						Yes
					</ChatButtonStyled>
					<ChatButtonStyled
						variant="contained"
						color="secondary"
						onClick={handleImageRequest}
						style={{ marginLeft: "10px" }}
						disabled={userSatisfied !== null}
					>
						No, generate another one
					</ChatButtonStyled>
				</Box>
			)}
			{imageLoading && <CircularProgress />}
			<ImageViewer
				open={viewerOpen}
				src={currentImage}
				onClose={() => setViewerOpen(false)}
			/>
		</>
	);
};

export default ImageGeneration;
