import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useAuth } from "./AuthContext";
import { AppContext } from "../models/applicationState";
import { TodoContext } from "../components/todoContext";
import { bindActionCreators } from "../actions/actionCreators";
import * as userActions from "../actions/userActions";

type UserData = {
	uid: string;
	provider: string;
	name: string;
	email: string;
	phone?: string;
	companyName?: string;
	roleOnTeam?: string;
	businessType?: string;
	teamSize?: string;
	isProfileComplete?: boolean;
	createdDate?: Date;
	updatedDate?: Date;
	isOnWaitingList?: boolean;
	isOnboardingComplete?: boolean;
	role?: string;
	isSubscribed?: boolean;
	onboardingData?: {
		logo: string;
		aboutBrand: string;
		businessGoals: string;
		competitor1: string;
		competitor2: string;
		competitor3: string;
		idealCustomer: string;
		facebook: string;
		instagram: string;
		tiktok: string;
		linkedin: string;
		pinterest: string;
		youtube: string;
		x: string;
		expansionPlan: string;
		flagshipProductsServices: string;
		leastProfitableOfferings: string;
		primaryTypeface: string;
		secondaryTypeface: string;
		primaryColor: string;
		secondaryColor: string;
		tertiaryColor: string;
	};
};

interface ContextProps {
	readonly userData: UserData | null;
	readonly setUserData: (userData: UserData) => void;
	readonly loadUserData: () => Promise<void>;
	readonly loading: boolean; // Add this line
}

export const UserContext = createContext<ContextProps>({
	userData: null,
	setUserData: () => {
		console.error("No user provider");
	},
	loadUserData: async () => {
		console.error("No user provider");
	},
	loading: false,
});

export const UserProvider: React.FC = ({ children }) => {
	const [userData, setUserData] = useState<UserData | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const { user } = useAuth();

	const appContext = useContext<AppContext>(TodoContext);
	const actions = useMemo(
		() => ({
			users: bindActionCreators(
				userActions,
				appContext.dispatch
			) as unknown as userActions.UserActions,
		}),
		[appContext.dispatch]
	);

	const value = {
		userData,
		setUserData,
		loading,
		loadUserData: async () => {
			try {
				setLoading(true);
				const response: UserData = (await actions.users.getMe()) as UserData;
				setUserData(response);
			} catch (error) {
				console.error("Failed to load user data:", error);
			} finally {
				setLoading(false);
			}
		},
	};

	return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
