import {
	getTheme,
	IStackItemTokens,
	IStackStyles,
	IStackTokens,
} from "@fluentui/react";
const theme = getTheme();

export const rootStackStyles: IStackStyles = {
	root: {
		height: "100%", // This ensures the stack takes the full viewport height
		overflow: "auto",
		overflowY: "hidden",
		// width: "100%",
		// background: theme.palette.secondary.main,
		// display: "flex",
		// alignItems: "center", // center vertically in the viewport
		// justifyContent: "left", // center horizontally in the viewportull viewport height
		// overflow: "hidden",
	},
};

export const headerStackStyles: IStackStyles = {
	root: {
		height: 48,
		background: theme.palette.themeDarker,
		zIndex: 1000,
	},
};

export const listItemsStackStyles: IStackStyles = {
	root: {
		padding: "10px",
	},
};

export const mainStackStyles: IStackStyles = {
	root: {
		display: "flex",
		// alignItems: "center", // center vertically in the viewport
		justifyContent: "center", // center horizontally in the viewport
		overflowY: "hidden",
		height: "100%",

		// on desktop justifies content to the left
		"@media (min-width: 600px)": {
			justifyContent: "left",
		},
	},
};

export const sidebarStackStyles: IStackStyles = {
	root: {
		minWidth: 300,
		background: theme.palette.neutralPrimary,
		boxShadow: theme.effects.elevation8,
	},
};

export const titleStackStyles: IStackStyles = {
	root: {
		alignItems: "center",
		background: theme.palette.neutralPrimaryAlt,
	},
};

export const stackPadding: IStackTokens = {
	padding: 10,
};

export const stackGaps: IStackTokens = {
	childrenGap: 10,
};

export const stackItemPadding: IStackItemTokens = {
	padding: 10,
};

export const stackItemMargin: IStackItemTokens = {
	margin: 10,
};

export const buttonContainerStyles: IStackStyles = {
	root: {
		background: "#000",
		width: "100%",
		// Takes approximately 1/3 of the screen height
		height: "calc(100vh / 4)",
		justifyContent: "space-around", // Centers buttons vertically
		alignItems: "center", // Centers buttons horizontally
		display: "flex",
		flexDirection: "column",
		// top border radius 15, bottom border radius 0
		borderRadius: "15px 15px 0 0",
		padding: 20,
		// negative margin to make the buttons container bottom border disappear
		// marginBottom: -12,
	},
};

export const buttonStyles = {
	root: {
		borderRadius: 8,
		border: "2px solid #333",
		background: "#2c2c2e",
		color: "#fff",
		marginTop: 10,
		width: "100%",
		height: 40,
	},
};

export const loginButtonStyles = {
	root: {
		borderRadius: 8,
		border: "2px solid #333",
		background: "#fff",
		color: "#000",
		marginTop: 10,
		width: "100%",
		height: 40,
	},
};
