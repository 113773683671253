import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

interface ImageViewerProps {
	open: boolean;
	src: string;
	onClose: () => void;
}

const ImageViewer: React.FC<ImageViewerProps> = ({ open, src, onClose }) => {
	return (
		<Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
			<IconButton
				aria-label="close"
				onClick={onClose}
				sx={{
					position: "absolute",
					right: 8,
					top: 8,
					color: (theme) => theme.palette.grey[500],
				}}
			>
				<CloseIcon />
			</IconButton>
			<DialogContent>
				<img
					src={src}
					alt="Full Size"
					style={{ width: "100%", height: "auto" }}
				/>
			</DialogContent>
		</Dialog>
	);
};

export default ImageViewer;
