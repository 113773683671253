import React, { useContext } from "react";
import { Box, Typography } from "@mui/material";
import { PostSignupContext } from "../../context/PostSingupContext";
import logo from "../../assets/Vector.svg";

export const StepIndicator = () => {
	const { currentStep } = useContext(PostSignupContext);
	const steps = [1, 2, 3]; // Assuming you have 5 steps, adjust as needed

	return (
		<>
			{/* <Box
				sx={{
					width: "100%", // This will make the logo take the full width of the container
					display: "flex",
					justifyContent: "left",
					alignItems: "center",
					mb: 4, // Add some margin bottom to separate it from the content below
				}}
			>
				<img
					src={logo}
					alt="Logo"
					style={{ width: "7rem", marginTop: "2rem" }}
				/>
			</Box> */}
			<Box
				sx={{
					display: "flex",
					justifyContent: "left",
					alignItems: "center",
					mb: 4,
					mt: 4,
					// Additional styles for the overall box if needed
				}}
			>
				<Typography
					variant="body1"
					sx={{
						mr: 2, // margin on right of the step indicator
						color: "#7777AB",
					}}
				>
					{currentStep} of {steps.length}
				</Typography>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						// backgroundColor: "#202029",
						borderRadius: "2rem",
						padding: "0.5rem 1rem",
					}}
				>
					{steps.map((step, index) => (
						<Box
							key={step}
							sx={{
								display: "flex",
								alignItems: "center",
							}}
						>
							<Box
								sx={{
									width: "1.375rem",
									height: "0.375rem",
									borderRadius: "1rem",
									backgroundColor:
										currentStep >= step ? "primary.main" : "#e0e0e0", // Active or completed steps are primary color
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									color:
										currentStep >= step
											? "primary.contrastText"
											: "text.secondary",
									fontWeight: "bold",
									// Additional styles for the number boxes if needed
								}}
							/>
							{index !== steps.length - 1 && (
								<Box
									sx={{
										flexGrow: 1,
										mx: 1, // margin on left and right of the connector
										height: 5,
										backgroundColor:
											currentStep > step ? "primary.main" : "#e0e0e0", // Active or completed connector bars are primary color
										// Additional styles for the connector bars if needed
									}}
								/>
							)}
						</Box>
					))}
				</Box>
			</Box>
		</>
	);
};
