import React, { useState, useContext, useMemo, useEffect, useRef } from "react";
import {
	TextField,
	Slider,
	FormGroup,
	Box,
	Paper,
	Container,
	IconButton,
	CircularProgress,
	Tooltip,
	useTheme,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import * as userActions from "../../../actions/userActions";
import {
	ChatButtonStyled,
	ChatItemContainer,
	ChatToggleButton,
	HeaderTypography,
	StyledFieldTypography,
} from "../../../ux/chatStyles";
import { TodoContext } from "../../todoContext";
import chatHeaderCloseIcon from "../../../assets/chatHeaderClose.svg";
import writeABlogPostIcon from "../../../assets/writeABlogPostNew.svg";
import writeABlogPostIconLight from "../../../assets/writeABlogPostLight.svg";
import { bindActionCreators } from "../../../actions/actionCreators";
import MarkdownViewer from "../../../utils/MarkdownRenderer";
import { ToggleButton } from "./components/ToggleButton";
import { copyToClipboard, formatMessage } from "../../../utils/chatUtils";
import ImageGeneration from "./components/ImageGeneration";
import Loader from "../../loader";

interface WriteBlogPostItemProps {
	onSendMessage: (message: any) => void;
	onClose: () => void;
}

const blogPostTypes = [
	"Product Launch",
	"Interview",
	"How to guide",
	"Case Study",
	"Feature Spotlight",
	"Service",
	"Product Description",
	"Other",
];

const toneOfVoiceOptions = [
	"Informative",
	"Inspirational",
	"Formal",
	"Persuasive",
	"Friendly",
	"Humorous",
	"Professional",
	"Other",
];

const WriteBlogPostItem: React.FC<WriteBlogPostItemProps> = ({
	onSendMessage,
	onClose,
}) => {
	const { dispatch } = useContext(TodoContext);
	const [threadId, setThreadId] = useState<string>("");
	const [loading, setLoading] = useState(false);
	const [blogPostLength, setBlogPostLength] = useState(400);
	const [blogPostType, setBlogPostType] = useState("");
	const [blogPostTypeOther, setBlogPostTypeOther] = useState("");
	const [blogPostDetails, setBlogPostDetails] = useState("");
	const [toneOfVoice, setToneOfVoice] = useState<string[]>([]);
	const [toneOfVoiceOther, setToneOfVoiceOther] = useState("");
	const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
	const [response, setResponse] = useState<string | null>(null);
	const [copied, setCopied] = useState(false);

	const [currentStreamId, setCurrentStreamId] = useState<string | null>(null);
	const [streamedResponse, setStreamedResponse] = useState<string>("");
	const responseRef = useRef<HTMLDivElement>(null);

	const theme = useTheme();
	const themeType = theme.palette.mode;

	const actions = useMemo(
		() => ({
			users: bindActionCreators(
				userActions,
				dispatch
			) as unknown as userActions.UserActions,
		}),
		[dispatch]
	);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const handleBlogPostTypeChange = (type: string) => {
		setBlogPostType(type);
		if (type === "Other") {
			// Open input for other type
			setBlogPostDetails("");
		} else {
			setBlogPostDetails(""); // Ensure blog post details are reset if type changes
		}
	};

	const handleToneOfVoiceChange = (tone: string) => {
		if (!toneOfVoice.includes(tone)) {
			setToneOfVoice([...toneOfVoice, tone]);
		} else {
			setToneOfVoice(toneOfVoice.filter((t) => t !== tone));
		}
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setLoading(true);

		const blogPostData = {
			blogPostLength,
			blogPostType: blogPostTypeOther ? blogPostTypeOther : blogPostType,
			blogPostDetails,
			// Ensure "Other" is correctly included if provided
			toneOfVoice: toneOfVoiceOther
				? [...toneOfVoice, `Other: ${toneOfVoiceOther}`]
				: toneOfVoice,
		};

		const streamId = Date.now().toString();
		setCurrentStreamId(streamId);

		try {
			await actions.users.writeBlogPostStream(
				blogPostData,
				(chunk) => {
					console.log("Chunk received", chunk);
					setStreamedResponse((prev) => {
						const threadIdMatch = chunk.match(/threadId:([^\s]+)/);
						if (threadIdMatch) {
							setThreadId(threadIdMatch[1]);
							return prev + chunk.replace(/threadId:[^\s]+/, "");
						}
						return prev + chunk;
					});
					if (responseRef.current) {
						responseRef.current.scrollTop = responseRef.current.scrollHeight;
					}
				},
				() => {
					setIsSubmitted(true);
					setLoading(false);
					setCurrentStreamId(null);
				},
				(error) => {
					console.error("Submission failed", error);
					setLoading(false);
					setCurrentStreamId(null);
				}
			);
		} catch (error) {
			console.error("Failed to send blog post data", error);
			setLoading(false);
			setCurrentStreamId(null);
		}
	};

	if (isSubmitted || streamedResponse) {
		return (
			<ChatItemContainer id="chat-container">
				<Paper
					style={{
						marginTop: "40px",
						marginBottom: "20px",
						borderRadius: "0.75rem",
						padding: "20px",
					}}
				>
					<Container
						sx={{ display: "flex", alignItems: "center", padding: "10px" }}
					>
						<img
							src={
								themeType === "dark"
									? writeABlogPostIcon
									: writeABlogPostIconLight
							}
							alt="Write a Blog Post"
							style={{ marginBottom: "2px" }}
						/>
						<HeaderTypography
							variant="h6"
							sx={{ display: "flex", alignItems: "center" }}
						>
							Blog Post Submission
						</HeaderTypography>
						<IconButton onClick={onClose} sx={{ marginLeft: "auto" }}>
							<img src={chatHeaderCloseIcon} alt="Minimize" />
						</IconButton>
					</Container>
					<div
						ref={responseRef}
						style={{
							color: "#FFF",
							wordWrap: "break-word",
							whiteSpace: "pre-wrap",
							display: "flex",
							alignItems: "flex-start",
							maxHeight: "70vh",
							overflowY: "auto",
						}}
					>
						<MarkdownViewer markdownText={formatMessage(streamedResponse)} />
						<Tooltip title="Copy">
							<IconButton
								onClick={() => copyToClipboard(streamedResponse, setCopied)}
								size="small"
							>
								{copied ? (
									<CheckCircleIcon
										style={{ color: theme.palette.success.main }}
									/>
								) : (
									<ContentCopyIcon
										style={{ color: theme.palette.primary.main }}
									/>
								)}
							</IconButton>
						</Tooltip>
					</div>
					<ImageGeneration
						threadId={threadId}
						platform={blogPostType}
						contentDetail={"blog"}
						assistantId="createSocialMediaContentAssistant"
						generateImageBasedOnThread={
							actions.users.generateImageBasedOnThread
						}
					/>
				</Paper>
			</ChatItemContainer>
		);
	}

	return (
		<ChatItemContainer>
			<Paper
				sx={{
					marginTop: "20px",
					marginBottom: "20px",
					borderRadius: "0.75rem",
					padding: "20px",
				}}
			>
				<Container
					sx={{
						display: "flex",
						alignItems: "center",
						padding: "10px",
					}}
				>
					<img
						src={
							themeType === "dark"
								? writeABlogPostIcon
								: writeABlogPostIconLight
						}
						alt="Write a Blog Post"
						style={{ marginBottom: "2px" }}
					/>
					<HeaderTypography
						variant="h6"
						sx={{ display: "flex", alignItems: "center" }}
					>
						Write a Blog Post
					</HeaderTypography>
					<IconButton onClick={onClose} sx={{ marginLeft: "auto" }}>
						<img src={chatHeaderCloseIcon} alt="Minimize" />
					</IconButton>
				</Container>
				{loading ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							width: "100%",
							padding: "20px",
						}}
					>
						<Loader />
					</div>
				) : (
					<FormGroup>
						<Box>
							<StyledFieldTypography gutterBottom>
								How long should the blog post be?
							</StyledFieldTypography>
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<Slider
									aria-label="Blog Post Length"
									value={blogPostLength}
									onChange={(_, newValue) =>
										setBlogPostLength(newValue as number)
									}
									step={100}
									marks
									min={100}
									max={2000}
									valueLabelDisplay="auto"
								/>
								<Box
									sx={{
										minWidth: 55,
										marginLeft: 2,
										textAlign: "center",
										color: "#fff",
									}}
									// make it not selectable or clickable
									style={{ pointerEvents: "none" }}
								>
									<TextField
										id="blog-post-length"
										value={blogPostLength}
										InputProps={{
											readOnly: true,
											style: { textAlign: "center" },
										}}
										variant="outlined"
										size="small"
										style={{ maxWidth: "6.2em" }}
										sx={{
											marginLeft: "auto",
											marginRight: "auto",
											border: "1px solid #938F99",
											borderRadius: "0.5rem",
											input: { textAlign: "center", marginTop: "0.1rem" },
										}}
										// make is not selectable or clickable
										inputProps={{ style: { pointerEvents: "none" } }}
									/>
								</Box>
							</Box>
						</Box>
						<Box mt={2}>
							<StyledFieldTypography gutterBottom>
								What is the blog post about?
							</StyledFieldTypography>
							{blogPostTypes.map((type) => (
								<ToggleButton
									key={type}
									selected={blogPostType.includes(type)}
									onClick={() => handleBlogPostTypeChange(type)}
								>
									{type}
								</ToggleButton>
							))}
							{blogPostType.includes("Other") && (
								<TextField
									label="Please specify"
									value={blogPostTypeOther}
									onChange={(e) => setBlogPostTypeOther(e.target.value)}
									sx={{ mt: 2, width: "100%" }}
								/>
							)}
							{/* Conditionally render this TextField based on selection */}
							{blogPostType.length > 0 && blogPostType[0] !== "Other" && (
								<TextField
									label={`Give us more information about the ${blogPostType.toLowerCase()}`}
									multiline
									rows={4}
									value={blogPostDetails}
									onChange={(e) => setBlogPostDetails(e.target.value)}
									sx={{ mt: 2, width: "100%" }}
								/>
							)}
						</Box>
						<Box mt={2}>
							<StyledFieldTypography gutterBottom>
								What should be the tone of voice?
							</StyledFieldTypography>
							{toneOfVoiceOptions.map((tone) => (
								<ToggleButton
									key={tone}
									selected={toneOfVoice.includes(tone)}
									onClick={() => handleToneOfVoiceChange(tone)}
								>
									{tone}
								</ToggleButton>
							))}
							{toneOfVoice.includes("Other") && (
								<TextField
									label="Please specify"
									value={toneOfVoiceOther}
									onChange={(e) => setToneOfVoiceOther(e.target.value)}
									sx={{ mt: 2, width: "100%" }}
								/>
							)}
						</Box>
						<ChatButtonStyled
							variant="contained"
							type="submit"
							onClick={handleSubmit}
							disabled={loading}
							sx={{ mt: 2 }}
						>
							{loading ? <CircularProgress size={24} /> : "Submit"}
						</ChatButtonStyled>
					</FormGroup>
				)}
			</Paper>
		</ChatItemContainer>
	);
};

export default WriteBlogPostItem;
