import React, { useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import * as adminActions from "../../actions/adminActions";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Pagination,
	Button,
} from "@mui/material";
import { bindActionCreators } from "../../actions/actionCreators";
import { TodoContext } from "../todoContext";
import { AppContext } from "../../models/applicationState";
import ChatHistory from "./ChatHistory"; // Import the ChatHistory component
import { StyledTableCell, StyledTableRow } from "../../ux/adminDashboardStyles";

interface Conversation {
	user_uid: string;
	threadId: string;
	assistantType: string;
	createdDate: Date;
	updatedDate: Date;
	file_ids: string[];
	tokenUsage: number;
	liked: boolean | null;
	generatedImages?: string[] | undefined;
}

interface Props {
	userUid: string;
	onClose: () => void;
}

const ConversationsTable: React.FC<Props> = ({ userUid, onClose }) => {
	const [conversations, setConversations] = useState<Conversation[]>([]);
	const [page, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [selectedConversation, setSelectedConversation] =
		useState<Conversation | null>(null);
	const limit = 10; // Set your limit here

	const appContext = useContext<AppContext>(TodoContext);
	const actions = useMemo(
		() => ({
			admin: bindActionCreators(
				adminActions,
				appContext.dispatch
			) as unknown as adminActions.AdminActions,
		}),
		[appContext.dispatch]
	);

	useEffect(() => {
		const fetchConversations = async () => {
			try {
				const response = await actions.admin.getConversationsByUid(
					userUid,
					page,
					limit
				);
				setConversations(response?.conversations);
				setTotalPages(response?.pagination?.pages);
			} catch (error) {
				console.error("Failed to fetch conversations", error);
			}
		};

		fetchConversations();
	}, [userUid, page]);

	const handlePageChange = (
		event: React.ChangeEvent<unknown>,
		value: number
	) => {
		setPage(value);
	};

	const handleRowClick = (conversation: Conversation) => {
		setSelectedConversation(conversation);
	};

	if (selectedConversation) {
		return (
			<ChatHistory
				threadId={selectedConversation.threadId}
				liked={selectedConversation.liked}
				tokenUsage={selectedConversation.tokenUsage}
				createdDate={selectedConversation.createdDate}
				assistantType={selectedConversation.assistantType}
				generatedImages={selectedConversation.generatedImages || []}
				onClose={() => setSelectedConversation(null)}
			/>
		);
	}

	return (
		<>
			<TableContainer component={Paper}>
				<Button onClick={onClose} style={{ float: "right" }}>
					Close
				</Button>
				<Table>
					<TableHead>
						<StyledTableRow>
							<StyledTableCell>Thread ID</StyledTableCell>
							<StyledTableCell>Assistant Type</StyledTableCell>
							<StyledTableCell>Created Date</StyledTableCell>
							<StyledTableCell>Updated Date</StyledTableCell>
							<StyledTableCell>File IDs</StyledTableCell>
							<StyledTableCell>Token Usage</StyledTableCell>
							<StyledTableCell>Liked</StyledTableCell>
						</StyledTableRow>
					</TableHead>
					<TableBody>
						{conversations?.map((conversation) => (
							<StyledTableRow
								key={conversation.threadId}
								onClick={() => handleRowClick(conversation)}
							>
								{" "}
								{/* Add onClick handler */}
								<StyledTableCell>{conversation.threadId}</StyledTableCell>
								<StyledTableCell>{conversation.assistantType}</StyledTableCell>
								<StyledTableCell>
									{new Date(conversation.createdDate).toLocaleString()}
								</StyledTableCell>
								<StyledTableCell>
									{new Date(conversation.updatedDate).toLocaleString()}
								</StyledTableCell>
								<StyledTableCell>
									{conversation.file_ids.join(", ")}
								</StyledTableCell>
								<StyledTableCell>{conversation.tokenUsage}</StyledTableCell>
								<StyledTableCell>
									{conversation.liked === null
										? "N/A"
										: conversation.liked
										? "Yes"
										: "No"}
								</StyledTableCell>
							</StyledTableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<Pagination count={totalPages} page={page} onChange={handlePageChange} />
		</>
	);
};

export default ConversationsTable;
