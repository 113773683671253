import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
	apiKey: "AIzaSyDV2Dhq_mY_BG1jV-kDnDOYU3KPmAfAF-c",
	authDomain: "cockpit-a2903.firebaseapp.com",
	projectId: "cockpit-a2903",
	storageBucket: "cockpit-a2903.appspot.com",
	messagingSenderId: "443083308858",
	appId: "1:443083308858:web:fed39710896d29faf45a03",
	measurementId: "G-JRVWS52RSE",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
