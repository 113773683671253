import React, { FC, useState, useEffect } from "react";
import {
	AppBar,
	Toolbar,
	IconButton,
	useTheme,
	Tooltip,
	Typography,
	Box,
	Button,
	Chip,
	Avatar,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import InfoIcon from "@mui/icons-material/Info"; // Import Info icon
import logo from "../assets/Vector.svg";
import logoLight from "../assets/VectorLight.svg";
import { useThemeContext } from "../context/ThemeContext";
import useCustomUserData from "../hooks/useCustomUserData";
import FullScreenDialog from "../components/menu/SettingsList";

const Header: FC = () => {
	const navigate = useNavigate();
	// const theme = useTheme();
	const { toggleTheme, isDarkTheme } = useThemeContext();
	const { customUserData } = useCustomUserData();
	const [isSettingsOpen, setIsSettingsOpen] = useState(false);
	const [showTooltip, setShowTooltip] = useState(false);
	const [trialMessage, setTrialMessage] = useState<string | null>(null);

	useEffect(() => {
		const tooltipShown = localStorage.getItem("tooltipShown");
		if (!tooltipShown) {
			setShowTooltip(true);
			localStorage.setItem("tooltipShown", "true");
		}

		if (
			customUserData &&
			!customUserData.isSubscribed &&
			customUserData.createdDate
		) {
			const createdDate = new Date(customUserData.createdDate);
			const currentDate = new Date();
			const trialDuration = 7;
			const daysPassed = Math.floor(
				(currentDate.getTime() - createdDate.getTime()) / (1000 * 3600 * 24)
			);
			const daysLeft = Math.max(0, trialDuration - daysPassed);

			if (daysLeft > 0) {
				setTrialMessage(
					`${daysLeft} day${daysLeft !== 1 ? "s" : ""} left - Upgrade  Now`
				);
			} else {
				setTrialMessage("Trial expired - Upgrade  Now");
			}
		} else {
			setTrialMessage(null);
		}
	}, [customUserData]);

	const handleMenuClick = () => setIsSettingsOpen(true);
	const handleSettingsClose = () => setIsSettingsOpen(false);
	const handleTooltipClose = () => setShowTooltip(false);
	const handleToggleTheme = () => {
		toggleTheme();
		setShowTooltip(false);
	};
	const handleTrialMessageClick = () => {
		// navigate to subscribe
		navigate("/subscribe");
	};

	return (
		<>
			<AppBar position="static" color="default" elevation={1}>
				<Toolbar
					sx={{
						justifyContent: "space-between",
						py: { xs: 1, sm: 2 },
						px: { xs: 2, sm: 3, md: 4 },
					}}
				>
					<Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
						<IconButton
							edge="start"
							color="inherit"
							aria-label="logo"
							onClick={() => navigate("/")}
							sx={{
								p: 0,
								"&:hover": {
									backgroundColor: "transparent",
								},
							}}
						>
							<Box
								component="img"
								src={isDarkTheme ? logo : logoLight}
								alt="Logo"
								sx={{
									height: { xs: "1.8rem", sm: "2.2rem" },
								}}
							/>
						</IconButton>

						{trialMessage && (
							<Box onClick={handleTrialMessageClick} sx={{ cursor: "pointer" }}>
								<Chip
									label={trialMessage}
									color="primary"
									size="small"
									avatar={
										<Avatar>
											<InfoIcon />
										</Avatar>
									}
									sx={{
										height: "auto",
										"& .MuiChip-label": {
											display: "block",
											whiteSpace: "normal",
											py: 0.5,
											color: "black",
										},
									}}
								/>
							</Box>
						)}
					</Box>

					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							gap: { xs: 1, sm: 2 },
						}}
					>
						<Tooltip
							title="New! Toggle theme"
							open={showTooltip}
							onClose={handleTooltipClose}
							disableHoverListener
							disableFocusListener
							disableTouchListener
							arrow
						>
							<IconButton
								color="inherit"
								aria-label="toggle-theme"
								onClick={handleToggleTheme}
								size="small"
							>
								{isDarkTheme ? "🌙" : "☀️"}
							</IconButton>
						</Tooltip>

						<Button
							variant="outlined"
							color="inherit"
							startIcon={<MenuIcon />}
							onClick={handleMenuClick}
							sx={{ display: { xs: "none", sm: "flex" } }}
						>
							Menu
						</Button>

						<IconButton
							color="inherit"
							aria-label="menu"
							onClick={handleMenuClick}
							sx={{ display: { xs: "flex", sm: "none" } }}
						>
							<MenuIcon />
						</IconButton>
					</Box>
				</Toolbar>
			</AppBar>

			<FullScreenDialog onClose={handleSettingsClose} open={isSettingsOpen} />
		</>
	);
};

export default Header;
