import { ChatToggleButton } from "../../../../ux/chatStyles";
import checkIcon from "../../../../assets/check.svg";

interface ToggleButtonProps {
	selected: boolean;
	onClick: () => void;
	children: React.ReactNode;
}

export const ToggleButton: React.FC<ToggleButtonProps> = ({
	selected,
	onClick,
	children,
}) => {
	const style = selected ? { borderColor: "#DCF032", color: "#DCF032" } : {};
	return (
		<ChatToggleButton style={style} onClick={onClick} variant="outlined">
			{selected && (
				<img src={checkIcon} alt="Selected" style={{ marginRight: "8px" }} />
			)}
			{children}
		</ChatToggleButton>
	);
};
