import { Dispatch } from "react";
import { UserService } from "../services/userService";
import { User } from "../models/user";
import { ActionTypes } from "./common";
import {
	createPayloadAction,
	PayloadAction,
	ActionMethod,
} from "./actionCreators";
import config from "../config";
import { OnboardingData } from "../models/onboarding";
import {
	AdCampaign,
	Blog,
	Deck,
	Document,
	GeneratedImage,
	ImagePrompt,
	Message,
	Newsletter,
	SocialContent,
	Survey,
	WebsiteCopy,
	StripeSession,
} from "../services/restService";

const userService = new UserService(config.api.baseUrl, "/user");

export interface UserActions {
	signUp(user: User): Promise<User>;
	completeSignUp(user: User): Promise<User>;
	getMe(): Promise<User>;
	saveOnboardingData(onboardingData: OnboardingData): Promise<User>;
	getOnboardingData(): Promise<OnboardingData>;
	// chat
	chatMessage(message: Message): Promise<{
		message: string;
		threadId?: string;
		imageFileId?: string;
	}>;
	chatMessageStream(
		message: Message,
		onMessage: (chunk: string) => void, // Function to handle each chunk
		onComplete: () => void, // Function when streaming is complete
		onError: (error: any) => void // Function to handle any error
	): Promise<void>;
	uploadFile(file: File): Promise<{
		fileId: string;
	}>;
	createSurvey(survey: Survey): Promise<{
		message: string;
		threadId?: string;
		imageFileId?: string;
	}>;
	createSurveyStream(
		survey: Survey,
		onMessage: (chunk: string) => void,
		onComplete: () => void,
		onError: (error: any) => void
	): Promise<void>;
	writeBlogPost(blog: Blog): Promise<{
		message: string;
		threadId?: string;
		imageFileId?: string;
	}>;
	writeBlogPostStream(
		blog: Blog,
		onMessage: (chunk: string) => void,
		onComplete: () => void,
		onError: (error: any) => void
	): Promise<void>;
	writeNewsletter(newsletter: Newsletter): Promise<{
		message: string;
		threadId?: string;
		imageFileId?: string;
	}>;
	writeNewsletterStream(
		newsletter: Newsletter,
		onMessage: (chunk: string) => void,
		onComplete: () => void,
		onError: (error: any) => void
	): Promise<void>;
	createAdCampaign(adCampaign: AdCampaign): Promise<{
		message: string;
		threadId?: string;
		imageFileId?: string;
	}>;
	createAdCampaignStream(
		adCampaign: AdCampaign,
		onMessage: (chunk: string) => void,
		onComplete: () => void,
		onError: (error: any) => void
	): Promise<void>;
	createDocument(document: Document): Promise<{
		message: string;
		threadId?: string;
		imageFileId?: string;
	}>;
	createDocumentStream(
		document: Document,
		onMessage: (chunk: string) => void,
		onComplete: () => void,
		onError: (error: any) => void
	): Promise<void>;
	createSlideDeck(deck: Deck): Promise<{
		message: string;
		threadId?: string;
		imageFileId?: string;
	}>;
	createSlideDeckStream(
		deck: Deck,
		onMessage: (chunk: string) => void,
		onComplete: () => void,
		onError: (error: any) => void
	): Promise<void>;
	createSocialContent(content: SocialContent): Promise<{
		message: string;
		threadId?: string;
		imageFileId?: string;
	}>;
	createSocialContentStream(
		content: SocialContent,
		onMessage: (chunk: string) => void,
		onComplete: () => void,
		onError: (error: any) => void
	): Promise<void>;
	writeWebsiteCopy(content: WebsiteCopy): Promise<{
		message: string;
		threadId?: string;
		imageFileId?: string;
	}>;
	writeWebsiteCopyStream(
		content: WebsiteCopy,
		onMessage: (chunk: string) => void,
		onComplete: () => void,
		onError: (error: any) => void
	): Promise<void>;
	generateImageBasedOnThread(
		threadId: string,
		assistantType: string
	): Promise<GeneratedImage>;
	generateImageBasedOnPrompt(prompt: ImagePrompt): Promise<GeneratedImage>;

	// stripe
	createSubscriptionCheckoutSession(plan: string): Promise<StripeSession>;
}

// sign up
export const signUp =
	(user: User): ActionMethod<User> =>
	async (dispatch: Dispatch<CompleteSignUpAction>) => {
		const newUser = await userService.signUp(user);
		dispatch(completeSignUpAction(newUser));
		return newUser;
	};

export const completeSignUp =
	(user: User): ActionMethod<User> =>
	async (dispatch: Dispatch<CompleteSignUpAction>) => {
		const newUser = await userService.completeSignUp(user); // Assuming completeSignUp method exists
		dispatch(completeSignUpAction(newUser));
		return newUser;
	};

// get my user info
export const getMe =
	(): ActionMethod<User> => async (dispatch: Dispatch<GetMeAction>) => {
		const user = await userService.getMe();
		dispatch(getMeAction(user));
		return user;
	};

export const saveOnboardingData =
	(onboardingData: OnboardingData): ActionMethod<User> =>
	async (dispatch: Dispatch<SaveOnboardingDataAction>) => {
		const updatedUser = await userService.saveOnboardingData(onboardingData);
		dispatch(saveOnboardingDataAction(updatedUser));
		return updatedUser;
	};

export const getOnboardingData =
	(): ActionMethod<OnboardingData> =>
	async (dispatch: Dispatch<GetOnboardingDataAction>) => {
		const onboardingData = await userService.getOnboardingData();
		dispatch(getOnboardingDataAction(onboardingData));
		return onboardingData;
	};

export const chatMessage =
	(
		message: Message
	): ActionMethod<{
		message: string;
		threadId?: string;
		imageFileId?: string;
	}> =>
	async (
		dispatch: Dispatch<
			PayloadAction<
				string,
				{ message: string; threadId?: string; imageFileId?: string }
			>
		>
	) => {
		const response = await userService.chatMessage(message);
		dispatch({
			type: ActionTypes.CHAT_MESSAGE,
			payload: response,
		});
		return response;
	};

export const chatMessageStream =
	(
		message: Message,
		onMessage: (chunk: string) => void,
		onComplete: () => void,
		onError: (error: any) => void
	): ActionMethod<void> =>
	async (dispatch: Dispatch<any>) => {
		try {
			// Use the chatMessageStream method from the service
			await userService.chatMessageStream(
				message,
				onMessage,
				onComplete,
				onError
			);
		} catch (error) {
			console.error("Error in chatMessageStream action:", error);
			onError(error); // Invoke the onError callback to handle the error
		}
	};

export const uploadFile =
	(file: File): ActionMethod<string> =>
	async (dispatch: Dispatch<PayloadAction<string, string>>) => {
		const fileId = await userService.uploadFile(file);
		dispatch({
			type: ActionTypes.UPLOAD_FILE,
			payload: fileId,
		});
		return fileId;
	};

// create survey
export const createSurvey =
	(
		survey: Survey
	): ActionMethod<{
		message: string;
		threadId?: string;
		imageFileId?: string;
	}> =>
	async (
		dispatch: Dispatch<
			PayloadAction<
				string,
				{ message: string; threadId?: string; imageFileId?: string }
			>
		>
	) => {
		const response = await userService.createSurvey(survey);
		dispatch({
			type: ActionTypes.CREATE_SURVEY,
			payload: response,
		});
		return response;
	};

export const createSurveyStream =
	(
		survey: Survey,
		onMessage: (chunk: string) => void,
		onComplete: () => void,
		onError: (error: any) => void
	): ActionMethod<void> =>
	async (dispatch: Dispatch<any>) => {
		try {
			// Use the createSurveyStream method from the service
			await userService.createSurveyStream(
				survey,
				onMessage,
				onComplete,
				onError
			);
		} catch (error) {
			console.error("Error in createSurveyStream action:", error);
			onError(error); // Invoke the onError callback to handle the error
		}
	};

// write blog post
export const writeBlogPost =
	(
		blog: Blog
	): ActionMethod<{
		message: string;
		threadId?: string;
		imageFileId?: string;
	}> =>
	async (
		dispatch: Dispatch<
			PayloadAction<
				string,
				{ message: string; threadId?: string; imageFileId?: string }
			>
		>
	) => {
		const response = await userService.writeBlogPost(blog);
		dispatch({
			type: ActionTypes.WRITE_BLOG_POST,
			payload: response,
		});
		return response;
	};

export const writeBlogPostStream =
	(
		blog: Blog,
		onMessage: (chunk: string) => void,
		onComplete: () => void,
		onError: (error: any) => void
	): ActionMethod<void> =>
	async (dispatch: Dispatch<any>) => {
		try {
			// Use the writeBlogPostStream method from the service
			await userService.writeBlogPostStream(
				blog,
				onMessage,
				onComplete,
				onError
			);
		} catch (error) {
			console.error("Error in writeBlogPostStream action:", error);
			onError(error); // Invoke the onError callback to handle the error
		}
	};

// write newsletter
export const writeNewsletter =
	(
		newsletter: Newsletter
	): ActionMethod<{
		message: string;
		threadId?: string;
		imageFileId?: string;
	}> =>
	async (
		dispatch: Dispatch<
			PayloadAction<
				string,
				{ message: string; threadId?: string; imageFileId?: string }
			>
		>
	) => {
		const response = await userService.writeNewsletter(newsletter);
		dispatch({
			type: ActionTypes.WRITE_BLOG_POST,
			payload: response,
		});
		return response;
	};

export const writeNewsletterStream =
	(
		newsletter: Newsletter,
		onMessage: (chunk: string) => void,
		onComplete: () => void,
		onError: (error: any) => void
	): ActionMethod<void> =>
	async (dispatch: Dispatch<any>) => {
		try {
			// Use the writeNewsletterStream method from the service
			await userService.writeNewsletterStream(
				newsletter,
				onMessage,
				onComplete,
				onError
			);
		} catch (error) {
			console.error("Error in writeNewsletterStream action:", error);
			onError(error); // Invoke the onError callback to handle the error
		}
	};

// create ad campaign
export const createAdCampaign =
	(
		adCampaign: AdCampaign
	): ActionMethod<{
		message: string;
		threadId?: string;
		imageFileId?: string;
	}> =>
	async (
		dispatch: Dispatch<
			PayloadAction<
				string,
				{ message: string; threadId?: string; imageFileId?: string }
			>
		>
	) => {
		const response = await userService.createAdCampaign(adCampaign);
		dispatch({
			type: ActionTypes.WRITE_BLOG_POST,
			payload: response,
		});
		return response;
	};

export const createAdCampaignStream =
	(
		adCampaign: AdCampaign,
		onMessage: (chunk: string) => void,
		onComplete: () => void,
		onError: (error: any) => void
	): ActionMethod<void> =>
	async (dispatch: Dispatch<any>) => {
		try {
			// Use the createAdCampaignStream method from the service
			await userService.createAdCampaignStream(
				adCampaign,
				onMessage,
				onComplete,
				onError
			);
		} catch (error) {
			console.error("Error in createAdCampaignStream action:", error);
			onError(error); // Invoke the onError callback to handle the error
		}
	};

// createDocument
export const createDocument =
	(
		document: Document
	): ActionMethod<{
		message: string;
		threadId?: string;
		imageFileId?: string;
	}> =>
	async (
		dispatch: Dispatch<
			PayloadAction<
				string,
				{ message: string; threadId?: string; imageFileId?: string }
			>
		>
	) => {
		const response = await userService.createDocument(document);
		dispatch({
			type: ActionTypes.WRITE_BLOG_POST,
			payload: response,
		});
		return response;
	};

export const createDocumentStream =
	(
		document: Document,
		onMessage: (chunk: string) => void,
		onComplete: () => void,
		onError: (error: any) => void
	): ActionMethod<void> =>
	async (dispatch: Dispatch<any>) => {
		try {
			// Use the createDocumentStream method from the service
			await userService.createDocumentStream(
				document,
				onMessage,
				onComplete,
				onError
			);
		} catch (error) {
			console.error("Error in createDocumentStream action:", error);
			onError(error); // Invoke the onError callback to handle the error
		}
	};

// create slide deck
export const createSlideDeck =
	(
		deck: Deck
	): ActionMethod<{
		message: string;
		threadId?: string;
		imageFileId?: string;
	}> =>
	async (
		dispatch: Dispatch<
			PayloadAction<
				string,
				{ message: string; threadId?: string; imageFileId?: string }
			>
		>
	) => {
		const response = await userService.createSlideDeck(deck);
		dispatch({
			type: ActionTypes.WRITE_BLOG_POST,
			payload: response,
		});
		return response;
	};

export const createSlideDeckStream =
	(
		deck: Deck,
		onMessage: (chunk: string) => void,
		onComplete: () => void,
		onError: (error: any) => void
	): ActionMethod<void> =>
	async (dispatch: Dispatch<any>) => {
		try {
			// Use the createSlideDeckStream method from the service
			await userService.createSlideDeckStream(
				deck,
				onMessage,
				onComplete,
				onError
			);
		} catch (error) {
			console.error("Error in createSlideDeckStream action:", error);
			onError(error); // Invoke the onError callback to handle the error
		}
	};

// create social content
export const createSocialContent =
	(
		content: SocialContent
	): ActionMethod<{
		message: string;
		threadId?: string;
		imageFileId?: string;
	}> =>
	async (
		dispatch: Dispatch<
			PayloadAction<
				string,
				{ message: string; threadId?: string; imageFileId?: string }
			>
		>
	) => {
		const response = await userService.createSocialContent(content);
		dispatch({
			type: ActionTypes.WRITE_BLOG_POST,
			payload: response,
		});
		return response;
	};

export const createSocialContentStream =
	(
		content: SocialContent,
		onMessage: (chunk: string) => void,
		onComplete: () => void,
		onError: (error: any) => void
	): ActionMethod<void> =>
	async (dispatch: Dispatch<any>) => {
		try {
			// Use the createSocialContentStream method from the service
			await userService.createSocialContentStream(
				content,
				onMessage,
				onComplete,
				onError
			);
		} catch (error) {
			console.error("Error in createSocialContentStream action:", error);
			onError(error); // Invoke the onError callback to handle the error
		}
	};

// write website copy
export const writeWebsiteCopy =
	(
		content: WebsiteCopy
	): ActionMethod<{
		message: string;
		threadId?: string;
		imageFileId?: string;
	}> =>
	async (
		dispatch: Dispatch<
			PayloadAction<
				string,
				{ message: string; threadId?: string; imageFileId?: string }
			>
		>
	) => {
		const response = await userService.writeWebsiteCopy(content);
		dispatch({
			type: ActionTypes.WRITE_BLOG_POST,
			payload: response,
		});
		return response;
	};

export const writeWebsiteCopyStream =
	(
		content: WebsiteCopy,
		onMessage: (chunk: string) => void,
		onComplete: () => void,
		onError: (error: any) => void
	): ActionMethod<void> =>
	async (dispatch: Dispatch<any>) => {
		try {
			// Use the writeWebsiteCopyStream method from the service
			await userService.writeWebsiteCopyStream(
				content,
				onMessage,
				onComplete,
				onError
			);
		} catch (error) {
			console.error("Error in writeWebsiteCopyStream action:", error);
			onError(error); // Invoke the onError callback to handle the error
		}
	};

export const generateImageBasedOnThread =
	(threadId: string, assistantType: string): ActionMethod<GeneratedImage> =>
	async (dispatch: Dispatch<PayloadAction<string, GeneratedImage>>) => {
		const image = await userService.generateImageBasedOnThread(
			threadId,
			assistantType
		);
		dispatch({
			type: ActionTypes.GENERATE_IMAGE,
			payload: image,
		});
		return image;
	};

export const generateImageBasedOnPrompt =
	(prompt: ImagePrompt): ActionMethod<GeneratedImage> =>
	async (dispatch: Dispatch<PayloadAction<string, GeneratedImage>>) => {
		const image = await userService.generateImageBasedOnPrompt(prompt);
		dispatch({
			type: ActionTypes.GENERATE_IMAGE,
			payload: image,
		});
		return image;
	};

// stripe
// createSubscriptionCheckoutSession - sends a plan (string) to the server and returns a session
export const createSubscriptionCheckoutSession =
	(plan: string): ActionMethod<StripeSession> =>
	async (dispatch: Dispatch<PayloadAction<string, StripeSession>>) => {
		const session = await userService.createSubscriptionCheckoutSession(plan);
		dispatch({
			type: ActionTypes.CREATE_SUBSCRIPTION_CHECKOUT_SESSION,
			payload: session,
		});
		return session;
	};

export interface SignUpAction extends PayloadAction<string, User> {
	type: ActionTypes.SIGN_UP;
}
export interface CompleteSignUpAction extends PayloadAction<string, User> {
	type: ActionTypes.COMPLETE_SIGN_UP;
}

export interface GetMeAction extends PayloadAction<string, User> {
	type: ActionTypes.GET_ME;
}

export interface SaveOnboardingDataAction extends PayloadAction<string, User> {
	type: ActionTypes.SAVE_ONBOARDING_DATA;
}

export interface GetOnboardingDataAction
	extends PayloadAction<string, OnboardingData> {
	type: ActionTypes.GET_ONBOARDING_DATA;
}

export interface ChatMessageAction extends PayloadAction<string, string> {
	type: ActionTypes.CHAT_MESSAGE;
}

export interface ChatMessageStreamAction extends PayloadAction<string, string> {
	type: ActionTypes.CHAT_MESSAGE_STREAM;
}

export interface UploadFileAction extends PayloadAction<string, string> {
	type: ActionTypes.UPLOAD_FILE;
}

export interface CreateSurveyAction extends PayloadAction<string, string> {
	type: ActionTypes.CREATE_SURVEY;
}

export interface CreateSurveyStreamAction
	extends PayloadAction<string, string> {
	type: ActionTypes.CREATE_SURVEY_STREAM;
}

export interface WriteBlogPostAction extends PayloadAction<string, string> {
	type: ActionTypes.WRITE_BLOG_POST;
}

export interface WriteBlogPostStreamAction
	extends PayloadAction<string, string> {
	type: ActionTypes.WRITE_BLOG_POST_STREAM;
}

export interface WriteNewsletterAction extends PayloadAction<string, string> {
	type: ActionTypes.WRITE_NEWSLETTER;
}

export interface writeNewsletterStreamAction
	extends PayloadAction<string, string> {
	type: ActionTypes.WRITE_NEWSLETTER_STREAM;
}

export interface CreateAdCampaignAction extends PayloadAction<string, string> {
	type: ActionTypes.CREATE_AD_CAMPAIGN;
}

export interface CreateAdCampaignStreamAction
	extends PayloadAction<string, string> {
	type: ActionTypes.CREATE_AD_CAMPAIGN_STREAM;
}

export interface CreateDocumentAction extends PayloadAction<string, string> {
	type: ActionTypes.CREATE_DOCUMENT;
}

export interface CreateDocumentStreamAction
	extends PayloadAction<string, string> {
	type: ActionTypes.CREATE_DOCUMENT_STREAM;
}

export interface CreateSlideDeckAction extends PayloadAction<string, string> {
	type: ActionTypes.CREATE_SLIDE_DECK;
}

export interface CreateSlideDeckStreamAction
	extends PayloadAction<string, string> {
	type: ActionTypes.CREATE_SLIDE_DECK_STREAM;
}

export interface CreateSocialContentAction
	extends PayloadAction<string, string> {
	type: ActionTypes.CREATE_SOCIAL_CONTENT;
}

export interface CreateSocialContentStreamAction
	extends PayloadAction<string, string> {
	type: ActionTypes.CREATE_SOCIAL_CONTENT_STREAM;
}

export interface WriteWebsiteCopyAction extends PayloadAction<string, string> {
	type: ActionTypes.WRITE_WEBSITE_COPY;
}

export interface WriteWebsiteCopyStreamAction
	extends PayloadAction<string, string> {
	type: ActionTypes.WRITE_WEBSITE_COPY_STREAM;
}

export interface GenerateImageAction
	extends PayloadAction<string, GeneratedImage> {
	type: ActionTypes.GENERATE_IMAGE;
}

export interface GenerateImageFromPromptAction
	extends PayloadAction<string, GeneratedImage> {
	type: ActionTypes.GENERATE_IMAGE_FROM_PROMPT;
}

export interface CreateSubscriptionCheckoutSessionAction
	extends PayloadAction<string, StripeSession> {
	type: ActionTypes.CREATE_SUBSCRIPTION_CHECKOUT_SESSION;
}

export const signUpAction = createPayloadAction<SignUpAction>(
	ActionTypes.SIGN_UP
);

export const completeSignUpAction = createPayloadAction<CompleteSignUpAction>(
	ActionTypes.COMPLETE_SIGN_UP
);

export const getMeAction = createPayloadAction<GetMeAction>(ActionTypes.GET_ME);

export const saveOnboardingDataAction =
	createPayloadAction<SaveOnboardingDataAction>(
		ActionTypes.SAVE_ONBOARDING_DATA
	);

export const getOnboardingDataAction =
	createPayloadAction<GetOnboardingDataAction>(ActionTypes.GET_ONBOARDING_DATA);

export const chatMessageAction = createPayloadAction<ChatMessageAction>(
	ActionTypes.CHAT_MESSAGE
);

export const chatMessageStreamAction =
	createPayloadAction<ChatMessageStreamAction>(ActionTypes.CHAT_MESSAGE_STREAM);

export const uploadFileAction = createPayloadAction<UploadFileAction>(
	ActionTypes.UPLOAD_FILE
);

export const createSurveyAction = createPayloadAction<CreateSurveyAction>(
	ActionTypes.CREATE_SURVEY
);

export const createSurveyStreamAction =
	createPayloadAction<CreateSurveyStreamAction>(
		ActionTypes.CREATE_SURVEY_STREAM
	);

export const writeBlogPostAction = createPayloadAction<WriteBlogPostAction>(
	ActionTypes.WRITE_BLOG_POST
);

export const writeBlogPostStreamAction =
	createPayloadAction<WriteBlogPostStreamAction>(
		ActionTypes.WRITE_BLOG_POST_STREAM
	);

export const writeNewsletterAction = createPayloadAction<WriteNewsletterAction>(
	ActionTypes.WRITE_NEWSLETTER
);

export const writeNewsletterStreamAction =
	createPayloadAction<writeNewsletterStreamAction>(
		ActionTypes.WRITE_NEWSLETTER_STREAM
	);

export const createAdCampaignAction =
	createPayloadAction<CreateAdCampaignAction>(ActionTypes.CREATE_AD_CAMPAIGN);

export const createAdCampaignStreamAction =
	createPayloadAction<CreateAdCampaignStreamAction>(
		ActionTypes.CREATE_AD_CAMPAIGN_STREAM
	);
export const createDocumentAction = createPayloadAction<CreateDocumentAction>(
	ActionTypes.CREATE_DOCUMENT
);

export const createDocumentStreamAction =
	createPayloadAction<CreateDocumentStreamAction>(
		ActionTypes.CREATE_DOCUMENT_STREAM
	);

export const createSlideDeckAction = createPayloadAction<CreateSlideDeckAction>(
	ActionTypes.CREATE_SLIDE_DECK
);

export const createSlideDeckStreamAction =
	createPayloadAction<CreateSlideDeckStreamAction>(
		ActionTypes.CREATE_SLIDE_DECK_STREAM
	);

export const createSocialContentAction =
	createPayloadAction<CreateSocialContentAction>(
		ActionTypes.CREATE_SOCIAL_CONTENT
	);

export const createSocialContentStreamAction =
	createPayloadAction<CreateSocialContentStreamAction>(
		ActionTypes.CREATE_SOCIAL_CONTENT_STREAM
	);

export const writeWebsiteCopyAction =
	createPayloadAction<WriteWebsiteCopyAction>(ActionTypes.WRITE_WEBSITE_COPY);

export const writeWebsiteCopyStreamAction =
	createPayloadAction<WriteWebsiteCopyStreamAction>(
		ActionTypes.WRITE_WEBSITE_COPY_STREAM
	);
export const generateImageAction = createPayloadAction<GenerateImageAction>(
	ActionTypes.GENERATE_IMAGE
);

export const generateImageFromPromptAction =
	createPayloadAction<GenerateImageFromPromptAction>(
		ActionTypes.GENERATE_IMAGE_FROM_PROMPT
	);

export const createSubscriptionCheckoutSessionAction =
	createPayloadAction<CreateSubscriptionCheckoutSessionAction>(
		ActionTypes.CREATE_SUBSCRIPTION_CHECKOUT_SESSION
	);
