import React, { useState, useEffect, useContext, useMemo } from "react";
import {
	Box,
	Typography,
	Paper,
	Card,
	CardContent,
	Button,
} from "@mui/material";
import * as adminActions from "../../actions/adminActions";
import { AppContext } from "../../models/applicationState";
import { TodoContext } from "../todoContext";
import { bindActionCreators } from "../../actions/actionCreators";
import MarkdownViewer from "../../utils/MarkdownRenderer";

// Define the chatMessage interface based on the provided structure
export interface ChatMessage {
	id: string;
	object: string;
	created_at: number;
	thread_id: string;
	role: string;
	content: [
		{
			type: string;
			text: {
				value: string;
				annotations: string[];
			};
		}
	];
	file_ids: string[];
	assistant_id: string | null;
	run_id: string | null;
	metadata: object;
}

interface ChatHistoryProps {
	threadId: string;
	liked: boolean | null;
	tokenUsage: number;
	createdDate: Date;
	assistantType: string;
	generatedImages: string[] | undefined;
	onClose: () => void;
}

const ChatHistory: React.FC<ChatHistoryProps> = ({
	threadId,
	liked,
	tokenUsage,
	createdDate,
	assistantType,
	generatedImages,
	onClose,
}) => {
	const [chatHistory, setChatHistory] = useState<ChatMessage[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	const appContext = useContext<AppContext>(TodoContext);
	const actions = useMemo(
		() => ({
			admin: bindActionCreators(
				adminActions,
				appContext.dispatch
			) as unknown as adminActions.AdminActions,
		}),
		[appContext.dispatch]
	);

	useEffect(() => {
		const fetchChatHistory = async () => {
			setIsLoading(true);
			try {
				const response = await actions.admin.listMessages(threadId);
				// reverse the chat history so the latest messages are at the bottom
				setChatHistory(response.reverse());
			} catch (error) {
				console.error("There was an error fetching the chat history:", error);
				setChatHistory([]);
			} finally {
				setIsLoading(false);
			}
		};

		fetchChatHistory();
	}, [threadId]);

	return (
		<Box sx={{ width: "100%" }}>
			<Button onClick={onClose} style={{ float: "right" }}>
				Close
			</Button>
			<Card sx={{ margin: "16px 0" }}>
				<CardContent>
					<Typography variant="h6">Chat Details</Typography>
					<Typography variant="body2">
						Liked: {liked === null ? "N/A" : liked ? "Yes" : "No"}
					</Typography>
					<Typography variant="body2">Token Usage: {tokenUsage}</Typography>
					<Typography variant="body2">
						Created Date: {createdDate.toLocaleString()}
					</Typography>
					<Typography variant="body2">
						Assistant Type: {assistantType}
					</Typography>
				</CardContent>
			</Card>
			{isLoading ? (
				<Typography>Loading chat history...</Typography>
			) : (
				chatHistory.map((message) => (
					<Paper
						key={message.id}
						elevation={1}
						sx={{
							padding: "16px",
							margin: "8px 0",
							whiteSpace: "pre-wrap",
							overflowWrap: "break-word",
						}}
					>
						<Typography variant="subtitle2" color="textSecondary">
							{message.role.charAt(0).toUpperCase() + message.role.slice(1)}
						</Typography>
						{message.content.map((content, index) => (
							<MarkdownViewer key={index} markdownText={content.text.value} />
						))}
					</Paper>
				))
			)}
			{generatedImages?.map(
				(
					image,
					index // Add this block
				) => (
					<img
						key={index}
						src={`data:image/png;base64,${image}`}
						alt="Generated"
					/>
				)
			)}
		</Box>
	);
};

export default ChatHistory;
