import React, { useState, useEffect, useContext, useMemo } from "react";
import {
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TextField,
	Button,
	TablePagination,
	Container,
	Checkbox,
	Snackbar,
	Alert,
} from "@mui/material";
import { bindActionCreators } from "../../actions/actionCreators";
import { AppContext } from "../../models/applicationState";
import { TodoContext } from "../todoContext";
import * as adminActions from "../../actions/adminActions";
import { StyledTableCell, StyledTableRow } from "../../ux/adminDashboardStyles";

interface User {
	uid: string;
	provider: string;
	name: string;
	email: string;
	phone?: string;
	companyName?: string;
	roleOnTeam?: string;
	teamSize?: string;
	isProfileComplete?: boolean;
	createdDate?: Date;
	updatedDate?: Date;
	isOnWaitingList?: boolean;
	isSubscribed?: boolean;
	role?: string;
}

const WaitingListUserList = () => {
	const [users, setUsers] = useState<User[]>([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarType, setSnackbarType] = useState<"success" | "error">(
		"success"
	);
	const [snackbarMessage, setSnackbarMessage] = useState("");

	const appContext = useContext<AppContext>(TodoContext);
	const actions = useMemo(
		() => ({
			admin: bindActionCreators(
				adminActions,
				appContext.dispatch
			) as unknown as adminActions.AdminActions,
		}),
		[appContext.dispatch]
	);

	const fetchWaitingListUsers = async () => {
		const response =
			(await actions.admin.getWaitingList()) as unknown as User[];
		setUsers(response);
	};

	useEffect(() => {
		fetchWaitingListUsers();
	}, []);

	// Client-side filtering and pagination logic
	const filteredUsers = users.filter(
		(user) =>
			user?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
			user?.email.toLowerCase().includes(searchTerm.toLowerCase())
	);
	const paginatedUsers = filteredUsers.slice(
		page * rowsPerPage,
		(page + 1) * rowsPerPage
	);

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);
		setPage(0);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleSelectUser = (uid: string) => {
		const selectedIndex = selectedUsers.indexOf(uid);
		let newSelectedUsers: string[] = [];

		if (selectedIndex === -1) {
			newSelectedUsers = newSelectedUsers.concat(selectedUsers, uid);
		} else if (selectedIndex === 0) {
			newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(1));
		} else if (selectedIndex === selectedUsers.length - 1) {
			newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelectedUsers = newSelectedUsers.concat(
				selectedUsers.slice(0, selectedIndex),
				selectedUsers.slice(selectedIndex + 1)
			);
		}

		setSelectedUsers(newSelectedUsers);
	};

	const handleApproveSelectedUsers = async () => {
		try {
			// Call the API to approve the selected users
			await actions.admin.approveUsersFromWaitingList(selectedUsers);
			// Then, refetch the waiting list
			fetchWaitingListUsers();
			setSelectedUsers([]);
			// Set snackbar to success
			setSnackbarType("success");
			setSnackbarMessage("Users approved successfully");
			setSnackbarOpen(true);
		} catch (error) {
			// Set snackbar to error
			setSnackbarType("error");
			setSnackbarMessage("Error approving users");
			setSnackbarOpen(true);
		}
	};

	const handleCloseSnackbar = (
		event?: React.SyntheticEvent,
		reason?: string
	) => {
		if (reason === "clickaway") {
			return;
		}
		setSnackbarOpen(false);
	};

	return (
		<Container>
			{/* align TextField and Button */}
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<TextField
					label="Search Waiting List Users"
					value={searchTerm}
					onChange={handleSearchChange}
					style={{ margin: "20px" }}
				/>
				<Button
					variant="contained"
					color="primary"
					onClick={handleApproveSelectedUsers}
					disabled={selectedUsers.length === 0}
					// put on the right side of the screen
					style={{ float: "right" }}
				>
					Approve Selected Users
				</Button>
			</div>
			<TableContainer>
				<Table>
					<TableHead>
						<StyledTableRow>
							<StyledTableCell padding="checkbox">
								{/*Checkbox to select/deselect all */}
								<Checkbox
									checked={selectedUsers.length === paginatedUsers.length}
									onChange={() => {
										if (selectedUsers.length === paginatedUsers.length) {
											setSelectedUsers([]);
										} else {
											setSelectedUsers(paginatedUsers.map((user) => user.uid));
										}
									}}
									sx={{ color: "white" }}
								/>
							</StyledTableCell>
							<StyledTableCell>Name</StyledTableCell>
							<StyledTableCell>Email</StyledTableCell>
							<StyledTableCell>Phone</StyledTableCell>
							<StyledTableCell>Company Name</StyledTableCell>
							<StyledTableCell>Role on Team</StyledTableCell>
							<StyledTableCell>User Role</StyledTableCell>
						</StyledTableRow>
					</TableHead>
					<TableBody>
						{paginatedUsers.map((user) => (
							<StyledTableRow key={user.uid}>
								<StyledTableCell padding="checkbox">
									<Checkbox
										checked={selectedUsers.indexOf(user.uid) !== -1}
										onChange={() => handleSelectUser(user.uid)}
									/>
								</StyledTableCell>
								<StyledTableCell>{user.name}</StyledTableCell>
								<StyledTableCell>{user.email}</StyledTableCell>
								<StyledTableCell>{user.phone}</StyledTableCell>
								<StyledTableCell>{user.companyName}</StyledTableCell>
								<StyledTableCell>{user.roleOnTeam}</StyledTableCell>
								<StyledTableCell>{user.role}</StyledTableCell>
							</StyledTableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				component="div"
				count={filteredUsers.length}
				page={page}
				onPageChange={handleChangePage}
				rowsPerPage={rowsPerPage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
			<Snackbar open={snackbarOpen} autoHideDuration={6000}>
				<Alert
					onClose={handleCloseSnackbar}
					severity={snackbarType}
					sx={{ width: "100%" }}
				>
					{snackbarMessage}
				</Alert>
			</Snackbar>
		</Container>
	);
};

export default WaitingListUserList;
