import React, { createContext, useContext, useEffect, useState } from "react";
import {
	onAuthStateChanged,
	signOut,
	User,
	sendEmailVerification,
} from "firebase/auth";
import { auth } from "../firebase/firebase-config";
import { UserContext } from "./UserDataContext";

interface AuthContextType {
	user: User | null;
	loading: boolean;
	logout: (callback: () => void) => Promise<void>;
	sendVerificationEmail: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType>({
	user: null,
	loading: true,
	logout: async (callback: () => void) => {
		console.error("No user provider");
		callback();
	},
	sendVerificationEmail: async () => {
		console.error("No user provider");
	},
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC = ({ children }) => {
	const [user, setUser] = useState<User | null>(null);
	const [loading, setLoading] = useState(true);
	const { loadUserData } = useContext(UserContext);

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, (newUser) => {
			console.log("User state changed");
			setUser(newUser);
			if (newUser) {
				loadUserData();
				console.log("User is logged in");
			}
			setLoading(false);
		});

		return unsubscribe;
	}, []);

	const logout = async (callback: () => void) => {
		try {
			await signOut(auth);
			setUser(null);
			callback();
		} catch (error) {
			console.error("Error logging out", error);
		}
	};

	const sendVerificationEmail = async () => {
		if (user && !user.emailVerified) {
			try {
				await sendEmailVerification(user);
				console.log("Verification email sent");
			} catch (error) {
				console.error("Error sending verification email", error);
			}
		}
	};

	return (
		<AuthContext.Provider
			value={{ user, loading, logout, sendVerificationEmail }}
		>
			{children}
		</AuthContext.Provider>
	);
};
