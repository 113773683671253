import { Box, Button, MobileStepper, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { theme } from "./theme";

export const BoxStyled = styled(Box)({
	height: "100vh",
	width: "100%",
	background: "#131318",
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center",

	// Responsive styling for desktop
	// "@media (min-width: 600px)": {
	// 	marginTop: "20%",
	// },
});

export const PaperStyled = styled(Paper)({
	height: "100vh",
	background: "#000",
	padding: "2rem 2rem",
	justifyContent: "center",
	alignItems: "center",
	textAlign: "center",
	display: "flex",
	flexDirection: "column",
	gap: "1rem",
	textTransform: "none",

	// all text color is white
	color: "#fff",

	// Responsive styling for desktop
	"@media (min-width: 600px)": {
		width: "30rem",
		padding: "2rem 1rem",
	},
});

export const StartButtonStyled = styled(Button)({
	textTransform: "none",
	borderRadius: "1rem",
	background: "#DCF032",
	fontWeight: "600",
	padding: "0.875rem 1.375rem",
	// text color is theme secondary text color
	color: theme.palette.secondary.contrastText,

	// hover color
	"&:hover": {
		background: "#DCF032",
	},
});

export const UploadButtonStyled = styled(StartButtonStyled)({
	// outlined
	background: "none",
	border: "1px solid var(--Cockpit-AI-sys-dark-outline, #918F9A)",
	color: "#DCF032",
	width: "18rem",
	alignItems: "center",
	justifyContent: "center",
	// hover color
	"&:hover": {
		background: "#DCF032",
		color: "#000",
	},
});

export const CustomStepper = styled(MobileStepper)(({ theme }) => ({
	flexGrow: 1,
	position: "fixed",
	bottom: 0,
	left: 0,
	right: 0,
	backgroundColor: "transparent",
	"& .MuiMobileStepper-dots": {
		margin: "0 auto",
		justifyContent: "center",
		position: "relative",
	},
	"& .MuiMobileStepper-dot": {
		width: 8,
		height: 8,
		margin: "0 4px",
		backgroundColor: "#E4E1E9",
		transition: theme.transitions.create("transform", {
			duration: theme.transitions.duration.shortest,
		}),
	},
	"& .MuiMobileStepper-dotActive": {
		// Transform the active dot into a horizontal line
		width: 20, // Full width of the stepper
		// height: 2,
		backgroundColor: "#DCF032", // Active line color
		borderRadius: 50, // Remove border radius to make it a straight line
		margin: 0,
		"&::before, &::after": {
			// Remove pseudo-elements if they were previously defined
			display: "none",
		},
	},
}));
