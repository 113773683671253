import React, { useContext, useMemo, useState } from "react";
import {
	Grid,
	Card,
	CardContent,
	Typography,
	CardActions,
	Button,
	Box,
	useTheme,
	useMediaQuery,
} from "@mui/material";
import { bindActionCreators } from "../../actions/actionCreators";
import { TodoContext } from "../todoContext";
import * as userActions from "../../actions/userActions";
import { useNavigate } from "react-router-dom";
import CheckWhiteIcon from "../../assets/checkWhite.svg";
import logo from "../../assets/Vector.svg";

// no credit card required feature
const NoCreditCardRequired = () => {
	return (
		<Box
			sx={{
				my: 1,
				backgroundColor: "#202029",
				borderRadius: "0.5rem",
				padding: "0.5rem 0.5rem",
				width: "100%",
			}}
		>
			<Typography
				variant="body1"
				sx={{
					display: "flex",
					alignItems: "center",
					gap: "1rem",
					color: "primary.main",
				}}
			>
				{
					<img
						src={CheckWhiteIcon}
						alt="check"
						style={{ paddingBottom: "0.3rem" }}
					/>
				}
				No credit card required
			</Typography>
		</Box>
	);
};

const SubscriptionPage = () => {
	const { dispatch } = useContext(TodoContext);
	const [plan, setPlan] = useState("Yearly");
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

	// const navigate = useNavigate();
	const actions = useMemo(
		() => ({
			users: bindActionCreators(
				userActions,
				dispatch
			) as unknown as userActions.UserActions,
		}),
		[dispatch]
	);

	const checkout = async (planTitle: string) => {
		try {
			const response = await actions.users.createSubscriptionCheckoutSession(
				planTitle
			);
			if (response) {
				const url = response.session?.session?.url;
				if (url) {
					const newWindow = window.open(url, "_blank");
					if (newWindow) {
						newWindow.focus();
					}
				}
			}
		} catch (error) {
			console.error(
				"There has been a problem with your fetch operation:",
				error
			);
		}
	};

	const features = [
		"1 user seat",
		"Multiple AI models",
		"Text creation",
		"Image creation",
		"SEO optimization",
		"Personalized customization",
		"Dedicated support",
	];

	return (
		<Box
			sx={{
				height: "calc(100vh - 34px)", // Make sure it takes the full viewport height
				overflowY: "auto", // Enable vertical scrolling
				width: "100%",
			}}
		>
			<Grid
				container
				spacing={4}
				justifyContent="center"
				sx={{
					maxWidth: isDesktop ? "60vw" : 375,
					margin: "auto",
					padding: "2rem",
					backgroundColor: theme.palette.secondary.main,
				}}
			>
				{isDesktop && (
					<Grid container spacing={4}>
						<Grid item xs={6}>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									width: "100%",
									gap: 0,
									paddingTop: "1rem",
									opacity: plan === "Monthly" ? 1 : 0.3,
								}}
							>
								<Typography
									variant="h4"
									component="div"
									sx={{ fontWeight: 700, mb: 4, mt: 2 }}
								>
									Pro Plan - Monthly
								</Typography>
								{features.map((feature, index) => (
									<Box
										key={index}
										sx={{
											my: 1,
											backgroundColor: theme.palette.grey[100],
											borderRadius: "0.5rem",
											padding: "0.5rem 0.5rem",
											width: "100%",
										}}
									>
										<Typography
											variant="body1"
											sx={{
												display: "flex",
												alignItems: "center",
												gap: "1rem",
											}}
										>
											{
												<img
													src={CheckWhiteIcon}
													alt="check"
													style={{ paddingBottom: "0.3rem" }}
												/>
											}
											{feature}
										</Typography>
									</Box>
								))}
								{/* <NoCreditCardRequired /> */}
								<Typography variant="h5" sx={{ mt: 4, fontWeight: 700 }}>
									$25{" "}
									<Typography variant="body2" component="span">
										per month/seat
									</Typography>
								</Typography>
								<CardActions>
									<Button
										size="large"
										variant="contained"
										fullWidth
										onClick={() => checkout("Monthly")}
										sx={{
											mt: 4,
											width: "fit-content",
											color: theme.palette.secondary.contrastText,
										}}
										disabled={plan === "Yearly"}
									>
										Start free 7-day trial
									</Button>
								</CardActions>
							</Box>
						</Grid>
						<Grid item xs={6}>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									width: "100%",
									gap: 0,
									paddingTop: "1rem",
									opacity: plan === "Yearly" ? 1 : 0.3,
								}}
							>
								<Typography
									variant="h4"
									component="div"
									sx={{ fontWeight: 700, mb: 4, mt: 2 }}
								>
									Pro Plan - Yearly
								</Typography>
								{features.map((feature, index) => (
									<Box
										key={index}
										sx={{
											my: 1,
											backgroundColor: theme.palette.grey[100],
											borderRadius: "0.5rem",
											padding: "0.5rem 0.5rem",
											width: "100%",
										}}
									>
										<Typography
											variant="body1"
											sx={{
												display: "flex",
												alignItems: "center",
												gap: "1rem",
											}}
										>
											{
												<img
													src={CheckWhiteIcon}
													alt="check"
													style={{ paddingBottom: "0.3rem" }}
												/>
											}
											{feature}
										</Typography>
									</Box>
								))}
								{/* <NoCreditCardRequired /> */}
								<Typography variant="h5" sx={{ mt: 4, fontWeight: 700 }}>
									$228{" "}
									<Typography variant="body2" component="span">
										per year/seat
									</Typography>
								</Typography>
								<CardActions>
									<Button
										size="large"
										variant="contained"
										fullWidth
										onClick={() => checkout("Yearly")}
										sx={{
											mt: 4,
											width: "fit-content",
											color: theme.palette.secondary.contrastText,
										}}
										disabled={plan === "Monthly"}
									>
										Start free 7-day trial
									</Button>
								</CardActions>
							</Box>
						</Grid>
					</Grid>
				)}
				{!isDesktop && (
					<>
						{plan === "Monthly" && (
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									width: "100%",
									gap: 0,
									paddingTop: "1rem",
								}}
							>
								<Typography
									variant="h4"
									component="div"
									sx={{ fontWeight: 700, mb: 4, mt: 2 }}
								>
									Pro Plan - Monthly
								</Typography>
								{features.map((feature, index) => (
									<Box
										key={index}
										sx={{
											my: 1,
											backgroundColor: theme.palette.grey[100],
											borderRadius: "0.5rem",
											padding: "0.5rem 0.5rem",
											width: "100%",
										}}
									>
										<Typography
											variant="body1"
											sx={{
												display: "flex",
												alignItems: "center",
												gap: "1rem",
											}}
										>
											{
												<img
													src={CheckWhiteIcon}
													alt="check"
													style={{ paddingBottom: "0.3rem" }}
												/>
											}
											{feature}
										</Typography>
									</Box>
								))}
								{/* <NoCreditCardRequired /> */}
								<Typography variant="h5" sx={{ mt: 4, fontWeight: 700 }}>
									$25{" "}
									<Typography variant="body2" component="span">
										per month/seat
									</Typography>
								</Typography>
								<CardActions>
									<Button
										size="large"
										variant="contained"
										fullWidth
										onClick={() => checkout("Monthly")}
										sx={{
											mt: 4,
											width: "fit-content",
											color: theme.palette.secondary.contrastText,
										}}
									>
										Start free 7-day trial
									</Button>
								</CardActions>
							</Box>
						)}
						{plan === "Yearly" && (
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									width: "100%",
									gap: 0,
									paddingTop: "1rem",
								}}
							>
								<Typography
									variant="h4"
									component="div"
									sx={{ fontWeight: 700, mb: 4, mt: 2 }}
								>
									Pro Plan - Yearly
								</Typography>
								{features.map((feature, index) => (
									<Box
										key={index}
										sx={{
											my: 1,
											backgroundColor: theme.palette.grey[100],
											borderRadius: "0.5rem",
											padding: "0.5rem 0.5rem",
											width: "100%",
										}}
									>
										<Typography
											variant="body1"
											sx={{
												display: "flex",
												alignItems: "center",
												gap: "1rem",
											}}
										>
											{
												<img
													src={CheckWhiteIcon}
													alt="check"
													style={{ paddingBottom: "0.3rem" }}
												/>
											}
											{feature}
										</Typography>
									</Box>
								))}
								{/* <NoCreditCardRequired /> */}
								<Typography variant="h5" sx={{ mt: 4, fontWeight: 700 }}>
									$228{" "}
									<Typography variant="body2" component="span">
										per year/seat
									</Typography>
								</Typography>
								<CardActions>
									<Button
										size="large"
										variant="contained"
										fullWidth
										onClick={() => checkout("Yearly")}
										sx={{
											mt: 4,
											width: "fit-content",
											color: theme.palette.secondary.contrastText,
										}}
									>
										Start free 7-day trial
									</Button>
								</CardActions>
							</Box>
						)}
					</>
				)}
				<Box
					sx={{
						display: "flex",
						justifyContent: "center",
						marginTop: 4,
						padding: "0.5rem",
						borderRadius: "1rem",
						background: theme.palette.grey[100],
						gap: "0.5rem",
						width: "100%",
					}}
				>
					<Button
						sx={{
							display: "grid",
							placeItems: "center",
							width: "100%",
							height: "fit-content",
							color: theme.palette.primary.main,
							backgroundColor:
								plan === "Monthly" ? theme.palette.primary.main : "",
							borderRadius: "1rem",
							// padding: "1rem",
							"&:hover": {
								backgroundColor: theme.palette.primary.main,
							},
						}}
						onClick={() => setPlan("Monthly")}
					>
						<Typography
							variant="h6"
							sx={{
								fontWeight: 700,
								// change color of text to contrast when selected
								color:
									plan === "Monthly"
										? theme.palette.secondary.contrastText
										: "",
							}}
						>
							Monthly
						</Typography>

						<Typography
							variant="body2"
							component="span"
							sx={{
								color:
									plan === "Monthly"
										? theme.palette.secondary.contrastText
										: "",
							}}
						>
							{" "}
							Cancel anytime
						</Typography>
					</Button>
					<Button
						sx={{
							display: "grid",
							placeItems: "center",
							width: "100%",
							height: "fit-content",
							color: "white",
							backgroundColor:
								plan === "Yearly" ? theme.palette.primary.main : "",
							// focus color none
							"&:focus": {
								backgroundColor: "",
							},

							borderRadius: "1rem",
							// padding: "1rem",
							"&:hover": {
								backgroundColor: theme.palette.primary.main,
							},
						}}
						onClick={() => setPlan("Yearly")}
					>
						<Typography
							variant="h6"
							sx={{
								fontWeight: 700,
								// change color of text to contrast when selected
								color:
									plan === "Yearly" ? theme.palette.secondary.contrastText : "",
							}}
						>
							Yearly
						</Typography>
						<Typography
							variant="body2"
							component="span"
							sx={{
								color:
									plan === "Yearly" ? theme.palette.secondary.contrastText : "",
							}}
						>
							Save ~20%
						</Typography>
					</Button>
				</Box>
			</Grid>
		</Box>
	);
};

export default SubscriptionPage;
