import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import {
	TextField,
	FormGroup,
	Box,
	Paper,
	Container,
	IconButton,
	CircularProgress,
	useTheme,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import * as userActions from "../../../actions/userActions";
import {
	ChatButtonStyled,
	ChatItemContainer,
	HeaderTypography,
	StyledFieldTypography,
} from "../../../ux/chatStyles";
import chatHeaderCloseIcon from "../../../assets/chatHeaderClose.svg";
import writeWebsiteCopyIcon from "../../../assets/writeWebsiteCopyNew.svg";
import writeWebsiteCopyIconLight from "../../../assets/writeWebsiteCopyLight.svg";
import { bindActionCreators } from "../../../actions/actionCreators";
import { TodoContext } from "../../todoContext";
import MarkdownViewer from "../../../utils/MarkdownRenderer";
import { ToggleButton } from "./components/ToggleButton";
import { copyToClipboard, formatMessage } from "../../../utils/chatUtils";
import Loader from "../../loader";
import ImageGeneration from "./components/ImageGeneration";

interface WriteWebsiteCopyItemProps {
	onSendMessage: (message: any) => void;
	onClose: () => void;
}

const WriteWebsiteCopyItem: React.FC<WriteWebsiteCopyItemProps> = ({
	onSendMessage,
	onClose,
}) => {
	const { dispatch } = useContext(TodoContext);
	const [loading, setLoading] = useState(false);
	const [contentType, setContentType] = useState("");
	const [contentTypeOther, setContentTypeOther] = useState("");
	const [copyDetails, setCopyDetails] = useState("");
	const [toneOfVoice, setToneOfVoice] = useState<string[]>([]);
	const [toneOfVoiceOther, setToneOfVoiceOther] = useState("");
	const [isSubmitted, setIsSubmitted] = useState(false);
	const [response, setResponse] = useState<string | null>(null);
	const [copied, setCopied] = useState(false);
	const [includeCTA, setIncludeCTA] = useState(false);
	const [threadId, setThreadId] = useState<string>("");
	const [currentStreamId, setCurrentStreamId] = useState<string | null>(null);
	const [streamedResponse, setStreamedResponse] = useState<string>("");
	const responseRef = useRef<HTMLDivElement>(null);

	const theme = useTheme();
	const themeType = theme.palette.mode;

	const actions = useMemo(
		() => ({
			users: bindActionCreators(
				userActions,
				dispatch
			) as unknown as userActions.UserActions,
		}),
		[dispatch]
	);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const handleContentTypeChange = (type: string) => {
		setContentType(type);
		if (type === "Other") {
			// Open input for other type
			setCopyDetails("");
		} else {
			setCopyDetails(""); // Ensure copy details are reset if type changes
		}
	};

	const handleToneOfVoiceChange = (tone: string) => {
		if (!toneOfVoice.includes(tone)) {
			setToneOfVoice([...toneOfVoice, tone]);
		} else {
			setToneOfVoice(toneOfVoice.filter((t) => t !== tone));
		}
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setLoading(true);

		const websiteCopyData = {
			contentType: contentTypeOther ? contentTypeOther : contentType,
			copyDetails,
			// Ensure "Other" is correctly included if provided
			toneOfVoice: toneOfVoiceOther
				? [...toneOfVoice, `Other: ${toneOfVoiceOther}`]
				: toneOfVoice,
			includeCTA,
		};

		const streamId = Date.now().toString();
		setCurrentStreamId(streamId);

		try {
			await actions.users.writeWebsiteCopyStream(
				websiteCopyData,
				(chunk) => {
					console.log("Chunk received", chunk);
					setStreamedResponse((prev) => {
						const threadIdMatch = chunk.match(/threadId:([^\s]+)/);
						if (threadIdMatch) {
							setThreadId(threadIdMatch[1]);
							return prev + chunk.replace(/threadId:[^\s]+/, "");
						}
						return prev + chunk;
					});
					if (responseRef.current) {
						responseRef.current.scrollTop = responseRef.current.scrollHeight;
					}
				},
				() => {
					setIsSubmitted(true);
					setLoading(false);
					setCurrentStreamId(null);
				},
				(error) => {
					console.error("Submission failed", error);
					setLoading(false);
					setCurrentStreamId(null);
				}
			);
		} catch (error) {
			console.error("Failed to send website copy data", error);
			setLoading(false);
			setCurrentStreamId(null);
		}
	};

	if (isSubmitted || streamedResponse) {
		return (
			<ChatItemContainer id="chat-container">
				<Paper
					style={{
						marginTop: "20px",
						marginBottom: "20px",
						borderRadius: "0.75rem",
						padding: "20px",
					}}
				>
					<Container
						sx={{ display: "flex", alignItems: "center", padding: "10px" }}
					>
						<img
							src={
								themeType === "dark"
									? writeWebsiteCopyIcon
									: writeWebsiteCopyIconLight
							}
							alt="Write Website Copy"
							style={{ marginBottom: "2px" }}
						/>
						<HeaderTypography
							variant="h6"
							sx={{ display: "flex", alignItems: "center" }}
						>
							Website Copy Submission
						</HeaderTypography>
						<IconButton onClick={onClose} sx={{ marginLeft: "auto" }}>
							<img src={chatHeaderCloseIcon} alt="Minimize" />
						</IconButton>
					</Container>
					<div
						ref={responseRef}
						style={{
							color: "#FFF",
							wordWrap: "break-word",
							whiteSpace: "pre-wrap",
							display: "flex",
							alignItems: "flex-start",
							maxHeight: "70vh",
							overflowY: "auto",
						}}
					>
						<MarkdownViewer markdownText={formatMessage(streamedResponse)} />
						<IconButton
							onClick={() => copyToClipboard(streamedResponse, setCopied)}
							size="small"
						>
							{copied ? (
								<CheckCircleIcon
									style={{ color: theme.palette.success.main }}
								/>
							) : (
								<ContentCopyIcon
									style={{ color: theme.palette.primary.main }}
								/>
							)}
						</IconButton>
					</div>
					<ImageGeneration
						threadId={threadId}
						platform={contentType}
						contentDetail={"website"}
						assistantId="createSocialMediaContentAssistant"
						generateImageBasedOnThread={
							actions.users.generateImageBasedOnThread
						}
					/>
				</Paper>
			</ChatItemContainer>
		);
	}

	const contentTypeOptions = [
		"Collection description",
		"Product description",
		"About page",
		"Homepage component",
		"Announcement",
		"Other",
	];

	const toneOfVoiceOptions = [
		"Informative",
		"Inspirational",
		"Formal",
		"Persuasive",
		"Friendly",
		"Humorous",
		"Professional",
		"Other",
	];

	return (
		<ChatItemContainer id="chat-container">
			<Paper
				sx={{
					marginTop: "20px",
					marginBottom: "20px",
					borderRadius: "0.75rem",
					padding: "20px",
				}}
			>
				<Container
					sx={{
						display: "flex",
						alignItems: "center",
						padding: "10px",
					}}
				>
					<img
						src={
							themeType === "dark"
								? writeWebsiteCopyIcon
								: writeWebsiteCopyIconLight
						}
						alt="Write Website Copy"
						style={{ marginBottom: "2px" }}
					/>
					<HeaderTypography
						variant="h6"
						sx={{ display: "flex", alignItems: "center" }}
					>
						Write Website Copy
					</HeaderTypography>
					<IconButton onClick={onClose} sx={{ marginLeft: "auto" }}>
						<img src={chatHeaderCloseIcon} alt="Minimize" />
					</IconButton>
				</Container>
				{loading ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							width: "100%",
							padding: "20px",
						}}
					>
						<Loader />
					</div>
				) : (
					<FormGroup onSubmit={handleSubmit}>
						<Box mt={2}>
							<StyledFieldTypography gutterBottom>
								Which type of content?
							</StyledFieldTypography>
							{contentTypeOptions.map((type) => (
								<ToggleButton
									key={type}
									selected={contentType === type}
									onClick={() => handleContentTypeChange(type)}
								>
									{type}
								</ToggleButton>
							))}
							{contentType.includes("Other") && (
								<TextField
									label="Please specify"
									value={contentTypeOther}
									onChange={(e) => setContentTypeOther(e.target.value)}
									sx={{ mt: 2, width: "100%" }}
								/>
							)}
						</Box>

						<Box mt={2}>
							<StyledFieldTypography gutterBottom>
								What are you looking to create?
							</StyledFieldTypography>
							<TextField
								fullWidth
								label="Navigation titles"
								variant="outlined"
								value={copyDetails}
								onChange={(e) => setCopyDetails(e.target.value)}
								sx={{ mt: 2 }}
							/>
						</Box>

						<Box mt={2}>
							<StyledFieldTypography gutterBottom>
								What should be the tone of voice?
							</StyledFieldTypography>
							{toneOfVoiceOptions.map((tone) => (
								<ToggleButton
									key={tone}
									selected={toneOfVoice.includes(tone)}
									onClick={() => handleToneOfVoiceChange(tone)}
								>
									{tone}
								</ToggleButton>
							))}
							{toneOfVoice.includes("Other") && (
								<TextField
									label="Please specify"
									value={toneOfVoiceOther}
									onChange={(e) => setToneOfVoiceOther(e.target.value)}
									sx={{ mt: 2, width: "100%" }}
								/>
							)}
						</Box>

						<Box mt={2}>
							<StyledFieldTypography gutterBottom>
								Should we add a CTA?
							</StyledFieldTypography>
							<ToggleButton
								selected={includeCTA}
								onClick={() => setIncludeCTA(true)}
							>
								Yes
							</ToggleButton>
							<ToggleButton
								selected={!includeCTA}
								onClick={() => setIncludeCTA(false)}
							>
								No
							</ToggleButton>
						</Box>

						<Box mt={2}>
							<ChatButtonStyled
								variant="contained"
								onClick={handleSubmit}
								disabled={loading}
							>
								{loading ? <CircularProgress size={24} /> : "Submit"}
							</ChatButtonStyled>
						</Box>
					</FormGroup>
				)}
			</Paper>
		</ChatItemContainer>
	);
};

export default WriteWebsiteCopyItem;
