import React from "react";
import {
	Container,
	Typography,
	Button,
	Box,
	useTheme,
	useMediaQuery,
	Paper,
	Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import useCustomUserData from "../hooks/useCustomUserData";
import { STRIPE_CUSTOMER_PORTAL_URL } from "../utils/constants";

const NotSubscribedPage: React.FC = () => {
	const { customUserData } = useCustomUserData();
	const navigate = useNavigate();
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

	const handleFeedbackClick = () => {
		const email = "support@cockpit-ai.com";
		const subject = encodeURIComponent("User Feedback/Support Request");
		const body = encodeURIComponent(
			`User Details:\nName: ${customUserData?.name}\nEmail: ${customUserData?.email}\n\nFeedback/Support Request:`
		);
		const mailtoLink = `mailto:${email}?subject=${subject}&body=${body}`;

		window.open(mailtoLink, "_blank");
	};

	const handleBillingClick = () => {
		window.open(STRIPE_CUSTOMER_PORTAL_URL, "_blank", "noopener,noreferrer");
	};

	return (
		<Container
			component="main"
			maxWidth="sm"
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				minHeight: "100vh",
				py: 4,
			}}
		>
			<Paper
				elevation={3}
				sx={{
					p: { xs: 3, md: 6 },
					borderRadius: 2,
					textAlign: "center",
					backgroundColor: theme.palette.background.paper,
				}}
			>
				<Typography variant="h2" component="h1" gutterBottom>
					😢
				</Typography>
				<Typography variant="h5" gutterBottom fontWeight="bold">
					Time flies by when you're having fun
				</Typography>
				<Typography variant="body1" paragraph>
					Looks like your trial is over. <br /> Choose your plan to get back to
					Cockpit.
				</Typography>
				<Stack
					direction={{ xs: "column", sm: "row" }}
					spacing={2}
					justifyContent="center"
					mt={4}
				>
					<Button
						variant="contained"
						onClick={() => navigate("/subscribe")}
						sx={{
							color: theme.palette.secondary.contrastText,
							py: 1,
							px: 3,
						}}
					>
						Choose Plan
					</Button>
					<Button
						variant="outlined"
						onClick={handleFeedbackClick}
						sx={{
							borderColor: theme.palette.primary.main,
							color: theme.palette.primary.main,
							py: 1,
							px: 3,
						}}
					>
						Contact Support
					</Button>
				</Stack>
				<Box mt={6}>
					<Typography variant="body1" gutterBottom fontWeight="bold">
						Already subscribed?
					</Typography>
					<Typography variant="body2" paragraph>
						Click below to view your billing details or renew your subscription
					</Typography>
					<Button
						variant="contained"
						onClick={handleBillingClick}
						sx={{
							color: theme.palette.secondary.contrastText,
							mt: 1,
							py: 1,
							px: 3,
						}}
					>
						View Billing Details
					</Button>
				</Box>
			</Paper>
		</Container>
	);
};

export default NotSubscribedPage;
