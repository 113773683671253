import React, { useContext } from "react";
import {
	Box,
	Button,
	Typography,
	useMediaQuery,
	useTheme,
} from "@mui/material";
import { StepIndicator } from "./PostSignupStepIndicator";
import { PostSignupContext } from "../../context/PostSingupContext";
import { useNavigate } from "react-router-dom";

export const PostSignupStepFive = () => {
	const { postSignupData, setPostSignupData, setCurrentStep } =
		useContext(PostSignupContext);

	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

	const navigate = useNavigate();
	const handleContinue = async () => {
		navigate("/chat");
	};

	return (
		<Box
			sx={{
				maxWidth: isDesktop ? "90vw" : 400,
				mx: "auto",
				textAlign: "center",
				padding: isDesktop ? 6 : 4,
				alignItems: "center",
				display: isDesktop ? "flex" : "block",
				flexDirection: "column",
				justifyContent: isDesktop ? "center" : "flex-start",
				height: "100%",
			}}
		>
			{/* {!isDesktop && <StepIndicator />} */}
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					width: "50%",
					height: "50%",
				}}
			>
				<Typography
					variant="h4"
					sx={{ mb: 4, textAlign: isDesktop ? "center" : "left" }}
				>
					Great, you’re ready to go. <br /> You can now use Cockpit AI for a
					7-day free trial.
				</Typography>
				{isDesktop && (
					<Button
						variant="contained"
						onClick={handleContinue}
						sx={{
							ml: 1,
							width: "fit-content",
							whiteSpace: "nowrap",
							color: theme.palette.secondary.contrastText,
						}}
					>
						Go to Cockpit AI
					</Button>
				)}
			</Box>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					mt: 6,
					padding: isDesktop ? "0 4rem" : "0 1rem",
					width: isDesktop ? "100%" : "auto",
				}}
			>
				<Box
					sx={{
						display: "flex",
						justifyContent: "flex-start",
						width: "50%",
					}}
				>
					<Button
						variant="outlined"
						onClick={() => setCurrentStep(3)}
						sx={{
							mr: 1,
							borderColor: "#29273E",
							color: "primary.contrastText",
						}}
					>
						Back
					</Button>
					{!isDesktop && (
						<Button
							variant="contained"
							onClick={handleContinue}
							sx={{
								ml: 1,
								width: "fit-content",
								whiteSpace: "nowrap",
								color: theme.palette.secondary.contrastText,
							}}
						>
							To Cockpit AI
						</Button>
					)}
				</Box>
				{/* {isDesktop && <StepIndicator />} */}
			</Box>
		</Box>
	);
};
