import React, { FC, ReactElement } from "react";
import Header from "./header";
// import Sidebar from "./sidebar";
import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";
import { IStackStyles, Stack, mergeStyleSets } from "@fluentui/react";
import {
	headerStackStyles,
	mainStackStyles,
	rootStackStyles,
	sidebarStackStyles,
} from "../ux/styles";
import CompleteSignUpComponent from "../components/SignUpComplete";
import SignInForm from "../components/signInForm";
import SignUpForm from "../components/signUpForm";
import MainAuthPage from "../components/mainAuth";
import AdminHomePage from "../components/admin/Home";
import ChatHomePage from "../components/chat/Home";
import PrivateRoute from "./PrivateRoute";
import SubscriptionPage from "../components/stripe/SubscriptionComponent";
import Success from "../components/stripe/Success";
import Cancel from "../components/stripe/Cancel";
import { useTheme } from "@mui/material";
import EmailVerificationPage from "../components/EmailVerificationPage";

const Layout: FC = (): ReactElement => {
	const location = useLocation();
	const isMainAuthRoute = location.pathname === "/";
	const theme = useTheme();

	// Merge the rootStackStyles with the background color from the theme
	const mergedRootStackStyles: IStackStyles = mergeStyleSets(rootStackStyles, {
		root: {
			background: theme.palette.secondary.main,
		},
	});

	return (
		<Stack styles={mergedRootStackStyles}>
			{/* GTM noscript tag */}
			<noscript>
				<iframe
					src="https://www.googletagmanager.com/ns.html?id=GTM-PCBB9LTD"
					height="0"
					width="0"
					style={{ display: "none", visibility: "hidden" }}
					title="Google Tag Manager"
				></iframe>
			</noscript>
			{!isMainAuthRoute && (
				<Stack.Item styles={headerStackStyles}>
					<Header></Header>
					{/* {isOnboardingRoute && <CarouselStepper activeStep={activeStep} />} */}
				</Stack.Item>
			)}
			<Stack horizontal grow={1}>
				<Stack.Item grow={1} styles={mainStackStyles}>
					<Routes>
						<Route path="/" element={<MainAuthPage />} />
						<Route path="/signin" element={<SignInForm />} />
						<Route path="/signup" element={<SignUpForm />} />
						<Route
							path="/email-verification"
							element={<EmailVerificationPage />}
						/>
						<Route
							path="/admin"
							element={
								<PrivateRoute element={<AdminHomePage />} isAdminRoute />
							}
						/>
						<Route
							path="/chat"
							element={
								<PrivateRoute
									element={<ChatHomePage />}
									isWaitingListRoute
									isSubscriptionRoute
								/>
							}
						/>
						<Route
							path="/post-signup"
							element={<PrivateRoute element={<CompleteSignUpComponent />} />}
						/>

						<Route
							path="/subscribe"
							element={<PrivateRoute element={<SubscriptionPage />} />}
						/>
						<Route
							path="/success"
							element={<PrivateRoute element={<Success />} />}
						/>
						<Route
							path="/cancel"
							element={<PrivateRoute element={<Cancel />} />}
						/>
						<Route path="*" element={<Navigate to="/" replace />} />
					</Routes>
				</Stack.Item>
			</Stack>
		</Stack>
	);
};

export default Layout;
