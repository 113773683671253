import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { UserContext } from "../context/UserDataContext";
import NotSubscribedPage from "../components/notSubscribed";
import NotFoundPage from "../components/notFound";
import EmailVerificationPage from "../components/EmailVerificationPage";

interface PrivateRouteProps {
	element: React.ReactElement;
	isAdminRoute?: boolean;
	isWaitingListRoute?: boolean;
	isSubscriptionRoute?: boolean;
}

const TRIAL_PERIOD_DAYS = 7;

const isWithinTrialPeriod = (createdAtString: string): boolean => {
	const createdAt = new Date(createdAtString);
	const trialPeriodAgo = new Date();
	trialPeriodAgo.setDate(trialPeriodAgo.getDate() - TRIAL_PERIOD_DAYS);
	return createdAt >= trialPeriodAgo;
};

const PrivateRoute: React.FC<PrivateRouteProps> = ({
	element,
	isAdminRoute = false,
	isWaitingListRoute = false,
	isSubscriptionRoute = false,
}) => {
	const { user, loading: authLoading } = useAuth();
	const { userData, loading: userLoading } = useContext(UserContext);

	// Check if either auth data or user data is still loading
	if (authLoading || userLoading) {
		return <div>Loading...</div>;
	}

	// Check if the user is authenticated
	if (!user) {
		return <Navigate to="/signin" replace />;
	}

	// Check if the user's email is verified (except for admin routes)
	if (!isAdminRoute && !user.emailVerified) {
		return <EmailVerificationPage />;
	}

	if (isAdminRoute && userData?.role !== "admin") {
		return <NotFoundPage />;
	}

	if (
		isSubscriptionRoute &&
		userData?.role !== "admin" &&
		!userData?.isSubscribed &&
		!(
			userData?.createdDate &&
			isWithinTrialPeriod(userData.createdDate.toString())
		)
	) {
		return <NotSubscribedPage />;
	}

	return element;
};

export default PrivateRoute;
