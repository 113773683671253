// import { useState, useEffect, useMemo, useContext } from "react";
// import { useAuth } from "../context/AuthContext";
// import { User } from "../models/user";
// import * as userActions from "../actions/userActions";
// import { bindActionCreators } from "../actions/actionCreators";
// import { AppContext } from "../models/applicationState";
// import { TodoContext } from "../components/todoContext";

// const useCustomUserData = () => {
// 	const { user } = useAuth();
// 	const [customUserData, setCustomUserData] = useState<User | null>(null);
// 	const [isProfileComplete, setIsProfileComplete] = useState<boolean>(false);
// 	const [isLoading, setIsLoading] = useState(true);

// 	const appContext = useContext<AppContext>(TodoContext);
// 	const actions = useMemo(
// 		() => ({
// 			users: bindActionCreators(
// 				userActions,
// 				appContext.dispatch
// 			) as unknown as userActions.UserActions,
// 		}),
// 		[appContext.dispatch]
// 	);

// 	useEffect(() => {
// 		const fetchUserData = async () => {
// 			if (!user || customUserData || isLoading) return;
// 			setIsLoading(true);
// 			try {
// 				const userData = await actions.users.getMe();
// 				if (JSON.stringify(userData) !== JSON.stringify(customUserData)) {
// 					setCustomUserData(userData);
// 					setIsProfileComplete(userData.isProfileComplete || false);
// 				}
// 			} catch (error) {
// 				console.error(error);
// 			} finally {
// 				setIsLoading(false);
// 			}
// 		};

// 		fetchUserData();
// 	}, [user, customUserData]); // Removed customUserData from dependencies

// 	return { customUserData, isProfileComplete, isLoading };
// };

// export default useCustomUserData;

import { useState, useEffect, useMemo, useContext } from "react";
import { useAuth } from "../context/AuthContext";
import { User } from "../models/user";
import * as userActions from "../actions/userActions";
import { bindActionCreators } from "../actions/actionCreators";
import { AppContext } from "../models/applicationState";
import { TodoContext } from "../components/todoContext";
import { useLocation, useNavigate } from "react-router-dom";

const useCustomUserData = () => {
	const { user } = useAuth();
	const navigate = useNavigate();
	const [customUserData, setCustomUserData] = useState<User | null>(null);
	const [isProfileComplete, setIsProfileComplete] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState(false);

	const appContext = useContext<AppContext>(TodoContext);
	const actions = useMemo(
		() => ({
			users: bindActionCreators(
				userActions,
				appContext.dispatch
			) as unknown as userActions.UserActions,
		}),
		[appContext.dispatch]
	);

	useEffect(() => {
		if (user && !customUserData) {
			const fetchUserData = async () => {
				try {
					setIsLoading(true);
					const userData = await actions.users.getMe();
					setCustomUserData(userData);
					setIsProfileComplete(userData.isProfileComplete || false);
				} finally {
					setIsLoading(false);
				}
			};
			fetchUserData();
		}
	}, [user, actions.users]);

	const redirectToAppropriatePage = (currentPath: string) => {
		if (isLoading || currentPath !== "/" || !customUserData) return; // Prevent redirection if already on a different page
		let destination = "/chat"; // Default route

		if (!customUserData?.isProfileComplete) {
			destination = "/post-signup";
		}
		setIsLoading(false);
		navigate(destination);
	};

	// Then in your component
	// useEffect(() => {
	// 	redirectToAppropriatePage();
	// }, [customUserData, isLoading]);

	return {
		customUserData,
		setCustomUserData,
		isProfileComplete,
		isLoading,
		redirectToAppropriatePage,
	};
};

export default useCustomUserData;
