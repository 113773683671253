import axios, {
	AxiosInstance,
	AxiosRequestHeaders,
	AxiosResponse,
} from "axios";
import { getAuth, getIdToken } from "firebase/auth";
import { OnboardingData } from "../models/onboarding";

export interface QueryOptions {
	top?: number;
	skip?: number;
}
export interface Pagination {
	page: number;
	limit: number;
	total: number;
	pages: number;
}

export interface Entity {
	id?: string;
	created?: Date;
	updated?: Date;
}

export interface UserQueryOptions {
	page?: number;
	limit?: number;
	search?: string;
}

export interface Message {
	content: string;
	file_ids?: string[];
	thread?: {
		id: string;
	};
	threadId?: string;
}

export interface Survey {
	surveyPurpose?: string[];
	audience?: string[];
	questionsCount?: number;
	toneOfVoice?: string[];
}
export interface Blog {
	blogPostLength: number;
	blogPostType: string;
	blogPostDetails: string;
	toneOfVoice: string[];
}

export interface Newsletter {
	newsletterType: string;
	flowType?: string;
	emailsCount: number;
	mainMessage: string;
	toneOfVoice: string[];
	otherDetails?: string;
}

export interface AdCampaign {
	campaignType: string;
	topics: string;
	toneOfVoice: string[];
	additionalDetails: string;
	addCTA: boolean;
}

export interface Document {
	documentType: string;
	topics: string;
	wordCount: number;
	toneOfVoice: string[];
	additionalDetails: string;
	includeCTA: boolean;
}

// receive deckType (string), slideCount (number), topics (string), toneOfVoice (array of strings, might contain "Other: other details"), cta (boolean)
export interface Deck {
	deckType: string;
	slideCount: number;
	topics: string;
	toneOfVoice: string[];
	cta: boolean;
}

// receive mainMessage (string), toneOfVoice (array of strings, might contain "Other: other details"), CTA (boolean), feedOrStory (string, only if platform is Instagram or Facebook. Otherwise null), postOrArticle (string, only if platform is Linkedin. Otherwise null), toneOfVoiceOther (string), platform (string)
export interface SocialContent {
	mainMessage: string;
	toneOfVoice: string[];
	CTA: boolean;
	feedOrStory: string | null;
	postOrArticle: string | null;
	toneOfVoiceOther: string;
	platform: string;
	about: string;
	otherDetails: string;
}

// receive contentType (string), copyDetails (string), toneOfVoice (array of strings, might contain "Other: other details"), includeCTA (boolean)
export interface WebsiteCopy {
	contentType: string;
	copyDetails: string;
	toneOfVoice: string[];
	includeCTA: boolean;
}

export interface Conversation {
	user_uid: string;
	threadId: string;
	assistantType: string;
	createdDate: Date;
	updatedDate: Date;
	file_ids: string[];
	tokenUsage: number;
	liked: boolean | null;
	generatedImages?: string[] | undefined;
}

export interface chatMessage {
	id: string;
	object: string;
	created_at: number;
	thread_id: string;
	role: string;
	content: [
		{
			type: string;
			text: {
				value: string;
				annotations: string[];
			};
		}
	];
	file_ids: string[];
	assistant_id: string | null;
	run_id: string | null;
	metadata: object;
}

export interface GeneratedImage {
	images: {
		created: number;
		data: [
			{
				url?: string;
				b64_json?: string;
			}
		];
	};
}

// receives subject (string), artisticStyle (array of strings, might contain "Other: other details"), additionalDetails (string), elements (string), imageSize (string)
export interface ImagePrompt {
	subject: string;
	artisticStyle: string[];
	additionalDetails: string;
	elements: string;
	imageSize: string;
}

export interface StripeSession {
	session: {
		session: {
			id: string;
			object: string;
			allow_promotion_codes: boolean;
			amount_subtotal: number;
			amount_total: number;
			billing_address_collection: string | null;
			cancel_url: string;
			client_reference_id: string | null;
			currency: string;
			customer: string;
			customer_email: string | null;
			livemode: boolean;
			locale: string | null;
			payment_intent: string | null;
			payment_method_types: string[];
			payment_status: string;
			setup_intent: string | null;
			shipping: string | null;
			shipping_address_collection: string | null;
			submit_type: string | null;
			subscription: string | null;
			success_url: string;
			total_details: object;
			url: string;
		};
	};
}

export abstract class RestService<T extends Entity> {
	protected client: AxiosInstance;

	public constructor(baseUrl: string, baseRoute: string) {
		this.client = axios.create({
			baseURL: `${baseUrl}${baseRoute}`,
		});

		this.client.interceptors.request.use(async (config) => {
			const auth = getAuth();
			const currentUser = auth.currentUser;
			if (currentUser) {
				const token = await getIdToken(currentUser);

				// Directly set the Authorization header
				if (config.headers) {
					config.headers["Authorization"] = `Bearer ${token}`;
				} else {
					// In the unlikely case headers are not defined, use a type assertion
					config.headers = {} as AxiosRequestHeaders;
					config.headers["Authorization"] = `Bearer ${token}`;
				}
			}
			return config;
		});
	}

	public async getList(queryOptions?: QueryOptions): Promise<T[]> {
		const response = await this.client.request<T[]>({
			method: "GET",
			data: queryOptions,
		});

		return response.data;
	}

	public async get(id: string): Promise<T> {
		const response = await this.client.request<T>({
			method: "GET",
			url: id,
		});

		return response.data;
	}

	public async save(entity: T): Promise<T> {
		return entity.id ? await this.put(entity) : await this.post(entity);
	}

	public async delete(id: string): Promise<void> {
		await this.client.request<void>({
			method: "DELETE",
			url: id,
		});
	}

	private async post(entity: T): Promise<T> {
		const response = await this.client.request<T>({
			method: "POST",
			data: entity,
		});

		return response.data;
	}

	private async put(entity: T): Promise<T> {
		const response = await this.client.request<T>({
			method: "PUT",
			url: entity.id,
			data: entity,
		});

		return response.data;
	}

	public async patch(id: string, entity: Partial<T>): Promise<T> {
		const response = await this.client.request<T>({
			method: "PATCH",
			url: id,
			data: entity,
		});

		return response.data;
	}

	public async signUp(entity: T): Promise<T> {
		const response = await this.client.request<T>({
			method: "POST",
			url: "signUp",
			data: entity,
		});

		return response.data;
	}

	public async completeSignUp(entity: T): Promise<T> {
		const response = await this.client.request<T>({
			method: "POST",
			url: "completeSignUp",
			data: entity,
		});

		return response.data;
	}

	public async getMe(): Promise<T> {
		const response = await this.client.request<T>({
			method: "GET",
			url: "me",
		});

		return response.data;
	}

	// sends onboarding data to the server, and returns the updated user
	public async saveOnboardingData(onboardingData: OnboardingData): Promise<T> {
		const response = await this.client.request<T>({
			method: "POST",
			url: "onboardingData",
			data: onboardingData,
		});
		return response.data;
	}

	// gets onboarding data from the server
	public async getOnboardingData(): Promise<OnboardingData> {
		try {
			const response = await this.client.request<OnboardingData>({
				method: "GET",
				url: "onboardingData",
			});
			return response.data;
		} catch (error) {
			console.error("Error fetching onboarding data:", error);
			throw error;
		}
	}

	// ---------------------- chat routes ----------------------
	// sends a message to the chat service (with message content and optional threadId)
	public async chatMessage(
		message: Message
	): Promise<{ message: string; threadId?: string; imageFileId?: string }> {
		const response = await this.client.request<{
			message: string;
			threadId?: string;
			imageFileId?: string;
		}>({
			method: "POST",
			url: "subscription/chat",
			data: message,
		});

		return response.data;
	}

	public async chatMessageStream(
		message: Message,
		onMessage: (message: string) => void,
		onComplete: () => void,
		onError: (error: any) => void
	): Promise<void> {
		try {
			const auth = getAuth();
			const currentUser = auth.currentUser;
			if (!currentUser) {
				throw new Error("User not authenticated");
			}
			const token = await getIdToken(currentUser);
			const response = await fetch(
				`${this.client.defaults.baseURL}/subscription/chat`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						content: message.content,
						threadId: message.threadId,
						file_ids: message.file_ids, // Include file_ids in the request body
					}),
				}
			);

			const reader = response.body?.getReader();
			const decoder = new TextDecoder();

			if (reader) {
				let done = false;
				while (!done) {
					const { value, done: readerDone } = await reader.read();
					if (readerDone) {
						done = true;
						break;
					}

					const chunk = decoder.decode(value, { stream: true });
					// Process the chunk of message
					onMessage(chunk);
				}
			}

			// Streaming is complete
			onComplete();
		} catch (error) {
			onError(error);
		}
	}

	// upload a file
	public async uploadFile(file: File): Promise<string> {
		const formData = new FormData();
		formData.append("file", file);

		const response = await this.client.request({
			method: "POST",
			url: "subscription/upload",
			data: formData,
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});

		return response.data;
	}

	public async createSurvey(
		survey: Survey
	): Promise<{ message: string; threadId?: string; imageFileId?: string }> {
		const response = await this.client.request<{
			message: string;
			threadId?: string;
			imageFileId?: string;
		}>({
			method: "POST",
			url: "subscription/survey",
			data: survey,
		});

		return response.data;
	}

	public async createSurveyStream(
		survey: Survey,
		onMessage: (message: string) => void, // Function to process each chunk of message
		onComplete: () => void, // Function when streaming is complete
		onError: (error: any) => void // Function to handle error during streaming
	): Promise<void> {
		try {
			const auth = getAuth();
			const currentUser = auth.currentUser;
			if (!currentUser) {
				throw new Error("User not authenticated");
			}
			const token = await getIdToken(currentUser);
			const response = await fetch(
				`${this.client.defaults.baseURL}/subscription/survey`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(survey),
				}
			);

			const reader = response.body?.getReader();
			const decoder = new TextDecoder();

			if (reader) {
				let done = false;
				while (!done) {
					const { value, done: readerDone } = await reader.read();
					if (readerDone) {
						done = true;
						break;
					}

					const chunk = decoder.decode(value, { stream: true });
					// Process the chunk of message
					onMessage(chunk);
				}
			}

			// Streaming is complete
			onComplete();
		} catch (error) {
			onError(error);
		}
	}

	public async writeBlogPost(
		blog: Blog
	): Promise<{ message: string; threadId?: string; imageFileId?: string }> {
		const response = await this.client.request<{
			message: string;
			threadId?: string;
			imageFileId?: string;
		}>({
			method: "POST",
			url: "subscription/blog",
			data: blog,
		});

		return response.data;
	}

	public async writeBlogPostStream(
		blog: Blog,
		onMessage: (message: string) => void, // Function to process each chunk of message
		onComplete: () => void, // Function when streaming is complete
		onError: (error: any) => void // Function to handle error during streaming
	): Promise<void> {
		try {
			const auth = getAuth();
			const currentUser = auth.currentUser;
			if (!currentUser) {
				throw new Error("User not authenticated");
			}
			const token = await getIdToken(currentUser);
			const response = await fetch(
				`${this.client.defaults.baseURL}/subscription/blog`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(blog),
				}
			);

			const reader = response.body?.getReader();
			const decoder = new TextDecoder();

			if (reader) {
				let done = false;
				while (!done) {
					const { value, done: readerDone } = await reader.read();
					if (readerDone) {
						done = true;
						break;
					}

					const chunk = decoder.decode(value, { stream: true });
					// Process the chunk of message
					onMessage(chunk);
				}
			}

			// Streaming is complete
			onComplete();
		} catch (error) {
			onError(error);
		}
	}

	public async writeNewsletter(
		newsletter: Newsletter
	): Promise<{ message: string; threadId?: string; imageFileId?: string }> {
		const response = await this.client.request<{
			message: string;
			threadId?: string;
			imageFileId?: string;
		}>({
			method: "POST",
			url: "subscription/newsletter",
			data: newsletter,
		});

		return response.data;
	}

	public async writeNewsletterStream(
		newsletter: Newsletter,
		onMessage: (message: string) => void, // Function to process each chunk of message
		onComplete: () => void, // Function when streaming is complete
		onError: (error: any) => void // Function to handle error during streaming
	): Promise<void> {
		try {
			const auth = getAuth();
			const currentUser = auth.currentUser;
			if (!currentUser) {
				throw new Error("User not authenticated");
			}
			const token = await getIdToken(currentUser);
			const response = await fetch(
				`${this.client.defaults.baseURL}/subscription/newsletter`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(newsletter),
				}
			);

			const reader = response.body?.getReader();
			const decoder = new TextDecoder();

			if (reader) {
				let done = false;
				while (!done) {
					const { value, done: readerDone } = await reader.read();
					if (readerDone) {
						done = true;
						break;
					}

					const chunk = decoder.decode(value, { stream: true });
					// Process the chunk of message
					onMessage(chunk);
				}
			}

			// Streaming is complete
			onComplete();
		} catch (error) {
			onError(error);
		}
	}

	public async createAdCampaign(
		adCampaign: AdCampaign
	): Promise<{ message: string; threadId?: string; imageFileId?: string }> {
		const response = await this.client.request<{
			message: string;
			threadId?: string;
			imageFileId?: string;
		}>({
			method: "POST",
			url: "subscription/adCampaign",
			data: adCampaign,
		});

		return response.data;
	}

	public async createAdCampaignStream(
		adCampaign: AdCampaign,
		onMessage: (message: string) => void, // Function to process each chunk of message
		onComplete: () => void, // Function when streaming is complete
		onError: (error: any) => void // Function to handle error during streaming
	): Promise<void> {
		try {
			const auth = getAuth();
			const currentUser = auth.currentUser;
			if (!currentUser) {
				throw new Error("User not authenticated");
			}
			const token = await getIdToken(currentUser);
			const response = await fetch(
				`${this.client.defaults.baseURL}/subscription/adCampaign`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(adCampaign),
				}
			);

			const reader = response.body?.getReader();
			const decoder = new TextDecoder();

			if (reader) {
				let done = false;
				while (!done) {
					const { value, done: readerDone } = await reader.read();
					if (readerDone) {
						done = true;
						break;
					}

					const chunk = decoder.decode(value, { stream: true });
					// Process the chunk of message
					onMessage(chunk);
				}
			}

			// Streaming is complete
			onComplete();
		} catch (error) {
			onError(error);
		}
	}

	public async createDocument(
		document: Document
	): Promise<{ message: string; threadId?: string; imageFileId?: string }> {
		const response = await this.client.request<{
			message: string;
			threadId?: string;
			imageFileId?: string;
		}>({
			method: "POST",
			url: "subscription/createDocument",
			data: document,
		});

		return response.data;
	}

	public async createDocumentStream(
		document: Document,
		onMessage: (message: string) => void, // Function to process each chunk of message
		onComplete: () => void, // Function when streaming is complete
		onError: (error: any) => void // Function to handle error during streaming
	): Promise<void> {
		try {
			const auth = getAuth();
			const currentUser = auth.currentUser;
			if (!currentUser) {
				throw new Error("User not authenticated");
			}
			const token = await getIdToken(currentUser);
			const response = await fetch(
				`${this.client.defaults.baseURL}/subscription/createDocument`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(document),
				}
			);

			const reader = response.body?.getReader();
			const decoder = new TextDecoder();

			if (reader) {
				let done = false;
				while (!done) {
					const { value, done: readerDone } = await reader.read();
					if (readerDone) {
						done = true;
						break;
					}

					const chunk = decoder.decode(value, { stream: true });
					// Process the chunk of message
					onMessage(chunk);
				}
			}

			// Streaming is complete
			onComplete();
		} catch (error) {
			onError(error);
		}
	}

	public async createSlideDeck(
		deck: Deck
	): Promise<{ message: string; threadId?: string; imageFileId?: string }> {
		const response = await this.client.request<{
			message: string;
			threadId?: string;
			imageFileId?: string;
		}>({
			method: "POST",
			url: "subscription/createSlideDeck",
			data: deck,
		});

		return response.data;
	}

	public async createSlideDeckStream(
		deck: Deck,
		onMessage: (message: string) => void, // Function to process each chunk of message
		onComplete: () => void, // Function when streaming is complete
		onError: (error: any) => void // Function to handle error during streaming
	): Promise<void> {
		try {
			const auth = getAuth();
			const currentUser = auth.currentUser;
			if (!currentUser) {
				throw new Error("User not authenticated");
			}
			const token = await getIdToken(currentUser);
			const response = await fetch(
				`${this.client.defaults.baseURL}/subscription/createSlideDeck`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(deck),
				}
			);

			const reader = response.body?.getReader();
			const decoder = new TextDecoder();

			if (reader) {
				let done = false;
				while (!done) {
					const { value, done: readerDone } = await reader.read();
					if (readerDone) {
						done = true;
						break;
					}

					const chunk = decoder.decode(value, { stream: true });
					// Process the chunk of message
					onMessage(chunk);
				}
			}

			// Streaming is complete
			onComplete();
		} catch (error) {
			onError(error);
		}
	}

	public async createSocialContent(
		socialContent: SocialContent
	): Promise<{ message: string; threadId?: string; imageFileId?: string }> {
		const response = await this.client.request<{
			message: string;
			threadId?: string;
			imageFileId?: string;
		}>({
			method: "POST",
			url: "subscription/createSocialMediaContent",
			data: socialContent,
		});

		return response.data;
	}

	public async createSocialContentStream(
		socialContent: SocialContent,
		onMessage: (message: string) => void, // Function to process each chunk of message
		onComplete: () => void, // Function when streaming is complete
		onError: (error: any) => void // Function to handle error during streaming
	): Promise<void> {
		try {
			const auth = getAuth();
			const currentUser = auth.currentUser;
			if (!currentUser) {
				throw new Error("User not authenticated");
			}
			const token = await getIdToken(currentUser);
			const response = await fetch(
				`${this.client.defaults.baseURL}/subscription/createSocialMediaContent`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(socialContent),
				}
			);

			const reader = response.body?.getReader();
			const decoder = new TextDecoder();

			if (reader) {
				let done = false;
				while (!done) {
					const { value, done: readerDone } = await reader.read();
					if (readerDone) {
						done = true;
						break;
					}

					const chunk = decoder.decode(value, { stream: true });
					// Process the chunk of message
					onMessage(chunk);
				}
			}

			// Streaming is complete
			onComplete();
		} catch (error) {
			onError(error);
		}
	}

	public async writeWebsiteCopy(
		websiteCopy: WebsiteCopy
	): Promise<{ message: string; threadId?: string; imageFileId?: string }> {
		const response = await this.client.request<{
			message: string;
			threadId?: string;
			imageFileId?: string;
		}>({
			method: "POST",
			url: "subscription/writeWebsiteCopy",
			data: websiteCopy,
		});

		return response.data;
	}

	public async writeWebsiteCopyStream(
		websiteCopy: WebsiteCopy,
		onMessage: (message: string) => void, // Function to process each chunk of message
		onComplete: () => void, // Function when streaming is complete
		onError: (error: any) => void // Function to handle error during streaming
	): Promise<void> {
		try {
			const auth = getAuth();
			const currentUser = auth.currentUser;
			if (!currentUser) {
				throw new Error("User not authenticated");
			}
			const token = await getIdToken(currentUser);
			const response = await fetch(
				`${this.client.defaults.baseURL}/subscription/writeWebsiteCopy`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(websiteCopy),
				}
			);

			const reader = response.body?.getReader();
			const decoder = new TextDecoder();

			if (reader) {
				let done = false;
				while (!done) {
					const { value, done: readerDone } = await reader.read();
					if (readerDone) {
						done = true;
						break;
					}

					const chunk = decoder.decode(value, { stream: true });
					// Process the chunk of message
					onMessage(chunk);
				}
			}

			// Streaming is complete
			onComplete();
		} catch (error) {
			onError(error);
		}
	}

	public async generateImageBasedOnThread(
		threadId: string,
		assistantType: string
	): Promise<GeneratedImage> {
		const response = await this.client.request<GeneratedImage>({
			method: "POST",
			url: "subscription/generateImageBasedOnThread",
			data: { threadId, assistantType },
		});

		return response.data;
	}

	// generateImageBasedOnPrompt
	public async generateImageBasedOnPrompt(
		imagePrompt: ImagePrompt
	): Promise<GeneratedImage> {
		const response = await this.client.request<GeneratedImage>({
			method: "POST",
			url: "subscription/generateImageBasedOnPrompt",
			data: imagePrompt,
		});

		return response.data;
	}

	// ---------------------- admin routes ----------------------

	public async getUserByUid(uid: string): Promise<T> {
		const response = await this.client.request<T>({
			method: "GET",
			url: `user/${uid}`,
		});

		return response.data;
	}

	// can recieve query options and pagination (page, limit)
	public async getAllUsers(queryOptions?: UserQueryOptions): Promise<T[]> {
		const response = await this.client.request<T[]>({
			method: "GET",
			url: "users",
			params: queryOptions, // Use params for GET requests
		});

		return response.data;
	}

	// export users to csv, returns Blob
	public async exportUsersToCSV(): Promise<AxiosResponse<Blob>> {
		const response = await this.client.request<Blob>({
			method: "GET",
			url: "users/export-users",
			responseType: "blob",
		});

		return response;
	}

	public async deleteUserByUid(uid: string): Promise<void> {
		await this.client.request<void>({
			method: "DELETE",
			url: `user/${uid}`,
		});
	}

	public async updateUserByUid(uid: string, entity: T): Promise<T> {
		const response = await this.client.request<T>({
			method: "PUT",
			url: `user/${uid}`,
			data: entity,
		});

		return response.data;
	}

	public async createUser(entity: T): Promise<T> {
		const response = await this.client.request<T>({
			method: "POST",
			url: "user",
			data: entity,
		});

		return response.data;
	}

	public async getWaitingList(): Promise<T[]> {
		const response = await this.client.request<T[]>({
			method: "GET",
			url: "waitingList",
		});

		return response.data;
	}

	// approve users from waiting list
	public async approveUsersFromWaitingList(uids: string[]): Promise<T[]> {
		const response = await this.client.request<T[]>({
			method: "POST",
			url: "approveUsers",
			data: { uids },
		});

		return response.data;
	}

	public async getOnboardingDataByUid(uid: string): Promise<OnboardingData> {
		const response = await this.client.request<OnboardingData>({
			method: "GET",
			url: `user/${uid}/onboardingData`,
		});

		return response.data;
	}

	public async editOnboardingDataByUid(
		uid: string,
		onboardingData: OnboardingData
	): Promise<OnboardingData> {
		const response = await this.client.request<OnboardingData>({
			method: "PUT",
			url: `user/${uid}/onboardingData`,
			data: onboardingData,
		});

		return response.data;
	}

	// get conversations of a user by uid
	public async getConversationsByUid(
		uid: string,
		page: number,
		limit: number
	): Promise<{ conversations: Conversation[]; pagination: Pagination }> {
		const response = await this.client.request<{
			conversations: Conversation[];
			pagination: Pagination;
		}>({
			method: "GET",
			url: `conversations/${uid}`,
			params: { page, limit }, // Send page and limit as query parameters
		});

		return response.data;
	}

	// list messages of a conversation thread by threadId (no pagination)
	public async listMessages(threadId: string): Promise<chatMessage[]> {
		const response = await this.client.request<chatMessage[]>({
			method: "GET",
			url: `conversations/${threadId}/messages`,
		});

		return response.data;
	}

	// searchUsersByEmail
	public async searchUsersByEmail(email: string): Promise<T[]> {
		const response = await this.client.request<T[]>({
			method: "GET",
			url: "users/search",
			params: { email },
		});

		return response.data;
	}

	// ---------------------- stripe routes ----------------------

	// stripe subscription - sends plan (string) and return stripe session
	public async createSubscriptionCheckoutSession(
		plan: string
	): Promise<StripeSession> {
		const response = await this.client.request<StripeSession>({
			method: "POST",
			url: "subscribe",
			data: { plan },
		});

		return response.data;
	}
}
