import React, { useEffect, useState } from "react";
import {
	Typography,
	Button,
	Box,
	Paper,
	Container,
	useTheme,
	useMediaQuery,
	CircularProgress,
} from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import { useAuth } from "../context/AuthContext";

const StyledPaper = styled(Paper)(({ theme }) => ({
	padding: theme.spacing(4),
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	gap: theme.spacing(3),
	maxWidth: 600,
	margin: "auto",
	marginTop: theme.spacing(4),
	[theme.breakpoints.down("sm")]: {
		padding: theme.spacing(3),
		marginTop: theme.spacing(2),
	},
}));

const EmailVerificationPage: React.FC = () => {
	const { user, sendVerificationEmail, logout } = useAuth();
	const [verificationSent, setVerificationSent] = useState(false);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	useEffect(() => {
		if (user?.emailVerified) {
			navigate("/dashboard");
		}
	}, [user, navigate]);

	const handleResendVerification = async () => {
		setLoading(true);
		try {
			await sendVerificationEmail();
			setVerificationSent(true);
			// if verification email sent successfully, show a success message and then log out the user and redirect to the login page
			// setTimeout(() => {
			// 	logout(() => {
			// 		// Perform actions after logout, like navigation
			// 		navigate("/");
			// 	});
			// });
		} catch (error) {
			console.error("Error sending verification email:", error);
		} finally {
			setLoading(false);
		}
	};

	// handle approve email verification (sign the user out and redirect to the login page)
	const handleApproveEmailVerification = () => {
		logout(() => {
			// Perform actions after logout, like navigation
			navigate("/signin");
		});
	};

	return (
		<Container maxWidth="sm">
			<StyledPaper elevation={3}>
				<EmailIcon
					sx={{
						fontSize: isMobile ? 60 : 80,
						color: theme.palette.primary.main,
					}}
				/>
				<Typography
					variant={isMobile ? "h5" : "h4"}
					align="center"
					gutterBottom
				>
					Verify Your Email
				</Typography>
				<Typography variant="body1" align="center" paragraph>
					We've sent a verification email to your registered email address.
					Please check your inbox and click the verification link.
				</Typography>
				{verificationSent && (
					<Typography
						variant="body2"
						align="center"
						color="success.main"
						paragraph
					>
						Verification email sent successfully. Please check your inbox.
					</Typography>
				)}
				<Button
					variant="contained"
					color="primary"
					onClick={handleResendVerification}
					disabled={verificationSent || loading}
					startIcon={
						loading ? <CircularProgress size={20} color="inherit" /> : null
					}
					sx={{ mt: 2, color: theme.palette.secondary.contrastText }}
				>
					{loading
						? "Sending..."
						: verificationSent
						? "Verification Email Sent"
						: "Resend Verification Email"}
				</Button>
				<Button
					variant="outlined"
					color="primary"
					onClick={handleApproveEmailVerification}
					sx={{ mt: 2 }}
				>
					I have verified my email
				</Button>
			</StyledPaper>
		</Container>
	);
};

export default EmailVerificationPage;
