import {
	Button,
	Container,
	Input,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Slider,
	Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledList = styled(List)`
	${({ theme }) => `
    &.MuiList-root {
      margin: 1rem 0 0 0; // top, right, bottom, left
      display: flex;
      flex-direction: column;
      align-items: center; // Center items in the list
      justify-content: center; // Ensure all items are centered
      height: 100%;
      background: ${theme.palette.background.default};
    }
  `}
`;

export const StyledListItem = styled(ListItem)`
	${({ theme }) => `
    &.MuiListItem-root {
      background: ${theme.palette.grey[100]};
      border-radius: 0.5rem;
      display: flex;
      align-items: center; // Center content within each list item
      justify-content: center; // Center the list item itself
      margin-bottom: 1rem;
      width: 90%; // Use full width for better centering
    }
  `}
`;

export const StyledListItemButton = styled(ListItemButton)`
	display: flex;
	align-items: center;
	justify-content: center; // Center the content within the button
	width: 100%; // Full width within the list item
	padding: 0;
	height: 6rem;
	margin: 0 auto; // Center the button within the list item
`;

export const StyledListItemIcon = styled(ListItemIcon)`
	${({ theme }) => `
	box-sizing: border-box; // Ensures padding and border are included in height calculation
	border-right: 1px solid ${theme.palette.divider};
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1.5rem;
  `}
`;

export const StyledListItemText = styled(ListItemText)`
	${({ theme }) => `
	flex-grow: 1;
	padding-left: 1rem;
	justify-content: center;
	align-items: center;
	width: 80%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 0 1rem;

	.MuiListItemText-primary {
		color: ${theme.palette.text.primary};
		font-weight: bold;
	}

	.MuiListItemText-secondary {
	// take color from theme grey 200
		color: ${theme.palette.grey[200]};
		font-weight: 400;
		font-size: 0.875rem;
	}
  `}
`;

export const PromptBarBackdrop = styled("div")(({ theme }) => ({
	position: "absolute",
	bottom: "56px", // Align with PromptBar's position
	width: "100%",
	height: "65px", // Adjust based on PromptBar's height including padding
	backgroundColor: theme.palette.background.paper,
	zIndex: 100, // Ensure this is below the PromptBar's zIndex of 101
}));

export const PromptInput = styled(Input)(({ theme }) => ({
	color: theme.palette.text.primary,
	flex: 1,
	marginRight: "8px",
}));

export const PromptBarContainer = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	padding: "8px",
	backgroundColor: theme.palette.grey[500], // Use theme's default background color
	position: "fixed",
	bottom: "1rem",
	width: "90%",
	height: "2.5rem",
	borderRadius: "2rem",
	// add shadow
	boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",

	zIndex: 101, // Above the BottomBar component

	// Responsive styling for desktop
	"@media (min-width: 600px)": {
		position: "absolute",
		bottom: "0",
		width: "95%",
		maxWidth: "700px",
	},
}));

export const ChatItemContainer = styled(Container)`
	${({ theme }) => `
        background: ${theme.palette.background.default};
        height: 100%;
        width: 100%; // Ensure it takes the full width available
        position: "relative";
    `}
`;

export const UserListItemText = styled(ListItemText)`
	${({ theme }) => `
	&.MuiListItemText-root {
		color: ${theme.palette.text.primary}; // Replace with the specific text color you want
		border-radius: 10px; // You can adjust this as necessary
		margin: 4px 16px; // Space out each list item
		padding: 8px 16px 8px 10px; // Adjust padding as necessary
		max-width: 15.125rem; // So it doesn't span the full width of the list
		word-wrap: break-word; // To ensure long words don't overflow
		align-items: flex-start;
		align-content: flex-start;
		border-radius: 1rem 1rem 0rem 1rem;
		background: ${theme.palette.background.default};
		width: fit-content;
		white-space: pre-wrap;
	}

	&.MuiListItemText-primary {
		font-weight: bold;
	}

	// If you are using secondary text, style it here
	&.MuiListItemText-secondary {
		color: ${theme.palette.text.secondary}; // Lighter color for secondary text
	}
  `}
`;

export const BotListItemText = styled(ListItemText)`
	${({ theme }) => `
	&.MuiListItemText-root {
		color: ${theme.palette.text.primary}; // Replace with the specific text color you want
		word-wrap: break-word; // To ensure long words don't overflow
		white-space: pre-wrap;
	}
  `}
`;

export const HeaderTypography = styled(Typography)`
	${({ theme }) => `
	&.MuiTypography-root {
		color: ${theme.palette.text.secondary};
		font-size: 1rem;
		font-weight: 600;
		margin-left: 0.5rem;
	}
  `}
`;

export const ChatToggleButton = styled(Button)`
	${({ theme }) => `
	flex: 1;
	border-radius: 2rem;
	border: 1px solid ${theme.palette.divider};
	background: none;
	text-transform: none;
	color: ${theme.palette.text.primary};
	font-weight: 400;
	padding: 0.625rem 1.125rem;
	margin: 0.5rem;
	font-size: 0.875rem;

	// remove focus border color
	&:focus {
		border-color: ${theme.palette.divider};
	}

	// selected state
	&.selected {
		border-color: ${theme.palette.primary.main};
		color: ${theme.palette.primary.main};
	}
  `}
`;

export const StyledFieldTypography = styled(Typography)`
	${({ theme }) => `
	&.MuiTypography-root {
		color: ${theme.palette.text.primary};
		font-size: 1rem;
		font-weight: 400;
		margin: 1rem 0;
	}
  `}
`;

export const ChatButtonStyled = styled(Button)`
	${({ theme }) => `
	border-radius: 6.25rem;
	background: ${theme.palette.primary.main};
	color: ${theme.palette.primary.contrastText};
	margin-top: 10px;
	width: 35%;
	height: 50px;
	border: none;

	// change the contained style
	&.MuiButton-contained {
		background: ${theme.palette.primary.main};
		color: ${theme.palette.primary.contrastText};
		box-shadow: none;
	}

	// Responsive styling for desktop
	@media (min-width: 600px) {
		width: 30%;
	}
  `}
`;

export const ChatSliderStyled = styled(Slider)`
	${({ theme }) => `
	&.MuiSlider-root {
		color: ${theme.palette.primary.main};
	}

	// track and rail styles
	& .MuiSlider-track {
		color: ${theme.palette.primary.main};
	}
	& .MuiSlider-rail {
		color: ${theme.palette.primary.main};
	}
  `}
`;
