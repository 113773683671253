import React from "react";
import {
	Container,
	Typography,
	Button,
	Box,
	useTheme,
	useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import useCustomUserData from "../hooks/useCustomUserData";

const NotFoundPage: React.FC = () => {
	const { customUserData } = useCustomUserData();
	const navigate = useNavigate();
	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

	const handleFeedbackClick = () => {
		const email = "support@cockpit-ai.com"; // replace with your feedback email
		const subject = encodeURIComponent("User Feedback/Support Request");
		const body = encodeURIComponent(
			`User Details:\nName: ${customUserData?.name}\nEmail: ${customUserData?.email}\n\nFeedback/Support Request:`
		);
		const mailtoLink = `mailto:${email}?subject=${subject}&body=${body}`;

		const link = document.createElement("a");
		link.href = mailtoLink;
		link.target = "_blank"; // This line is added
		link.click();
	};

	return (
		<Container
			component="main"
			maxWidth="xs"
			sx={{
				textAlign: "center",
				paddingTop: "20%",
				paddingBottom: "20%",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
			}}
		>
			<Typography variant="h3" component="h1" gutterBottom>
				404
			</Typography>
			<Typography variant="h5">Whoops... Looks like you're lost</Typography>
			<Box marginTop={4}>
				<Button
					variant="contained"
					color="primary"
					onClick={() => navigate(-1)}
					sx={{ mr: 1 }}
				>
					Go Back
				</Button>
				<Button
					variant="outlined"
					sx={{
						mr: 1,
						borderColor: "#29273E",
						color: "primary.contrastText",
					}}
					onClick={handleFeedbackClick}
				>
					Contact Support
				</Button>
			</Box>
		</Container>
	);
};

export default NotFoundPage;
