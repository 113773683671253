import React, { useEffect, useState } from "react";
import {
	Box,
	CircularProgress,
	Container,
	IconButton,
	ImageList,
	ImageListItem,
	ImageListItemBar,
	Paper,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import {
	ChatButtonStyled,
	ChatItemContainer,
	HeaderTypography,
	StyledFieldTypography,
} from "../../../../ux/chatStyles";
import chatHeaderCloseIcon from "../../../../assets/chatHeaderClose.svg";
import createImageIcon from "../../../../assets/createImage.svg";
import { ImagePrompt } from "../../../../services/restService";
import ImageViewer from "./ImageViewer";

interface GenerateImageItemProps {
	prompt: ImagePrompt | null;
	generateImageBasedOnPrompt: (prompt: ImagePrompt) => Promise<any>;
	onImagesGenerated: (hasImages: boolean) => void;
	onClose: () => void;
}

const ImageGenerationFromPrompt: React.FC<GenerateImageItemProps> = ({
	prompt,
	generateImageBasedOnPrompt,
	onImagesGenerated,
	onClose,
}) => {
	const [askForImage, setAskForImage] = useState<boolean>(true); // Show the initial question
	const [imageLoading, setImageLoading] = useState<boolean>(false);
	const [b64JsonArray, setB64JsonArray] = useState<string[]>([]);
	const [userSatisfied, setUserSatisfied] = useState<boolean | null>(null);
	const [viewerOpen, setViewerOpen] = useState<boolean>(false);
	const [currentImage, setCurrentImage] = useState<string>("");

	const handleImageRequest = async () => {
		setImageLoading(true);
		if (!prompt) return;
		try {
			const imageResponse = await generateImageBasedOnPrompt(prompt);
			setB64JsonArray((prevImages) => [
				...prevImages,
				...imageResponse.images.data.map((item: any) => item.b64_json),
			]);
			onImagesGenerated(true);
			setUserSatisfied(null); // Reset satisfaction question for new images
		} catch (error) {
			console.error("Image request failed", error);
		} finally {
			setImageLoading(false);
			setAskForImage(false);
		}
	};

	useEffect(() => {
		handleImageRequest(); // Call this function on component mount to immediately start generating an image
	}, []);

	return (
		<ChatItemContainer id="chat-container">
			<Paper
				sx={{
					marginTop: "20px",
					marginBottom: "20px",
					// background: "#1c1a21",
					borderRadius: "0.75rem",
					padding: "20px",
				}}
			>
				<Container
					sx={{
						display: "flex",
						alignItems: "center",
						padding: "10px",
					}}
				>
					<img
						src={createImageIcon}
						alt="Create"
						style={{ marginBottom: "2px" }}
					/>
					<HeaderTypography
						variant="h6"
						sx={{ display: "flex", alignItems: "center" }}
					>
						Create an Image
					</HeaderTypography>
					<IconButton onClick={onClose} sx={{ marginLeft: "auto" }}>
						<img src={chatHeaderCloseIcon} alt="Minimize" />
					</IconButton>
				</Container>
				{b64JsonArray.length > 0 && (
					<Box mt={2}>
						<StyledFieldTypography gutterBottom>
							Here are the generated images:
						</StyledFieldTypography>
						<ImageList cols={3} gap={8}>
							{b64JsonArray.map((b64Json: string, index: number) => (
								<ImageListItem key={index}>
									<img
										src={`data:image/png;base64,${b64Json}`}
										alt={`Generated ${index + 1}`}
										loading="lazy"
										style={{ cursor: "pointer" }}
										onClick={() => {
											setCurrentImage(`data:image/png;base64,${b64Json}`);
											setViewerOpen(true);
										}}
									/>
									<ImageListItemBar
										position="top"
										actionIcon={
											<IconButton
												sx={{ color: "#DCF032" }}
												aria-label={`download image ${index + 1}`}
												href={`data:image/png;base64,${b64Json}`}
												download={`generated-image-${index + 1}.png`}
											>
												<DownloadIcon />
											</IconButton>
										}
										actionPosition="right"
									/>
								</ImageListItem>
							))}
						</ImageList>
					</Box>
				)}
				{!imageLoading && b64JsonArray.length > 0 && userSatisfied === null && (
					<Box mt={2}>
						<StyledFieldTypography gutterBottom>
							Are you satisfied with the generated images?
						</StyledFieldTypography>
						<ChatButtonStyled
							variant="contained"
							color="primary"
							onClick={() => setUserSatisfied(true)}
							disabled={userSatisfied !== null}
						>
							Yes
						</ChatButtonStyled>
						<ChatButtonStyled
							variant="contained"
							color="secondary"
							onClick={handleImageRequest}
							style={{ marginLeft: "10px" }}
							disabled={userSatisfied !== null}
						>
							No, generate another one
						</ChatButtonStyled>
					</Box>
				)}
				{imageLoading && <CircularProgress />}
				<ImageViewer
					open={viewerOpen}
					src={currentImage}
					onClose={() => setViewerOpen(false)}
				/>
			</Paper>
		</ChatItemContainer>
	);
};

export default ImageGenerationFromPrompt;
