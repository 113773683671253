import React, {
	useState,
	useEffect,
	useContext,
	useMemo,
	useCallback,
	useRef,
} from "react";
import {
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TextField,
	Button,
	TablePagination,
	Container,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Snackbar,
	Alert,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { bindActionCreators } from "../../actions/actionCreators";
import { AppContext } from "../../models/applicationState";
import { TodoContext } from "../todoContext";
import * as adminActions from "../../actions/adminActions";
import { useAuth } from "../../context/AuthContext";
import EditUserModal from "./EditUserModal";
import { StyledTableCell, StyledTableRow } from "../../ux/adminDashboardStyles";
import ConversationsTable from "./ConversationsTable";

export interface User {
	uid: string;
	provider: string;
	name: string;
	email: string;
	phone?: string;
	companyName?: string;
	roleOnTeam?: string;
	teamSize?: string;
	isProfileComplete?: boolean;
	createdDate?: Date;
	updatedDate?: Date;
	isOnWaitingList?: boolean;
	isSubscribed?: boolean;
	role?: string;
}

interface UserResponse {
	users: User[];
	totalUsers: number;
}

function useDebounce(func: (...args: any[]) => void, wait: number) {
	const timeoutRef = useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		return () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		};
	}, []);

	return useCallback(
		(...args: any[]) => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}

			timeoutRef.current = setTimeout(() => {
				func(...args);
			}, wait);
		},
		[func, wait]
	);
}

const UserList = () => {
	const [users, setUsers] = useState<User[]>([]);
	const [isExporting, setIsExporting] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");
	const [page, setPage] = useState(0); // Zero-based for Material-UI, but will be adjusted for API call
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [totalCount, setTotalCount] = useState(0);
	const [openModal, setOpenModal] = useState(false);
	const [userIdToDelete, setUserIdToDelete] = useState<string | null>(null);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");
	const [editingUser, setEditingUser] = useState<User | null>(null);
	const [selectedUserUid, setSelectedUserUid] = useState<string | null>(null);
	const [showConversations, setShowConversations] = useState(false);

	const { user } = useAuth();

	const appContext = useContext<AppContext>(TodoContext);
	const actions = useMemo(
		() => ({
			admin: bindActionCreators(
				adminActions,
				appContext.dispatch
			) as unknown as adminActions.AdminActions,
		}),
		[appContext.dispatch]
	);

	const fetchUsers = async () => {
		try {
			const queryOptions = {
				search: searchTerm,
				page: page + 1, // Adjust for one-based API indexing
				limit: rowsPerPage,
			};
			const response = (await actions.admin.getAllUsers(
				queryOptions
			)) as unknown as UserResponse;
			setUsers(response.users);
			setTotalCount(response.totalUsers);
		} catch (error) {
			console.error("Failed to fetch users:", error);
		}
	};

	const handleExportUsers = async () => {
		setIsExporting(true);
		try {
			const response = await actions.admin.exportUsersToCSV();

			// Check if the response is successful and contains data
			if (response.status === 200 && response.data) {
				const blob = new Blob([response.data], { type: "text/csv" });
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement("a");
				a.style.display = "none";
				a.href = url;
				a.download = "users_export.csv";
				document.body.appendChild(a);
				a.click();
				window.URL.revokeObjectURL(url);
				setSnackbarMessage("Users exported successfully");
				setSnackbarOpen(true);
			} else {
				throw new Error("Export failed: Unexpected response");
			}
		} catch (error) {
			console.error("Failed to export users:", error);
			setSnackbarMessage("Error exporting users");
			setSnackbarOpen(true);
		} finally {
			setIsExporting(false);
		}
	};

	const debouncedFetchUsers = useDebounce(fetchUsers, 300);

	useEffect(() => {
		debouncedFetchUsers();
	}, [searchTerm, page, rowsPerPage, user]);

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(event.target.value);
		setPage(0); // Reset to first page when search changes
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleDeleteUser = (event: React.MouseEvent, userId: string) => {
		event.stopPropagation(); // Prevents the row's onClick event from firing
		setUserIdToDelete(userId);
		setOpenModal(true);
	};

	const confirmDeleteUser = async () => {
		try {
			if (userIdToDelete) {
				await actions.admin.deleteUserByUid(userIdToDelete);
				setUsers(users.filter((user) => user.uid !== userIdToDelete));
				setOpenModal(false); // Close modal
				setUserIdToDelete(null); // Reset delete user ID

				// Set success message and open snackbar
				setSnackbarMessage("User deleted successfully");
				setSnackbarOpen(true);
			}
		} catch (error) {
			// Set error message and open snackbar
			setSnackbarMessage("Error deleting user");
			setSnackbarOpen(true);
		}
	};

	const handleCloseModal = () => {
		setOpenModal(false);
		setUserIdToDelete(null);
	};

	const handleEditUser = (event: React.MouseEvent, userId: string) => {
		event.stopPropagation(); // Prevents the row's onClick event from firing
		const userToEdit = users.find((user) => user.uid === userId);
		setEditingUser(userToEdit || null);
	};

	const handleSaveUser = async (updatedUser: User) => {
		try {
			// Assuming you have an API method to update user details
			await actions.admin.updateUserByUid(updatedUser.uid, updatedUser);
			// Update the users state with the updated user details
			setUsers(
				users.map((user) => (user.uid === updatedUser.uid ? updatedUser : user))
			);
			setEditingUser(null); // Close the modal
		} catch (error) {
			console.error("Failed to update user", error);
		}
	};

	const handleRowClick = (userUid: string) => {
		setSelectedUserUid(userUid);
		setShowConversations(true);
	};

	const handleCloseConversations = () => {
		setShowConversations(false);
		setSelectedUserUid(null);
	};

	if (showConversations) {
		return (
			<ConversationsTable
				userUid={selectedUserUid as string}
				onClose={handleCloseConversations}
			/>
		);
	}

	return (
		<>
			<Container>
				<TextField
					label="Search Users"
					value={searchTerm}
					onChange={handleSearchChange}
					style={{ margin: "20px" }}
				/>
				<Button
					variant="contained"
					color="primary"
					startIcon={<FileDownloadIcon />}
					onClick={handleExportUsers}
					disabled={isExporting}
					style={{ margin: "20px", float: "right" }}
				>
					{isExporting ? "Exporting..." : "Export to CSV"}
				</Button>
				<TableContainer>
					<Table
						stickyHeader
						aria-label="User table"
						style={{ minWidth: "650px" }}
					>
						<TableHead>
							<StyledTableRow key={user?.uid}>
								<StyledTableCell>Name</StyledTableCell>
								<StyledTableCell>Email</StyledTableCell>
								<StyledTableCell>Phone</StyledTableCell>
								<StyledTableCell>Company Name</StyledTableCell>
								<StyledTableCell>Role on Team</StyledTableCell>
								<StyledTableCell>User Role</StyledTableCell>
								<StyledTableCell>Actions</StyledTableCell>
							</StyledTableRow>
						</TableHead>
						<TableBody>
							{users.map((user) => (
								<StyledTableRow
									key={user.uid}
									onClick={() => handleRowClick(user?.uid || "")}
								>
									<StyledTableCell>{user.name}</StyledTableCell>
									<StyledTableCell>{user.email}</StyledTableCell>
									<StyledTableCell>{user.phone}</StyledTableCell>
									<StyledTableCell>{user.companyName}</StyledTableCell>
									<StyledTableCell>{user.roleOnTeam}</StyledTableCell>
									<StyledTableCell>{user.role}</StyledTableCell>
									<StyledTableCell>
										<Button
											onClick={(event) => handleEditUser(event, user.uid)}
										>
											<EditIcon />
										</Button>
										<Button
											onClick={(event) => handleDeleteUser(event, user.uid)}
										>
											<DeleteIcon style={{ color: "red" }} />
										</Button>
									</StyledTableCell>
								</StyledTableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					component="div"
					count={totalCount}
					page={page}
					onPageChange={handleChangePage}
					rowsPerPage={rowsPerPage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
				<Dialog
					open={openModal}
					onClose={handleCloseModal}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							Are you sure you want to delete this user?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleCloseModal}>Cancel</Button>
						<Button onClick={confirmDeleteUser} autoFocus>
							Confirm
						</Button>
					</DialogActions>
				</Dialog>
			</Container>
			<Snackbar
				open={snackbarOpen}
				autoHideDuration={6000}
				onClose={() => setSnackbarOpen(false)}
			>
				<Alert
					onClose={() => setSnackbarOpen(false)}
					severity={snackbarMessage.startsWith("Error") ? "error" : "success"}
				>
					{snackbarMessage}
				</Alert>
			</Snackbar>
			<EditUserModal
				open={!!editingUser}
				onClose={() => setEditingUser(null)}
				onSave={handleSaveUser}
				user={editingUser}
			/>
		</>
	);
};

export default UserList;
