import * as itemActions from "./itemActions";
import * as listActions from "./listActions";
import * as userActions from "./userActions";
import * as adminActions from "./adminActions";

export enum ActionTypes {
	LOAD_TODO_LISTS = "LOAD_TODO_LISTS",
	LOAD_TODO_LIST = "LOAD_TODO_LIST",
	SELECT_TODO_LIST = "SELECT_TODO_LIST",
	SAVE_TODO_LIST = "SAVE_TODO_LIST",
	DELETE_TODO_LIST = "DELETE_TODO_LIST",
	LOAD_TODO_ITEMS = "LOAD_TODO_ITEMS",
	LOAD_TODO_ITEM = "LOAD_TODO_ITEM",
	SELECT_TODO_ITEM = "SELECT_TODO_ITEM",
	SAVE_TODO_ITEM = "SAVE_TODO_ITEM",
	DELETE_TODO_ITEM = "DELETE_TODO_ITEM",
	SIGN_UP = "SIGN_UP",
	COMPLETE_SIGN_UP = "COMPLETE_SIGN_UP",
	GET_ME = "GET_ME",
	SAVE_ONBOARDING_DATA = "SAVE_ONBOARDING_DATA",
	GET_ONBOARDING_DATA = "GET_ONBOARDING_DATA",
	// chat
	CHAT_MESSAGE = "CHAT_MESSAGE",
	CHAT_MESSAGE_STREAM = "CHAT_MESSAGE_STREAM",
	UPLOAD_FILE = "UPLOAD_FILE",
	CREATE_SURVEY = "CREATE_SURVEY",
	CREATE_SURVEY_STREAM = "CREATE_SURVEY_STREAM",
	WRITE_BLOG_POST = "WRITE_BLOG_POST",
	WRITE_BLOG_POST_STREAM = "WRITE_BLO/G_POST_STREAM",
	WRITE_NEWSLETTER = "WRITE_NEWSLETTER",
	WRITE_NEWSLETTER_STREAM = "WRITE_NEWSLETTER_STREAM",
	CREATE_AD_CAMPAIGN = "CREATE_AD_CAMPAIGN",
	CREATE_AD_CAMPAIGN_STREAM = "CREATE_AD_CAMPAIGN_STREAM",
	CREATE_DOCUMENT = "CREATE_DOCUMENT",
	CREATE_DOCUMENT_STREAM = "CREATE_DOCUMENT_STREAM",
	CREATE_SLIDE_DECK = "CREATE_SLIDE_DECK",
	CREATE_SLIDE_DECK_STREAM = "CREATE_SLIDE_DECK_STREAM",
	CREATE_SOCIAL_CONTENT = "CREATE_SOCIAL_CONTENT",
	CREATE_SOCIAL_CONTENT_STREAM = "CREATE_SOCIAL_CONTENT_STREAM",
	GENERATE_IMAGE = "GENERATE_IMAGE",
	GENERATE_IMAGE_FROM_PROMPT = "GENERATE_IMAGE_FROM_PROMPT",
	WRITE_WEBSITE_COPY = "WRITE_WEBSITE_COPY",
	WRITE_WEBSITE_COPY_STREAM = "WRITE_WEBSITE_COPY_STREAM",
	// admin routes
	GET_USER_BY_UID = "GET_USER_BY_UID",
	GET_ALL_USERS = "GET_ALL_USERS",
	EXPORT_USERS_TO_CSV = "EXPORT_USERS_TO_CSV",
	DELETE_USER_BY_UID = "DELETE_USER_BY_UID",
	UPDATE_USER_BY_UID = "UPDATE_USER_BY_UID",
	CREATE_USER = "CREATE_USER",
	GET_WAITING_LIST = "GET_WAITING_LIST",
	APPROVE_USERS_FROM_WAITING_LIST = "APPROVE_USERS_FROM_WAITING_LIST",
	GET_ONBOARDING_DATA_BY_UID = "GET_ONBOARDING_DATA_BY_UID",
	EDIT_ONBOARDING_DATA_BY_UID = "EDIT_ONBOARDING_DATA_BY_UID",
	GET_CONVERSATIONS_BY_UID = "GET_CONVERSATIONS_BY_UID",
	LIST_MESSAGES = "LIST_MESSAGES",
	SEARCH_USERS_BY_EMAIL = "SEARCH_USERS_BY_EMAIL",
	// stripe routes
	CREATE_SUBSCRIPTION_CHECKOUT_SESSION = "CREATE_SUBSCRIPTION_CHECKOUT_SESSION",
}

export type TodoActions =
	| itemActions.ListItemsAction
	| itemActions.SelectItemAction
	| itemActions.LoadItemAction
	| itemActions.SaveItemAction
	| itemActions.DeleteItemAction
	| listActions.ListListsAction
	| listActions.SelectListAction
	| listActions.LoadListAction
	| listActions.SaveListAction
	| listActions.DeleteListAction
	| userActions.SignUpAction
	| userActions.CompleteSignUpAction
	| userActions.GetMeAction
	| userActions.SaveOnboardingDataAction
	| userActions.GetOnboardingDataAction
	| userActions.ChatMessageAction
	| userActions.UploadFileAction
	| userActions.CreateSurveyAction
	| userActions.WriteBlogPostAction
	| userActions.WriteNewsletterAction
	| userActions.CreateAdCampaignAction
	| userActions.CreateDocumentAction
	| userActions.CreateSlideDeckAction
	| userActions.CreateSocialContentAction
	| userActions.WriteWebsiteCopyAction
	| userActions.GenerateImageAction
	| userActions.GenerateImageFromPromptAction
	// stripe routes
	| userActions.CreateSubscriptionCheckoutSessionAction
	// admin routes
	| adminActions.GetUserByUidAction
	| adminActions.GetAllUsersAction
	| adminActions.ExportUsersToCSV
	| adminActions.DeleteUserByUidAction
	| adminActions.UpdateUserByUidAction
	| adminActions.CreateUserAction
	| adminActions.GetWaitingListAction
	| adminActions.ApproveUsersFromWaitingListAction
	| adminActions.GetOnboardingDataByUidAction
	| adminActions.EditOnboardingDataByUidAction
	| adminActions.GetConversationsByUidAction
	| adminActions.ListMessagesAction
	| adminActions.SearchUsersByEmailAction;
