import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import {
	TextField,
	FormGroup,
	Box,
	Paper,
	Container,
	IconButton,
	CircularProgress,
	Tooltip,
	useTheme,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ToggleButton } from "./components/ToggleButton";
import * as userActions from "../../../actions/userActions";
import {
	ChatButtonStyled,
	ChatItemContainer,
	HeaderTypography,
	StyledFieldTypography,
} from "../../../ux/chatStyles";
import chatHeaderCloseIcon from "../../../assets/chatHeaderClose.svg";
import createGoogleAdsIcon from "../../../assets/createGoogleAdsNew.svg";
import createGoogleAdsIconLight from "../../../assets/createGoogleAdsLight.svg";
import { bindActionCreators } from "../../../actions/actionCreators";
import { TodoContext } from "../../todoContext";
import MarkdownViewer from "../../../utils/MarkdownRenderer";
import { copyToClipboard, formatMessage } from "../../../utils/chatUtils";
import Loader from "../../loader";
import ImageGeneration from "./components/ImageGeneration";

interface CreateGoogleAdProps {
	onSendMessage: (message: any) => void;
	onClose: () => void;
}

const CreateGoogleAdItem: React.FC<CreateGoogleAdProps> = ({
	onSendMessage,
	onClose,
}) => {
	const { dispatch } = useContext(TodoContext);
	const [loading, setLoading] = useState<boolean>(false);
	const [campaignType, setCampaignType] = useState<string>(""); // Changed state to string
	const [topics, setTopics] = useState<string>("");
	const [toneOfVoice, setToneOfVoice] = useState<string[]>([]);
	const [toneOfVoiceOther, setToneOfVoiceOther] = useState<string>(""); // Added state for custom tone of voice
	const [additionalDetails, setAdditionalDetails] = useState<string>("");
	const [addCTA, setAddCTA] = useState<boolean>(false);
	const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
	const [response, setResponse] = useState<string | null>(null);
	const [copied, setCopied] = useState<boolean>(false);
	const [threadId, setThreadId] = useState<string>("");
	const [currentStreamId, setCurrentStreamId] = useState<string | null>(null);
	const [streamedResponse, setStreamedResponse] = useState<string>("");
	const responseRef = useRef<HTMLDivElement>(null);

	const theme = useTheme();
	const themeType = theme.palette.mode;

	const actions = useMemo(
		() => ({
			users: bindActionCreators(
				userActions,
				dispatch
			) as unknown as userActions.UserActions,
		}),
		[dispatch]
	);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const handleToneOfVoiceChange = (tone: string) => {
		if (tone === "Other") {
			// When "Other" is selected, just ensure it's in the array without toggling
			if (!toneOfVoice.includes(tone)) {
				setToneOfVoice([...toneOfVoice, tone]);
			}
		} else {
			setToneOfVoice(
				toneOfVoice.includes(tone)
					? toneOfVoice.filter((t) => t !== tone)
					: [...toneOfVoice, tone]
			);
		}
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setLoading(true);

		const finalToneOfVoice =
			toneOfVoice.includes("Other") && toneOfVoiceOther
				? [
						...toneOfVoice.filter((t) => t !== "Other"),
						`Other: ${toneOfVoiceOther}`,
						// eslint-disable-next-line no-mixed-spaces-and-tabs
				  ]
				: toneOfVoice;

		const adData = {
			campaignType,
			topics,
			toneOfVoice: finalToneOfVoice,
			additionalDetails,
			addCTA,
		};

		const streamId = Date.now().toString();
		setCurrentStreamId(streamId);

		try {
			await actions.users.createAdCampaignStream(
				adData,
				(chunk) => {
					console.log("Chunk received", chunk);
					setStreamedResponse((prev) => {
						const threadIdMatch = chunk.match(/threadId:([^\s]+)/);
						if (threadIdMatch) {
							setThreadId(threadIdMatch[1]);
							return prev + chunk.replace(/threadId:[^\s]+/, "");
						}
						return prev + chunk;
					});
					if (responseRef.current) {
						responseRef.current.scrollTop = responseRef.current.scrollHeight;
					}
				},
				() => {
					setIsSubmitted(true);
					setLoading(false);
					setCurrentStreamId(null);
				},
				(error) => {
					console.error("Submission failed", error);
					setLoading(false);
					setCurrentStreamId(null);
				}
			);
		} catch (error) {
			console.error("Failed to send ad campaign data", error);
			setLoading(false);
			setCurrentStreamId(null);
		}
	};

	const campaignTypes = [
		"Search Campaign",
		"YouTube Campaign",
		"Performance Max",
	];
	const toneOfVoiceOptions = [
		"Informative",
		"Inspirational",
		"Formal",
		"Persuasive",
		"Friendly",
		"Humorous",
		"Professional",
		"Other",
	];

	if (isSubmitted || streamedResponse) {
		return (
			<ChatItemContainer id="chat-container">
				<Paper
					style={{
						marginTop: "20px",
						marginBottom: "20px",
						borderRadius: "0.75rem",
						padding: "20px",
					}}
				>
					<Container
						sx={{ display: "flex", alignItems: "center", padding: "10px" }}
					>
						<img
							src={
								themeType === "dark"
									? createGoogleAdsIcon
									: createGoogleAdsIconLight
							}
							alt="Create Google Ad"
							style={{ marginBottom: "2px" }}
						/>
						<HeaderTypography
							variant="h6"
							sx={{ display: "flex", alignItems: "center" }}
						>
							Create Google Ad
						</HeaderTypography>
						<IconButton onClick={onClose} sx={{ marginLeft: "auto" }}>
							<img src={chatHeaderCloseIcon} alt="Minimize" />
						</IconButton>
					</Container>
					<div
						ref={responseRef}
						style={{
							color: "#FFF",
							wordWrap: "break-word",
							whiteSpace: "pre-wrap",
							display: "flex",
							alignItems: "flex-start",
							maxHeight: "70vh",
							overflowY: "auto",
						}}
					>
						<MarkdownViewer markdownText={formatMessage(streamedResponse)} />
						<Tooltip title="Copy">
							<IconButton
								onClick={() => copyToClipboard(streamedResponse, setCopied)}
								size="small"
							>
								{copied ? (
									<CheckCircleIcon
										style={{ color: theme.palette.success.main }}
									/>
								) : (
									<ContentCopyIcon
										style={{ color: theme.palette.primary.main }}
									/>
								)}
							</IconButton>
						</Tooltip>
					</div>
					<ImageGeneration
						threadId={threadId}
						platform={campaignType}
						contentDetail={"ad"}
						assistantId="createSocialMediaContentAssistant"
						generateImageBasedOnThread={
							actions.users.generateImageBasedOnThread
						}
					/>
				</Paper>
			</ChatItemContainer>
		);
	}

	return (
		<ChatItemContainer id="chat-container">
			<Paper
				style={{
					marginTop: "20px",
					marginBottom: "20px",
					borderRadius: "0.75rem",
					padding: "20px",
				}}
			>
				<Container
					sx={{
						display: "flex",
						alignItems: "center",
						padding: "10px",
					}}
				>
					<img
						src={
							themeType === "dark"
								? createGoogleAdsIcon
								: createGoogleAdsIconLight
						}
						alt="Create Google Ad"
						style={{ marginBottom: "2px" }}
					/>
					<HeaderTypography
						variant="h6"
						sx={{ display: "flex", alignItems: "center" }}
					>
						Create Google Ad
					</HeaderTypography>
					<IconButton onClick={onClose} sx={{ marginLeft: "auto" }}>
						<img src={chatHeaderCloseIcon} alt="Minimize" />
					</IconButton>
				</Container>
				{loading ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							width: "100%",
							padding: "20px",
						}}
					>
						<Loader />
					</div>
				) : (
					<>
						<FormGroup>
							<Box>
								<StyledFieldTypography gutterBottom>
									Which type of campaign?
								</StyledFieldTypography>
								{campaignTypes.map((type) => (
									<ToggleButton
										key={type}
										selected={campaignType.includes(type)}
										onClick={() =>
											setCampaignType(campaignType === type ? "" : type)
										}
									>
										{type}
									</ToggleButton>
								))}
							</Box>

							<Box mt={2}>
								<StyledFieldTypography gutterBottom>
									Which topics would you like to include?
								</StyledFieldTypography>
								<TextField
									label="Topics"
									variant="outlined"
									value={topics}
									onChange={(e) => setTopics(e.target.value)}
									sx={{ mt: 2, width: "100%" }}
								/>
							</Box>

							<Box mt={2}>
								<StyledFieldTypography gutterBottom>
									What should be the tone of voice?
								</StyledFieldTypography>
								{toneOfVoiceOptions.map((tone) => (
									<ToggleButton
										key={tone}
										selected={toneOfVoice.includes(tone)}
										onClick={() => handleToneOfVoiceChange(tone)}
									>
										{tone}
									</ToggleButton>
								))}
								{toneOfVoice.includes("Other") && (
									<TextField
										label="Please specify"
										variant="outlined"
										value={toneOfVoiceOther}
										onChange={(e) => setToneOfVoiceOther(e.target.value)}
										sx={{ mt: 2, width: "100%" }}
									/>
								)}
							</Box>

							<Box mt={2}>
								<StyledFieldTypography gutterBottom>
									Any other details to consider? Time frame, offer, value, etc.
								</StyledFieldTypography>
								<TextField
									label="Additional Details"
									variant="outlined"
									value={additionalDetails}
									onChange={(e) => setAdditionalDetails(e.target.value)}
									multiline
									rows={2}
									sx={{ mt: 2, width: "100%" }}
								/>
							</Box>

							<Box mt={2}>
								<StyledFieldTypography gutterBottom>
									Should we add a CTA?
								</StyledFieldTypography>
								<ToggleButton selected={addCTA} onClick={() => setAddCTA(true)}>
									Yes
								</ToggleButton>
								<ToggleButton
									selected={!addCTA}
									onClick={() => setAddCTA(false)}
								>
									No
								</ToggleButton>
							</Box>
						</FormGroup>
						<Box mt={2}>
							<ChatButtonStyled
								variant="contained"
								onClick={handleSubmit}
								disabled={loading}
							>
								{loading ? <CircularProgress size={24} /> : "Submit"}
							</ChatButtonStyled>
						</Box>
					</>
				)}
			</Paper>
		</ChatItemContainer>
	);
};

export default CreateGoogleAdItem;
