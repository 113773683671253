import React from "react";
import { Typography, Button, Container, Box } from "@mui/material";
// import cancelImage from "../assets/cancel.png";

const Cancel = () => {
	return (
		<Box
			sx={{
				maxWidth: 400,
				mx: "auto",
				textAlign: "center",
				padding: 4,
			}}
		>
			<Typography variant="h4" sx={{ mb: 4, textAlign: "left" }}>
				Whoops... Something went wrong. Please try again or contact support.
			</Typography>
			<Box sx={{ display: "flex", justifyContent: "flex-start", mt: 5 }}>
				<Button
					variant="outlined"
					href="/"
					sx={{
						mr: 1,
						borderColor: "#29273E",
						color: "primary.contrastText",
					}}
				>
					Back
				</Button>
			</Box>
		</Box>
	);
};

export default Cancel;
