import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import {
	TextField,
	Slider,
	FormGroup,
	Box,
	Paper,
	Container,
	IconButton,
	CircularProgress,
	Tooltip,
	useTheme,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import * as userActions from "../../../actions/userActions";
import {
	ChatButtonStyled,
	ChatItemContainer,
	HeaderTypography,
	StyledFieldTypography,
} from "../../../ux/chatStyles";
import chatHeaderCloseIcon from "../../../assets/chatHeaderClose.svg";
import writeASlideDeckIcon from "../../../assets/createASlideDeckNew.svg";
import writeASlideDeckIconLight from "../../../assets/createASlideDeckLight.svg";
import { bindActionCreators } from "../../../actions/actionCreators";
import { TodoContext } from "../../todoContext";
import { ToggleButton } from "./components/ToggleButton";
import { copyToClipboard, formatMessage } from "../../../utils/chatUtils";
import MarkdownViewer from "../../../utils/MarkdownRenderer";
import Loader from "../../loader";

interface CreateSlideDeckItemProps {
	onSendMessage: (message: any) => void;
	onClose: () => void;
}

const CreateSlideDeckItem: React.FC<CreateSlideDeckItemProps> = ({
	onSendMessage,
	onClose,
}) => {
	const { dispatch } = useContext(TodoContext);
	const [loading, setLoading] = useState<boolean>(false);
	const [deckType, setDeckType] = useState<string[]>([]);
	const [deckTypeOther, setDeckTypeOther] = useState<string>(""); // New state for "Other" deck type
	const [slideCount, setSlideCount] = useState<number>(3);
	const [topics, setTopics] = useState<string>("");
	const [toneOfVoice, setToneOfVoice] = useState<string[]>([]);
	const [toneOfVoiceOther, setToneOfVoiceOther] = useState<string>("");
	const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
	const [response, setResponse] = useState<string | null>(null);
	const [copied, setCopied] = useState<boolean>(false);
	const [cta, setCta] = useState<boolean>(false);
	const [threadId, setThreadId] = useState<string>("");
	const [currentStreamId, setCurrentStreamId] = useState<string | null>(null);
	const [streamedResponse, setStreamedResponse] = useState<string>("");
	const responseRef = useRef<HTMLDivElement>(null);

	const theme = useTheme();
	const themeType = theme.palette.mode;

	const actions = useMemo(
		() => ({
			users: bindActionCreators(
				userActions,
				dispatch
			) as unknown as userActions.UserActions,
		}),
		[dispatch]
	);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const handleDeckTypeChange = (value: string) => {
		setDeckType([value]);
		if (value === "Other") {
			// Ensure to handle "Other" selection for showing input field
		} else {
			setDeckTypeOther(""); // Clear the custom deck type if "Other" is not selected
		}
	};

	const handleSelectionChange = (
		value: string,
		currentValues: string[],
		setValues: React.Dispatch<React.SetStateAction<string[]>>,
		setOtherValue?: React.Dispatch<React.SetStateAction<string>>
	) => {
		if (currentValues.includes(value)) {
			setValues(currentValues.filter((item) => item !== value));
			if (value === "Other") {
				setOtherValue && setOtherValue("");
			}
		} else {
			setValues([...currentValues, value]);
		}
	};

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setLoading(true);

		const finalDeckType =
			deckType.includes("Other") && deckTypeOther ? deckTypeOther : deckType[0];

		const slideDeckData = {
			deckType: finalDeckType,
			slideCount,
			topics,
			toneOfVoice:
				toneOfVoice.includes("Other") && toneOfVoiceOther
					? [
							...toneOfVoice.filter((tone) => tone !== "Other"),
							`Other: ${toneOfVoiceOther}`,
							// eslint-disable-next-line no-mixed-spaces-and-tabs
					  ]
					: toneOfVoice,
			cta,
		};

		const streamId = Date.now().toString();
		setCurrentStreamId(streamId);

		try {
			await actions.users.createSlideDeckStream(
				slideDeckData,
				(chunk) => {
					console.log("Chunk received", chunk);
					setStreamedResponse((prev) => {
						const threadIdMatch = chunk.match(/threadId:([^\s]+)/);
						if (threadIdMatch) {
							setThreadId(threadIdMatch[1]);
							return prev + chunk.replace(/threadId:[^\s]+/, "");
						}
						return prev + chunk;
					});
					if (responseRef.current) {
						responseRef.current.scrollTop = responseRef.current.scrollHeight;
					}
				},
				() => {
					setIsSubmitted(true);
					setLoading(false);
					setCurrentStreamId(null);
				},
				(error) => {
					console.error("Submission failed", error);
					setLoading(false);
					setCurrentStreamId(null);
				}
			);
		} catch (error) {
			console.error("Failed to send slide deck data", error);
			setLoading(false);
			setCurrentStreamId(null);
		}
	};

	const deckTypes = [
		"Sales Deck",
		"Client Onboarding",
		"Company Overview",
		"Product Demo",
		"Project Proposal",
		"Other",
	];

	const toneOfVoiceOptions = [
		"Informative",
		"Inspirational",
		"Formal",
		"Persuasive",
		"Friendly",
		"Humorous",
		"Professional",
		"Other",
	];

	if (isSubmitted || streamedResponse) {
		return (
			<ChatItemContainer id="chat-container">
				<Paper
					style={{
						marginTop: "20px",
						marginBottom: "20px",
						borderRadius: "0.75rem",
						padding: "20px",
					}}
				>
					<Container
						sx={{ display: "flex", alignItems: "center", padding: "10px" }}
					>
						<img
							src={
								themeType === "dark"
									? writeASlideDeckIcon
									: writeASlideDeckIconLight
							}
							alt="Create Slide Deck"
							style={{ marginBottom: "2px" }}
						/>
						<HeaderTypography
							variant="h6"
							sx={{ display: "flex", alignItems: "center" }}
						>
							Slide Deck Submission
						</HeaderTypography>
						<IconButton onClick={onClose} sx={{ marginLeft: "auto" }}>
							<img src={chatHeaderCloseIcon} alt="Minimize" />
						</IconButton>
					</Container>
					<div
						ref={responseRef}
						style={{
							color: "#FFF",
							wordWrap: "break-word",
							whiteSpace: "pre-wrap",
							display: "flex",
							alignItems: "flex-start",
							maxHeight: "70vh",
							overflowY: "auto",
						}}
					>
						<MarkdownViewer markdownText={formatMessage(streamedResponse)} />
						<Tooltip title="Copy">
							<IconButton
								onClick={() => copyToClipboard(streamedResponse, setCopied)}
								size="small"
							>
								{copied ? (
									<CheckCircleIcon
										style={{ color: theme.palette.success.main }}
									/>
								) : (
									<ContentCopyIcon
										style={{ color: theme.palette.primary.main }}
									/>
								)}
							</IconButton>
						</Tooltip>
					</div>
				</Paper>
			</ChatItemContainer>
		);
	}

	return (
		<ChatItemContainer id="chat-container">
			<Paper
				style={{
					marginTop: "20px",
					marginBottom: "20px",
					borderRadius: "0.75rem",
					padding: "20px",
				}}
			>
				<Container
					sx={{
						display: "flex",
						alignItems: "center",
						padding: "10px",
					}}
				>
					<img
						src={
							themeType === "dark"
								? writeASlideDeckIcon
								: writeASlideDeckIconLight
						}
						alt="Create Slide Deck"
						style={{ marginBottom: "2px" }}
					/>
					<HeaderTypography
						variant="h6"
						sx={{ display: "flex", alignItems: "center" }}
					>
						Create a Slide Deck
					</HeaderTypography>
					<IconButton onClick={onClose} sx={{ marginLeft: "auto" }}>
						<img src={chatHeaderCloseIcon} alt="Minimize" />
					</IconButton>
				</Container>
				{loading ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							width: "100%",
							padding: "20px",
						}}
					>
						<Loader />
					</div>
				) : (
					<FormGroup onSubmit={handleSubmit}>
						<Box>
							<StyledFieldTypography gutterBottom>
								Which type of deck?
							</StyledFieldTypography>
							{deckTypes.map((type) => (
								<ToggleButton
									key={type}
									selected={deckType.includes(type)}
									onClick={() => handleDeckTypeChange(type)}
								>
									{type}
								</ToggleButton>
							))}
							{deckType.includes("Other") && (
								<Box mt={2}>
									<StyledFieldTypography gutterBottom>
										Please specify the type of deck
									</StyledFieldTypography>
									<TextField
										label="Deck type"
										variant="outlined"
										value={deckTypeOther}
										onChange={(e) => setDeckTypeOther(e.target.value)}
										fullWidth
									/>
								</Box>
							)}
						</Box>
						<Box mt={2}>
							<StyledFieldTypography gutterBottom>
								How many slides?
							</StyledFieldTypography>
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<Slider
									aria-label="Slide Count"
									value={slideCount}
									onChange={(_, newValue) => setSlideCount(newValue as number)}
									step={1}
									marks
									min={1}
									max={30}
									valueLabelDisplay="auto"
									color="secondary"
								/>
								<Box
									sx={{
										minWidth: 55,
										marginLeft: 2,
										textAlign: "center",
										color: "#fff",
									}}
									// make it not selectable or clickable
									style={{ pointerEvents: "none" }}
								>
									<TextField
										id="slide-count"
										value={slideCount}
										InputProps={{
											readOnly: true,
											style: { textAlign: "center" },
										}}
										variant="outlined"
										size="small"
										style={{ maxWidth: "6.2em" }}
										sx={{
											marginLeft: "auto",
											marginRight: "auto",
											border: "1px solid #938F99",
											borderRadius: "0.5rem",
											input: { textAlign: "center", marginTop: "0.1rem" },
										}}
										// make is not selectable or clickable
										inputProps={{ style: { pointerEvents: "none" } }}
									/>
								</Box>
							</Box>
						</Box>

						<Box mt={2}>
							<StyledFieldTypography gutterBottom>
								Which topics would you like to include?
							</StyledFieldTypography>
							<TextField
								label="Please specify"
								variant="outlined"
								value={topics}
								onChange={(e) => setTopics(e.target.value)}
								fullWidth
								multiline
								rows={2}
							/>
						</Box>

						<Box mt={2}>
							<StyledFieldTypography gutterBottom>
								What should be the tone of voice?
							</StyledFieldTypography>
							{toneOfVoiceOptions.map((tone) => (
								<ToggleButton
									key={tone}
									selected={toneOfVoice.includes(tone)}
									onClick={() =>
										handleSelectionChange(
											tone,
											toneOfVoice,
											setToneOfVoice,
											setToneOfVoiceOther
										)
									}
								>
									{tone}
								</ToggleButton>
							))}
							{toneOfVoice.includes("Other") && (
								<div>
									<StyledFieldTypography gutterBottom>
										Please specify the tone of voice
									</StyledFieldTypography>
									<TextField
										label="Please specify"
										variant="outlined"
										value={toneOfVoiceOther}
										onChange={(e) => setToneOfVoiceOther(e.target.value)}
										sx={{ mt: 2, width: "100%" }}
									/>
								</div>
							)}
						</Box>

						<Box mt={2}>
							<StyledFieldTypography gutterBottom>
								Should we add a CTA?
							</StyledFieldTypography>
							<ToggleButton
								selected={cta === true}
								onClick={() => setCta(true)}
							>
								Yes
							</ToggleButton>
							<ToggleButton
								selected={cta === false}
								onClick={() => setCta(false)}
							>
								No
							</ToggleButton>
						</Box>

						<Box mt={2}>
							<ChatButtonStyled
								type="submit"
								variant="contained"
								onClick={handleSubmit}
								disabled={loading}
							>
								{loading ? <CircularProgress size={24} /> : "Submit"}
							</ChatButtonStyled>
						</Box>
					</FormGroup>
				)}
			</Paper>
		</ChatItemContainer>
	);
};

export default CreateSlideDeckItem;
