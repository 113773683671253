import React from "react";
import FreeTextItem, { FreeTextItemProps } from "../freeText";

const FreeTextWrapper: React.FC<FreeTextItemProps> = (props) => {
	return (
		// <>
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				height: "fit-content",
				width: "100%",
			}}
		>
			<FreeTextItem {...props} />
		</div>
		// </>
	);
};

export default FreeTextWrapper;
