// CompleteSignUpComponent.tsx
import React, { useContext } from "react";
import { useTheme } from "@mui/material/styles";
import {
	PostSignupContext,
	PostSignupProvider,
} from "../context/PostSingupContext";
import { PostSignupStepOne } from "./postSingup/PostSignupStepOne";
import { PostSignupStepTwo } from "./postSingup/PostSignupStepTwo";
// import { PostSignupStepThree } from "./postSingup/PostSignupStepThree";
import { PostSignupStepThree } from "./postSingup/PostSignupStepThree";
import { PostSignupStepFive } from "./postSingup/PostSignupStepFive";

const CompleteSignUpComponent = () => {
	const theme = useTheme();
	return (
		<div
			style={{
				paddingBottom: "1rem",
				width: "100%",
				backgroundColor: theme.palette.background.default,
			}}
		>
			<PostSignupProvider>
				<SignUpSteps />
			</PostSignupProvider>
		</div>
	);
};

const SignUpSteps = () => {
	const { currentStep } = useContext(PostSignupContext);
	console.log(currentStep);

	return (
		<>
			{currentStep === 1 && <PostSignupStepOne />}
			{currentStep === 2 && <PostSignupStepTwo />}
			{/* {currentStep === 3 && <PostSignupStepThree />} */}
			{currentStep === 3 && <PostSignupStepThree />}
			{currentStep === 5 && <PostSignupStepFive />}
		</>
	);
};

export default CompleteSignUpComponent;
