import React, { useContext, useEffect, useMemo, useState, useRef } from "react";
import {
	TextField,
	Slider,
	FormGroup,
	Box,
	Paper,
	Container,
	IconButton,
	Tooltip,
	CircularProgress,
	useTheme,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import * as userActions from "../../../actions/userActions";
import {
	ChatButtonStyled,
	ChatItemContainer,
	HeaderTypography,
	StyledFieldTypography,
} from "../../../ux/chatStyles";
import chatHeaderCloseIcon from "../../../assets/chatHeaderClose.svg";
import createDocumentsIcon from "../../../assets/createDocumentsNew.svg";
import createDocumentsIconLight from "../../../assets/createDocumentsLight.svg";
import { bindActionCreators } from "../../../actions/actionCreators";
import { TodoContext } from "../../todoContext";
import MarkdownViewer from "../../../utils/MarkdownRenderer";
import { ToggleButton } from "./components/ToggleButton";
import { copyToClipboard, formatMessage } from "../../../utils/chatUtils";
import Loader from "../../loader";

interface CreateDocumentItemProps {
	onSendMessage: (message: any) => void;
	onClose: () => void;
}

const CreateDocumentItem: React.FC<CreateDocumentItemProps> = ({
	onSendMessage,
	onClose,
}) => {
	const { dispatch } = useContext(TodoContext);
	const [loading, setLoading] = useState<boolean>(false);
	const [documentType, setDocumentType] = useState<string>("One Pager");
	const [topics, setTopics] = useState<string>("");
	const [wordCount, setWordCount] = useState<number>(400);
	const [toneOfVoice, setToneOfVoice] = useState<string[]>([]);
	const [toneOfVoiceOther, setToneOfVoiceOther] = useState<string>("");
	const [additionalDetails, setAdditionalDetails] = useState<string>("");
	const [includeCTA, setIncludeCTA] = useState<boolean>(false);
	const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
	const [response, setResponse] = useState<string | null>(null);
	const [copied, setCopied] = useState<boolean>(false);
	const [currentStreamId, setCurrentStreamId] = useState<string | null>(null);
	const [streamedResponse, setStreamedResponse] = useState<string>("");
	const responseRef = useRef<HTMLDivElement>(null);

	const theme = useTheme();
	const themeType = theme.palette.mode;

	const actions = useMemo(
		() => ({
			users: bindActionCreators(
				userActions,
				dispatch
			) as unknown as userActions.UserActions,
		}),
		[dispatch]
	);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	// Handle document type selection
	const handleDocumentTypeChange = (type: string) => {
		setDocumentType(type);
	};

	const handleSelectionChange = (
		value: string,
		currentValues: string[],
		setValues: React.Dispatch<React.SetStateAction<string[]>>,
		setOtherValue?: React.Dispatch<React.SetStateAction<string>>
	) => {
		if (currentValues.includes(value)) {
			setValues(currentValues.filter((item) => item !== value));
			if (value === "Other") {
				setOtherValue && setOtherValue("");
			}
		} else {
			setValues([...currentValues, value]);
		}
	};

	// Form submission function
	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setLoading(true);

		const documentData = {
			documentType,
			topics,
			wordCount,
			toneOfVoice: toneOfVoiceOther
				? [...toneOfVoice, `Other: ${toneOfVoiceOther}`]
				: toneOfVoice,
			additionalDetails,
			includeCTA,
		};

		const streamId = Date.now().toString();
		setCurrentStreamId(streamId);

		try {
			await actions.users.createDocumentStream(
				documentData,
				(chunk) => {
					console.log("Chunk received", chunk);
					setStreamedResponse((prev) => {
						const threadIdMatch = chunk.match(/threadId:([^\s]+)/);
						if (threadIdMatch) {
							// setThreadId(threadIdMatch[1]);
							return prev + chunk.replace(/threadId:[^\s]+/, "");
						}
						return prev + chunk;
					});
					if (responseRef.current) {
						responseRef.current.scrollTop = responseRef.current.scrollHeight;
					}
				},
				() => {
					setIsSubmitted(true);
					setLoading(false);
					setCurrentStreamId(null);
				},
				(error) => {
					console.error("Submission failed", error);
					setLoading(false);
					setCurrentStreamId(null);
				}
			);
		} catch (error) {
			console.error("Failed to send document data", error);
			setLoading(false);
			setCurrentStreamId(null);
		}
	};

	const documentTypes = ["One Pager", "eBook", "White Paper"];
	const toneOfVoiceOptions = [
		"Informative",
		"Inspirational",
		"Formal",
		"Persuasive",
		"Friendly",
		"Humorous",
		"Professional",
		"Other",
	];

	if (isSubmitted || streamedResponse) {
		return (
			<ChatItemContainer id="chat-container">
				<Paper
					style={{
						marginTop: "20px",
						marginBottom: "20px",
						borderRadius: "0.75rem",
						padding: "20px",
					}}
				>
					<Container
						sx={{ display: "flex", alignItems: "center", padding: "10px" }}
					>
						<img
							src={
								themeType === "dark"
									? createDocumentsIcon
									: createDocumentsIconLight
							}
							alt="Create Document"
							style={{ marginBottom: "2px" }}
						/>
						<HeaderTypography
							variant="h6"
							sx={{ display: "flex", alignItems: "center" }}
						>
							Document Creation
						</HeaderTypography>
						<IconButton onClick={onClose} sx={{ marginLeft: "auto" }}>
							<img src={chatHeaderCloseIcon} alt="Minimize" />
						</IconButton>
					</Container>
					<div
						ref={responseRef}
						style={{
							color: "#FFF",
							wordWrap: "break-word",
							whiteSpace: "pre-wrap",
							display: "flex",
							alignItems: "flex-start",
							maxHeight: "70vh",
							overflowY: "auto",
						}}
					>
						<MarkdownViewer markdownText={formatMessage(streamedResponse)} />
						<Tooltip title="Copy">
							<IconButton
								onClick={() => copyToClipboard(streamedResponse, setCopied)}
								size="small"
							>
								{copied ? (
									<CheckCircleIcon
										style={{ color: theme.palette.success.main }}
									/>
								) : (
									<ContentCopyIcon
										style={{ color: theme.palette.primary.main }}
									/>
								)}
							</IconButton>
						</Tooltip>
					</div>
				</Paper>
			</ChatItemContainer>
		);
	}

	return (
		<ChatItemContainer id="chat-container">
			<Paper
				style={{
					marginTop: "20px",
					marginBottom: "20px",
					borderRadius: "0.75rem",
					padding: "20px",
				}}
			>
				<Container
					sx={{
						display: "flex",
						alignItems: "center",
						padding: "10px",
					}}
				>
					<img
						src={
							themeType === "dark"
								? createDocumentsIcon
								: createDocumentsIconLight
						}
						alt="Create Document"
						style={{ marginBottom: "2px" }}
					/>
					<HeaderTypography
						variant="h6"
						sx={{ display: "flex", alignItems: "center" }}
					>
						Create a Document
					</HeaderTypography>
					<IconButton onClick={onClose} sx={{ marginLeft: "auto" }}>
						<img src={chatHeaderCloseIcon} alt="Minimize" />
					</IconButton>
				</Container>
				{loading ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							width: "100%",
							padding: "20px",
						}}
					>
						<Loader />
					</div>
				) : (
					<FormGroup onSubmit={handleSubmit}>
						<Box>
							<StyledFieldTypography gutterBottom>
								Which type of Document?
							</StyledFieldTypography>
							{documentTypes.map((type) => (
								<ToggleButton
									key={type}
									selected={documentType === type}
									onClick={() => handleDocumentTypeChange(type)}
								>
									{type}
								</ToggleButton>
							))}
						</Box>
						<Box mt={2}>
							<StyledFieldTypography gutterBottom>
								Which topics would you like to include?
							</StyledFieldTypography>
							<TextField
								fullWidth
								label="Topics"
								variant="outlined"
								value={topics}
								onChange={(e) => setTopics(e.target.value)}
								sx={{ mt: 2 }}
							/>
						</Box>

						<Box mt={2}>
							<StyledFieldTypography gutterBottom>
								How long should the document be?
							</StyledFieldTypography>
							<Box sx={{ display: "flex", alignItems: "center" }}>
								<Slider
									aria-label="Word Count"
									value={wordCount}
									onChange={(_, newValue) => setWordCount(newValue as number)}
									step={100}
									marks
									min={100}
									max={2000}
									valueLabelDisplay="auto"
								/>
								<Box
									sx={{
										minWidth: 55,
										marginLeft: 2,
										textAlign: "center",
										color: "#fff",
									}}
									style={{ pointerEvents: "none" }}
								>
									<TextField
										id="word-count"
										value={wordCount}
										InputProps={{
											readOnly: true,
											style: { textAlign: "center" },
										}}
										variant="outlined"
										size="small"
										style={{ maxWidth: "6.2em" }}
										sx={{
											marginLeft: "auto",
											marginRight: "auto",
											border: "1px solid #938F99",
											borderRadius: "0.5rem",
											input: { textAlign: "center", marginTop: "0.1rem" },
										}}
										inputProps={{ style: { pointerEvents: "none" } }}
									/>
								</Box>
							</Box>
						</Box>

						<Box mt={2}>
							<StyledFieldTypography gutterBottom>
								What should be the tone of voice?
							</StyledFieldTypography>
							{toneOfVoiceOptions.map((tone) => (
								<ToggleButton
									key={tone}
									selected={toneOfVoice.includes(tone)}
									onClick={() =>
										handleSelectionChange(
											tone,
											toneOfVoice,
											setToneOfVoice,
											setToneOfVoiceOther
										)
									}
								>
									{tone}
								</ToggleButton>
							))}
							{toneOfVoice.includes("Other") && (
								<div>
									<StyledFieldTypography gutterBottom>
										Please specify the tone of voice
									</StyledFieldTypography>
									<TextField
										fullWidth
										label="Please specify"
										variant="outlined"
										value={toneOfVoiceOther}
										onChange={(e) => setToneOfVoiceOther(e.target.value)}
										sx={{ mt: 2 }}
									/>
								</div>
							)}
						</Box>

						<Box mt={2}>
							<StyledFieldTypography gutterBottom>
								Any other details to consider? Time frame, offer, value, etc.
							</StyledFieldTypography>
							<TextField
								fullWidth
								label="Additional Details"
								variant="outlined"
								value={additionalDetails}
								onChange={(e) => setAdditionalDetails(e.target.value)}
								sx={{ mt: 2 }}
							/>
						</Box>

						<Box mt={2}>
							<StyledFieldTypography gutterBottom>
								Should we add a CTA?
							</StyledFieldTypography>
							<ToggleButton
								selected={includeCTA}
								onClick={() => setIncludeCTA(true)}
							>
								Yes
							</ToggleButton>
							<ToggleButton
								selected={!includeCTA}
								onClick={() => setIncludeCTA(false)}
							>
								No
							</ToggleButton>
						</Box>

						<Box mt={2}>
							<ChatButtonStyled
								variant="contained"
								onClick={handleSubmit}
								disabled={loading}
							>
								{loading ? <CircularProgress size={24} /> : "Submit"}
							</ChatButtonStyled>
						</Box>
					</FormGroup>
				)}
			</Paper>
		</ChatItemContainer>
	);
};

export default CreateDocumentItem;
