import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { TodoContext } from "../components/todoContext";
import { bindActionCreators } from "../actions/actionCreators";
import * as userActions from "../actions/userActions";
import { AppContext } from "../models/applicationState";
import { useAuth } from "./AuthContext";

type FormState = {
	logo: string;
	aboutBrand: string;
	businessGoals: string;
	competitor1: string;
	competitor2: string;
	competitor3: string;
	idealCustomer: string;
	facebook: string;
	instagram: string;
	tiktok: string;
	linkedin: string;
	pinterest: string;
	youtube: string;
	x: string;
	expansionPlan: string;
	flagshipProductsServices: string;
	leastProfitableOfferings: string;
	primaryTypeface: string;
	secondaryTypeface: string;
	primaryColor: string;
	secondaryColor: string;
	tertiaryColor: string;

	// Add other fields as necessary
};

const defaultValue: FormState = {
	// Set default values for other fields
	logo: "",
	aboutBrand: "",
	businessGoals: "",
	competitor1: "",
	competitor2: "",
	competitor3: "",
	idealCustomer: "",
	facebook: "",
	instagram: "",
	tiktok: "",
	linkedin: "",
	pinterest: "",
	youtube: "",
	x: "",
	expansionPlan: "",
	flagshipProductsServices: "",
	leastProfitableOfferings: "",
	primaryTypeface: "",
	secondaryTypeface: "",
	primaryColor: "",
	secondaryColor: "",
	tertiaryColor: "",
};

export const FormStateContext = createContext<
	[FormState, React.Dispatch<React.SetStateAction<FormState>>]
>([
	defaultValue,
	() => {
		// Do nothing
	},
]);

export function FormProvider({ children }: { children: React.ReactNode }) {
	const [formState, setFormState] = useState<FormState>(defaultValue);
	const value: [FormState, React.Dispatch<React.SetStateAction<FormState>>] = [
		formState,
		setFormState,
	];
	const { user } = useAuth();

	const appContext = useContext<AppContext>(TodoContext);
	const actions = useMemo(
		() => ({
			users: bindActionCreators(
				userActions,
				appContext.dispatch
			) as unknown as userActions.UserActions,
		}),
		[appContext.dispatch]
	);

	useEffect(() => {
		// Function to fetch data from the server
		async function fetchData() {
			try {
				const response = await actions.users.getOnboardingData();
				if (response) {
					const updatedFormState: FormState = {
						...defaultValue,
						...response,
					};
					setFormState(updatedFormState);
				}
			} catch (error) {
				console.error("Error fetching data", error);
			}
		}
		if (user) fetchData();
	}, [actions.users, user]);

	return (
		<FormStateContext.Provider value={value}>
			{children}
		</FormStateContext.Provider>
	);
}

export function useFormState() {
	const context = useContext(FormStateContext);
	if (!context) {
		throw new Error("useFormState must be used within a FormProvider");
	}
	return context;
}
