import React, { useContext, useState, useMemo } from "react";
import {
	TextField,
	FormGroup,
	Box,
	Paper,
	Container,
	IconButton,
	CircularProgress,
	useTheme,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import * as userActions from "../../../actions/userActions";
import {
	ChatButtonStyled,
	ChatItemContainer,
	HeaderTypography,
	StyledFieldTypography,
} from "../../../ux/chatStyles";
import chatHeaderCloseIcon from "../../../assets/chatHeaderClose.svg";
import createImageIcon from "../../../assets/createAnImageNew.svg";
import createImageIconLight from "../../../assets/createAnImageLight.svg";
import { bindActionCreators } from "../../../actions/actionCreators";
import { TodoContext } from "../../todoContext";
import { ToggleButton } from "./components/ToggleButton";
import { copyToClipboard } from "../../../utils/chatUtils";
import ImageGenerationFromPrompt from "./components/ImageGenerationFromPrompt";
import { ImagePrompt } from "../../../services/restService";
import Loader from "../../loader";

interface CreateImageItemProps {
	onSendMessage: (message: any) => void;
	onClose: () => void;
}

const CreateImageItem: React.FC<CreateImageItemProps> = ({
	onSendMessage,
	onClose,
}) => {
	const { dispatch } = useContext(TodoContext);
	const [loading, setLoading] = useState<boolean>(false);
	const [subject, setSubject] = useState<string>("");
	const [subjectOther, setSubjectOther] = useState<string>("");
	const [artisticStyle, setArtisticStyle] = useState<string[]>([]);
	const [artisticStyleOther, setArtisticStyleOther] = useState<string>("");
	const [elements, setElements] = useState<string>("");
	const [imageSize, setImageSize] = useState<string>("Square");
	const [additionalDetails, setAdditionalDetails] = useState<string>("No");
	const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
	const [response, setResponse] = useState<string[] | null>(null);
	const [copied, setCopied] = useState<boolean>(false);
	const [hasGeneratedImages, setHasGeneratedImages] = useState<boolean>(false);
	const [imageData, setImageData] = useState<ImagePrompt | null>(null);

	const theme = useTheme();
	const themeType = theme.palette.mode;

	const actions = useMemo(
		() => ({
			users: bindActionCreators(
				userActions,
				dispatch
			) as unknown as userActions.UserActions,
		}),
		[dispatch]
	);

	const handleSubmit = async (e: React.FormEvent) => {
		e.preventDefault();
		setLoading(true);

		const imageData = {
			subject: subjectOther || subject,
			artisticStyle: artisticStyleOther
				? [...artisticStyle, `Other: ${artisticStyleOther}`]
				: artisticStyle,
			additionalDetails,
			elements,
			imageSize,
		};

		setImageData(imageData);
		setIsSubmitted(true); // Indicate that the form has been submitted
		setLoading(false); // No longer loading since we're handing off to ImageGenerationFromPrompt
	};

	const handleImagesGenerated = (hasImages: boolean) => {
		setHasGeneratedImages(hasImages);
	};

	const subjectOptions = ["Nature", "Urban", "Fantasy", "Abstract", "Other"];
	const artisticStyles = [
		"Realistic",
		"Abstract",
		"Minimalist",
		"Watercolor",
		"Filmic",
		"Anime",
		"Other",
	];
	const styles = ["Portrait", "Landscape", "Square", "Vertical", "Other"];

	if (isSubmitted && imageData) {
		return (
			<ImageGenerationFromPrompt
				prompt={imageData}
				generateImageBasedOnPrompt={actions.users.generateImageBasedOnPrompt}
				onImagesGenerated={() => {
					handleImagesGenerated(true);
				}}
				onClose={onClose}
			/>
		);
	}

	return (
		<ChatItemContainer id="chat-container">
			<Paper
				sx={{
					marginTop: "20px",
					marginBottom: "20px",
					// background: "#1c1a21",
					borderRadius: "0.75rem",
					padding: "20px",
				}}
			>
				<Container
					sx={{
						display: "flex",
						alignItems: "center",
						padding: "10px",
					}}
				>
					<img
						src={themeType === "dark" ? createImageIcon : createImageIconLight}
						alt="Create"
						style={{ marginBottom: "2px" }}
					/>
					<HeaderTypography
						variant="h6"
						sx={{ display: "flex", alignItems: "center" }}
					>
						Create an Image
					</HeaderTypography>
					<IconButton onClick={onClose} sx={{ marginLeft: "auto" }}>
						<img src={chatHeaderCloseIcon} alt="Minimize" />
					</IconButton>
				</Container>
				{loading ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							width: "100%",
							padding: "20px",
						}}
					>
						<Loader />
					</div>
				) : (
					<form onSubmit={handleSubmit}>
						<FormGroup>
							<Box>
								<StyledFieldTypography gutterBottom>
									What is the main subject of the image?
								</StyledFieldTypography>
								{subjectOptions.map((option) => (
									<ToggleButton
										key={option}
										selected={subject.includes(option)}
										onClick={() => setSubject(option)}
									>
										{option}
									</ToggleButton>
								))}
								{subject.includes("Other") && (
									<TextField
										label="Please specify"
										value={subjectOther}
										onChange={(e) => setSubjectOther(e.target.value)}
										sx={{ mt: 2, width: "100%" }}
									/>
								)}
							</Box>
							<Box mt={2}>
								<StyledFieldTypography gutterBottom>
									Any particular artistic styles or influences?
								</StyledFieldTypography>
								{artisticStyles.map((style) => (
									<ToggleButton
										key={style}
										selected={artisticStyle.includes(style)}
										onClick={() =>
											setArtisticStyle(
												artisticStyle.includes(style)
													? artisticStyle.filter((s) => s !== style)
													: [...artisticStyle, style]
											)
										}
									>
										{style}
									</ToggleButton>
								))}
								{artisticStyle.includes("Other") && (
									<TextField
										label="Please specify"
										value={artisticStyleOther}
										onChange={(e) => setArtisticStyleOther(e.target.value)}
										sx={{ mt: 2, width: "100%" }}
									/>
								)}
							</Box>
							<Box mt={2}>
								<StyledFieldTypography gutterBottom>
									Are there specific elements or details you want included?
								</StyledFieldTypography>
								<TextField
									fullWidth
									label="Elements"
									variant="outlined"
									value={elements}
									onChange={(e) => setElements(e.target.value)}
									sx={{ mt: 2 }}
								/>
							</Box>
							<Box mt={2}>
								<StyledFieldTypography gutterBottom>
									Do you have a preference for the size and orientation of the
									image?
								</StyledFieldTypography>
								{styles.map((option) => (
									<ToggleButton
										key={option}
										selected={imageSize === option}
										onClick={() => setImageSize(option)}
									>
										{option}
									</ToggleButton>
								))}
							</Box>
							<Box mt={2}>
								<StyledFieldTypography gutterBottom>
									Any other details to consider?
								</StyledFieldTypography>
								<TextField
									fullWidth
									label="Additional Details"
									variant="outlined"
									value={additionalDetails}
									onChange={(e) => setAdditionalDetails(e.target.value)}
									sx={{ mt: 2 }}
								/>
							</Box>
							<Box mt={2}>
								<ChatButtonStyled
									variant="contained"
									type="submit"
									disabled={loading} // Disable the button when loading
								>
									{loading ? (
										<CircularProgress size={24} color="secondary" />
									) : (
										"Submit"
									)}
								</ChatButtonStyled>
							</Box>
						</FormGroup>
					</form>
				)}
			</Paper>
		</ChatItemContainer>
	);
};

export default CreateImageItem;
