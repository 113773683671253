import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
	signInWithPopup,
	getAdditionalUserInfo,
	deleteUser,
	GoogleAuthProvider,
} from "firebase/auth";
import * as userActions from "../actions/userActions";
import { auth } from "../firebase/firebase-config";
import { useNavigate } from "react-router-dom";
import GoogleIcon from "../assets/google.svg";
import GoogleIconLight from "../assets/googleLight.svg";
import AppleIcon from "../assets/apple.svg";
import logo from "../assets/Vector.svg";
import logoLight from "../assets/VectorLight.svg";
import userIcon from "../assets/user.svg";
import {
	BoxStyled,
	ButtonContainerStyled,
	ButtonStyled,
	CheckboxStyled,
	LoginButtonStyled,
} from "../ux/mainAuthPageStyles";
import { FormControlLabel, Typography, Snackbar, Box } from "@mui/material";
import useCustomUserData from "../hooks/useCustomUserData";
import { UserContext } from "../context/UserDataContext";
import SignUpForm from "./signUpForm";
import { bindActionCreators } from "../actions/actionCreators";
import { TodoContext } from "./todoContext";
import DesktopLeftSideBackgroundImage from "../assets/Frame_2.png";
import Loader from "./loader";

// const UserIcon = () => {
// 	return <img src={userIcon} alt="User Icon" style={{ width: "1.5rem" }} />;
// };

const DesktopLeftContainer = () => {
	return (
		<Box
			sx={{
				width: "60%",
				height: "90%",
				backgroundImage: `url(${DesktopLeftSideBackgroundImage})`,
				backgroundSize: "contain",
				backgroundRepeat: "no-repeat",
				backgroundPosition: "top",
				padding: "2rem",
				marginTop: "1rem",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					height: "100%",
				}}
			>
				{/* <Typography
					variant="body1"
					sx={{
						color: "#FFF",
						textAlign: "center",
						marginBottom: "1rem",
						width: "65%",
					}}
				>
					Ready to unleash the power of AI? Just say the magic words (aka your
					username and password)
				</Typography> */}
			</Box>
		</Box>
	);
};

const MainAuthPage = () => {
	const { dispatch } = useContext(TodoContext);
	const [error, setError] = useState("");
	const navigate = useNavigate();
	const { customUserData, isLoading, redirectToAppropriatePage } =
		useCustomUserData();
	// const { userData } = React.useContext(UserContext);
	const theme = useTheme();
	const themeType = theme.palette.mode;
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

	const actions = useMemo(
		() => ({
			users: bindActionCreators(
				userActions,
				dispatch
			) as unknown as userActions.UserActions,
		}),
		[dispatch]
	);

	useEffect(() => {
		const currentPath = window.location.pathname;
		// Redirect to the appropriate page based on the user's profile only if the route is the root
		// This is to avoid redirecting from the onboarding page to the root page
		if (currentPath === "/") redirectToAppropriatePage(currentPath);
	}, [customUserData, isLoading]);

	const signInWithGoogle = () => {
		const provider = new GoogleAuthProvider();
		provider.setCustomParameters({ prompt: "select_account" });

		signInWithPopup(auth, provider)
			.then((result) => {
				const additionalUserInfo = getAdditionalUserInfo(result);
				if (additionalUserInfo?.isNewUser) {
					actions.users
						.signUp({
							email: result?.user?.email as string,
							name: result?.user?.displayName as string,
						})
						.then(() => {
							navigate("/post-signup"); // Redirect to the signup page
						})
						.catch(async (error) => {
							// If there's an error signing up, delete the user from Firebase
							if (result.user) {
								await deleteUser(result.user);
							}
							throw error; // Re-throw the error after deleting the user
						});
				} else {
					redirectToAppropriatePage("/"); // Redirect to the appropriate page
				}
				setError(""); // Clear error on successful operation
			})
			.catch((error) => {
				setError((error as Error).message); // Set error message
			});
	};

	if (isLoading) {
		return (
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					height: "100vh",
					width: "100%",
				}}
			>
				<Loader />
			</div>
		);
	}

	return (
		// if the user is on desktop, show the desktop layout (DesktopLeftContainer and BoxStyled, inside a Box)
		// if the user is on mobile, show the BoxStyled only
		<Box
			sx={{
				display: "flex",
				flexDirection: "row",
				width: "100%",
				height: "100vh",
				overflow: "hidden",
				background: isDesktop ? theme.palette.grey[500] : "",
			}}
		>
			{isDesktop ? <DesktopLeftContainer /> : null}
			<BoxStyled
				sx={{
					background: isDesktop ? theme.palette.secondary.main : "",
					width: isDesktop ? "50%" : "100%",
					margin: "1rem",
					borderRadius: "1rem",
				}}
			>
				<img
					src={themeType === "light" ? logoLight : logo}
					alt="Logo"
					style={{ width: "8rem", marginTop: "2rem" }}
				/>
				<ButtonContainerStyled>
					<Typography variant="h5">Create an Account</Typography>
					<SignUpForm />
					<LoginButtonStyled variant="text" onClick={() => navigate("/signin")}>
						or{" "}
						<p
							style={{
								color: "#DCF032",
								display: "inline",
								margin: "0 0.5rem",
							}}
						>
							Log In
						</p>
					</LoginButtonStyled>
					{/* make the apple and google buttons inline */}
					<div
						style={{ display: "flex", justifyContent: "center", gap: "1rem" }}
					>
						{/* <ButtonStyled variant="outlined" onClick={() => signInWithGoogle()}>
							<img
								src={AppleIcon}
								alt="Apple Icon"
								style={{ width: "1.25rem" }}
							/>
						</ButtonStyled> */}

						<ButtonStyled variant="outlined" onClick={() => signInWithGoogle()}>
							<img
								src={themeType === "light" ? GoogleIconLight : GoogleIcon}
								alt="Google Icon"
								style={{ width: "1.25rem" }}
							/>
						</ButtonStyled>
					</div>

					<Typography
						variant="body2"
						style={{ marginTop: "1rem", textAlign: "center" }}
					>
						By creating an account you agree to Cockpit-AI’s{" "}
						<a
							href="http://www.cockpit-ai.com/terms-of-service"
							style={{ color: "#DCF032" }}
						>
							Terms of Service
						</a>{" "}
						&{" "}
						<a
							href="http://www.cockpit-ai.com/privacy-policy"
							style={{ color: "#DCF032" }}
						>
							Privacy Policy
						</a>
					</Typography>
				</ButtonContainerStyled>
				<Snackbar
					open={!!error}
					autoHideDuration={4000}
					onClose={() => setError("")}
					message={error}
				/>
			</BoxStyled>
		</Box>
	);
};

export default MainAuthPage;
