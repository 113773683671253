import React, { createContext, useState, ReactNode } from "react";

export interface PostSignupData {
	name?: string;
	phone?: string;
	businessType?: string;
	companyName?: string;
	websiteURL?: string;
	teamSize?: string;
	roleOnTeam?: string;
	aboutBrand?: string;
	businessGoals?: string;
	competitors?: string[];
	idealCustomer?: string;
	marketingChannels?: {
		[key: string]: string;
	};
	flagshipProductsServices?: string;
}

interface PostSignupContextProps {
	currentStep: number;
	postSignupData: PostSignupData;
	setCurrentStep: (step: number) => void;
	setPostSignupData: (
		data: PostSignupData | ((prevState: PostSignupData) => PostSignupData)
	) => void;
}

export const PostSignupContext = createContext<PostSignupContextProps>({
	currentStep: 1,
	postSignupData: {},
	setCurrentStep: () => {
		return;
	},
	setPostSignupData: () => {
		return;
	},
});

export const PostSignupProvider = ({ children }: { children: ReactNode }) => {
	const [currentStep, setCurrentStep] = useState(1);
	const [postSignupData, setPostSignupDataData] = useState<PostSignupData>({});
	console.log(currentStep);

	return (
		<PostSignupContext.Provider
			value={{
				currentStep,
				postSignupData: postSignupData,
				setCurrentStep,
				setPostSignupData: setPostSignupDataData,
			}}
		>
			{children}
		</PostSignupContext.Provider>
	);
};
