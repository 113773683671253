import React, { useContext, useState, useMemo, useEffect } from "react";
import {
	Box,
	Button,
	Grid,
	Typography,
	Snackbar,
	Alert,
	TextField,
	Paper,
	useTheme,
	useMediaQuery,
	styled,
	Select,
	MenuItem,
	FormControl,
	InputLabel,
	FormHelperText,
	CircularProgress,
} from "@mui/material";
import { StepIndicator } from "./PostSignupStepIndicator";
import { PostSignupContext } from "../../context/PostSingupContext";
import { TodoContext } from "../todoContext";
import { bindActionCreators } from "../../actions/actionCreators";
import * as userActions from "../../actions/userActions";
import IllustrationImage from "../../assets/Layer_2.png";
import { useNavigate } from "react-router-dom";

interface SectionPaperProps {
	title: string;
	description: string;
	children: React.ReactNode;
}

const SectionPaper: React.FC<SectionPaperProps> = ({
	title,
	description,
	children,
}) => (
	<Paper
		elevation={3}
		sx={{
			padding: 2,
			borderRadius: 2,
			backgroundColor: (theme) => theme.palette.grey[100],
			marginTop: 2,
			width: "100%",
		}}
	>
		<Typography variant="h6" sx={{ mb: 2, textAlign: "left" }}>
			{title}
		</Typography>
		<Typography variant="body1" sx={{ mb: 2, textAlign: "left" }}>
			{description}
		</Typography>
		{children}
	</Paper>
);

interface FormData {
	aboutBrand?: string;
	businessGoals?: string;
	competitors?: string[];
	idealCustomer?: string;
	marketingChannels?: {
		[key: string]: string;
	};
	flagshipProductsServices?: string;
	businessType?: string;
	userRole?: string; // Added userRole field
}

const validateUrl = (url: string) => {
	const urlRegex = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;
	return urlRegex.test(url);
};

const ScrollableDiv = styled("div")({
	overflowY: "auto",
	height: "calc(100vh - 6rem)",
});

const businessTypes = [
	"Tech - SaaS",
	"Tech - Hardware",
	"Finance",
	"Food & Beverage",
	"Entertainment",
	"Hospitality & Tourism",
	"Manufacturing",
	"E-commerce",
	"Marketing",
	"Real Estate",
	"Healthcare",
	"Energy & Utilities",
	"Logistics",
];

const userRoles = [
	"C-Level",
	"Marketing",
	"Sales",
	"Content",
	"Creative",
	"Analytics",
	"HR",
	"Customer Service",
	"Product Manager",
	"Project Manager",
	"Designer",
	"Other",
];

export const PostSignupStepThree = () => {
	const { dispatch } = useContext(TodoContext);
	const { postSignupData, setPostSignupData, setCurrentStep } =
		useContext(PostSignupContext);
	const [formData, setFormData] = useState<FormData>({
		aboutBrand: postSignupData.aboutBrand || "",
		businessGoals: postSignupData.businessGoals || "",
		competitors: postSignupData.competitors || ["", "", ""],
		idealCustomer: postSignupData.idealCustomer || "",
		marketingChannels: {
			facebook: postSignupData.marketingChannels?.facebook || "",
			instagram: postSignupData.marketingChannels?.instagram || "",
			linkedin: postSignupData.marketingChannels?.linkedin || "",
			twitter: postSignupData.marketingChannels?.twitter || "",
			youtube: postSignupData.marketingChannels?.youtube || "",
		},
		flagshipProductsServices: postSignupData.flagshipProductsServices || "",
		businessType: postSignupData.businessType || "",
		userRole: postSignupData.roleOnTeam || "", // Initialize userRole
	});

	const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
	const [snackbarMessage, setSnackbarMessage] = useState<string>("");
	const [businessTypeError, setBusinessTypeError] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false); // Add loading state

	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

	const navigate = useNavigate();

	const actions = useMemo(
		() => ({
			users: bindActionCreators(
				userActions,
				dispatch
			) as unknown as userActions.UserActions,
		}),
		[dispatch]
	);

	useEffect(() => {
		const fetchUserData = async () => {
			try {
				const userData = await actions.users.getMe();
				setFormData({
					aboutBrand: userData.onboardingData.aboutBrand || "",
					businessGoals: userData.onboardingData.businessGoals || "",
					competitors: [
						userData.onboardingData.competitor1 || "",
						userData.onboardingData.competitor2 || "",
						userData.onboardingData.competitor3 || "",
					],
					idealCustomer: userData.onboardingData.idealCustomer || "",
					marketingChannels: {
						facebook: userData.onboardingData.facebook || "",
						instagram: userData.onboardingData.instagram || "",
						linkedin: userData.onboardingData.linkedin || "",
						tiktok: userData.onboardingData.tiktok || "",
						pinterest: userData.onboardingData.pinterest || "",
						youtube: userData.onboardingData.youtube || "",
						x: userData.onboardingData.x || "",
					},
					flagshipProductsServices:
						userData.onboardingData.flagshipProductsServices || "",
					businessType: userData.businessType || "",
					userRole: userData.roleOnTeam || "",
				});
			} catch (error) {
				console.error("Failed to fetch user data:", error);
			}
		};

		fetchUserData();
	}, [actions.users]);

	const handleInputChange = (key: keyof FormData, value: string) => {
		setFormData((prevState) => ({
			...prevState,
			[key]: value,
		}));
	};
	const validateForm = () => {
		const errors: string[] = [];

		if (formData.competitors) {
			formData.competitors.forEach((competitor, index) => {
				if (competitor && !validateUrl(competitor)) {
					errors.push(`Competitor #${index + 1} is not a valid URL.`);
				}
			});
		}

		if (formData.marketingChannels) {
			Object.entries(formData.marketingChannels).forEach(([channel, url]) => {
				if (url && !validateUrl(url)) {
					errors.push(
						`${
							channel.charAt(0).toUpperCase() + channel.slice(1)
						} URL is not valid.`
					);
				}
			});
		}

		if (!formData.businessType) {
			errors.push("Business Type is required.");
			setBusinessTypeError(true);
		} else {
			setBusinessTypeError(false);
		}

		if (errors.length > 0) {
			setSnackbarMessage(errors.join(" "));
			setOpenSnackbar(true);
			return false;
		}

		return true;
	};

	const handleNext = async () => {
		if (!validateForm()) return;

		setLoading(true); // Set loading state to true

		try {
			await actions.users.saveOnboardingData(formData);
			await actions.users.completeSignUp({
				...postSignupData,
				businessType: formData.businessType,
				roleOnTeam: formData.userRole,
			});
			actions.users.getMe();
			actions.users.getOnboardingData();
			setPostSignupData({
				...postSignupData,
				aboutBrand: formData.aboutBrand,
				businessGoals: formData.businessGoals,
				competitors: formData.competitors,
				idealCustomer: formData.idealCustomer,
				businessType: formData.businessType,
				roleOnTeam: formData.userRole,
			});
			console.log(formData);
			setTimeout(() => {
				setLoading(false); // Set loading state to false after completion
				navigate("/");
			}, 2000);
		} catch (error: any) {
			console.error("An error occurred:", error);
			setSnackbarMessage(error.message);
			setOpenSnackbar(true);
			setLoading(false); // Set loading state to false in case of error
		}
	};

	const handleBack = () => {
		setCurrentStep(2);
	};

	return (
		<ScrollableDiv>
			<Grid container spacing={isDesktop ? 4 : 0}>
				{isDesktop && (
					<Grid item md={5}>
						<Box
							sx={{
								position: "fixed",
								left: "0.5rem",
								top: "0",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "100%",
							}}
						>
							<img
								src={IllustrationImage}
								alt="Illustration"
								style={{
									maxHeight: "100%",
									maxWidth: "100%",
									borderRadius: 2,
									height: "90%",
								}}
							/>
						</Box>
					</Grid>
				)}
				<Grid item xs={12} md={7}>
					<Box
						sx={{
							maxWidth: isDesktop ? "90vw" : 400,
							mx: "auto",
							padding: isDesktop ? 6 : 4,
							textAlign: "center",
						}}
					>
						{!isDesktop && <StepIndicator />}
						<Typography variant="h4" sx={{ mb: 4, mt: 4, textAlign: "left" }}>
							Tell us more
						</Typography>
						<Typography
							variant="body1"
							sx={{
								mt: 2,
								textAlign: "left",
								fontSize: "1.2em",
								color: "primary.contrastText",
							}}
						>
							This information will help us customize our AI to your specific
							needs.
						</Typography>
						<Grid container spacing={2} sx={{ mt: 4 }}>
							<SectionPaper
								title="Business Type"
								description="What is your business's industry?"
							>
								<FormControl fullWidth error={businessTypeError}>
									<InputLabel id="business-type-label">
										Business Type
									</InputLabel>
									<Select
										labelId="business-type-label"
										value={formData.businessType || ""}
										onChange={(e) =>
											handleInputChange("businessType", e.target.value)
										}
										label="Business Type"
									>
										{businessTypes.map((type) => (
											<MenuItem key={type} value={type}>
												{type}
											</MenuItem>
										))}
									</Select>
									{businessTypeError && (
										<FormHelperText>Business Type is required.</FormHelperText>
									)}
								</FormControl>
							</SectionPaper>
							<SectionPaper title="Your Role" description="What’s your role?">
								<FormControl fullWidth>
									<InputLabel id="user-role-label">Your Role</InputLabel>
									<Select
										labelId="user-role-label"
										value={formData.userRole || ""}
										onChange={(e) =>
											handleInputChange("userRole", e.target.value)
										}
										label="User Role"
									>
										{userRoles.map((role) => (
											<MenuItem key={role} value={role}>
												{role}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</SectionPaper>
							<SectionPaper
								title="Tell us about your business"
								description="You probably have an ‘About’ page on your website, feel free to just copy and paste it here"
							>
								<TextField
									fullWidth
									multiline
									minRows={4}
									label="About your business"
									placeholder="Give us a snapshot of what your brand is all about."
									variant="outlined"
									value={formData.aboutBrand}
									onChange={(e) =>
										handleInputChange("aboutBrand", e.target.value)
									}
								/>
							</SectionPaper>

							<SectionPaper
								title="Ideal Customer"
								description="How would you describe your ideal customer?"
							>
								<TextField
									fullWidth
									multiline
									minRows={4}
									label="Ideal Customer"
									variant="outlined"
									value={formData.idealCustomer}
									onChange={(e) =>
										handleInputChange("idealCustomer", e.target.value)
									}
								/>
							</SectionPaper>
							<SectionPaper
								title="Flagship Products & Services"
								description="What are your leading products or services?"
							>
								<TextField
									fullWidth
									multiline
									minRows={4}
									label="Flagship products"
									variant="outlined"
									value={formData.flagshipProductsServices}
									onChange={(e) =>
										handleInputChange(
											"flagshipProductsServices",
											e.target.value
										)
									}
								/>
							</SectionPaper>
						</Grid>
						<Snackbar
							open={openSnackbar}
							autoHideDuration={6000}
							onClose={() => setOpenSnackbar(false)}
						>
							<Alert
								onClose={() => setOpenSnackbar(false)}
								severity="error"
								sx={{ width: "100%" }}
							>
								{snackbarMessage}
							</Alert>
						</Snackbar>
						<Box
							sx={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								mt: 6,
								padding: isDesktop ? "4rem 0" : "0 1rem",
								width: isDesktop ? "100%" : "auto",
							}}
						>
							<Box
								sx={{
									display: "flex",
									justifyContent: "flex-start",
									width: "50%",
								}}
							>
								<Button
									variant="outlined"
									onClick={handleBack}
									sx={{
										mr: 1,
										borderColor: "#29273E",
										color: "primary.contrastText",
									}}
								>
									Back
								</Button>
								<Button
									variant="contained"
									onClick={handleNext}
									disabled={loading} // Disable button when loading
									sx={{ ml: 1, color: theme.palette.secondary.contrastText }}
								>
									{loading ? (
										<CircularProgress size={24} color="inherit" />
									) : (
										"Next"
									)}
								</Button>
							</Box>
							{isDesktop && <StepIndicator />}
						</Box>
					</Box>
				</Grid>
			</Grid>
		</ScrollableDiv>
	);
};
