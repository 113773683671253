import React from "react";
import {
	StyledList,
	StyledListItem,
	StyledListItemButton,
	StyledListItemIcon,
	StyledListItemText,
} from "../../ux/chatStyles";
import { ListItemIcon } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"; // Import ArrowForwardIosIcon

export type MenuItem<T = object> = {
	icon: string;
	primary: string;
	secondary: string;
	component?: React.ComponentType<T>;
};

type MenuListProps = {
	items: MenuItem<unknown>[];
	onItemClick: (item: MenuItem<unknown>) => void;
	activeItem: MenuItem | null;
	initialLoad: boolean;
	isDesktop: boolean;
};

export const MenuList: React.FC<
	MenuListProps & { onItemClick: (item: MenuItem) => void }
> = ({ items, onItemClick, activeItem, initialLoad, isDesktop }) => (
	<StyledList
		style={{
			display: initialLoad && isDesktop ? "grid" : "flex",
			flexDirection: "column",
			alignItems: "center",
			gridTemplateColumns: initialLoad && isDesktop ? "1fr 1fr" : "none",
			gap: "0.5rem",
			height: initialLoad ? "auto" : "100%",
			justifyContent: "flex-start",
			// overflow: "auto",
			padding: "0.5rem 0",
		}}
	>
		{items.map((item, index) => (
			<StyledListItem
				key={index}
				disablePadding
				style={{
					width: "90%",
					maxWidth: "500px",
				}}
			>
				<StyledListItemButton
					onClick={() => onItemClick(item)}
					style={{
						borderLeft: `3px solid ${
							item.primary === activeItem?.primary ? "#DCF032" : "transparent"
						}`,
						borderRadius: "0.5rem",
						width: "100%",
					}}
				>
					<StyledListItemIcon>
						<img src={item.icon} alt={item.primary} />
					</StyledListItemIcon>
					<StyledListItemText
						primary={item.primary}
						secondary={item.secondary}
					/>
					<ListItemIcon style={{ minWidth: "auto", marginRight: "0.5rem" }}>
						<ArrowForwardIosIcon
							style={{ fontSize: "1rem", color: "#c8c5d0" }}
						/>
					</ListItemIcon>
				</StyledListItemButton>
			</StyledListItem>
		))}
	</StyledList>
);
