import React, { useContext, useState } from "react";
import { PostSignupContext } from "../../context/PostSingupContext";
import {
	Box,
	Button,
	Grid,
	Typography,
	useTheme,
	useMediaQuery,
} from "@mui/material";
import { StepIndicator } from "./PostSignupStepIndicator";
// import EcommerceIcon from "../../assets/ecommerce.svg";
// import TechnologyIcon from "../../assets/technology.svg";
// import MarketingIcon from "../../assets/marketing.svg";
// import FinanceIcon from "../../assets/finance.svg";
// import RealEstateIcon from "../../assets/realestate.svg";
// import FoodBeverageIcon from "../../assets/food.svg";
// import HealthcareIcon from "../../assets/healthcare.svg";
// import EntertainmentIcon from "../../assets/entertainment.svg";
// import EnergyIcon from "../../assets/energy.svg";
// import HospitalityIcon from "../../assets/hospitality.svg";
// import LogisticsIcon from "../../assets/logistics.svg";
// import ManufacturingIcon from "../../assets/manufacturing.svg";
import IllustrationImage from "../../assets/side_postSignup.png";

export const PostSignupStepOne = () => {
	const { postSignupData, setCurrentStep, setPostSignupData } =
		useContext(PostSignupContext);
	// const [selectedBusinessType, setSelectedBusinessType] = useState<string>(
	// 	postSignupData.businessType || ""
	// );

	const theme = useTheme();
	const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

	const handleNext = () => {
		// if (selectedBusinessType) {
		// 	setPostSignupData((prevState) => ({
		// 		...prevState,
		// 		businessType: selectedBusinessType,
		// 	}));
		setCurrentStep(2);
		// }
	};

	// const handleCloseSnackbar = () => {
	// 	setOpenSnackbar(false);
	// };

	return (
		<Box
			sx={{
				height: "calc(100vh - 6rem)", // Adjust height for desktop
				overflow: "auto",
			}}
		>
			<Grid container spacing={isDesktop ? 4 : 0}>
				{isDesktop && (
					<Grid item md={4}>
						<Box
							sx={{
								position: "fixed",
								left: "0.5rem",
								top: "5rem",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								height: "calc(100vh - 6rem)",
							}}
						>
							<img
								src={IllustrationImage}
								alt="Illustration"
								style={{
									maxWidth: "100%",
									maxHeight: "100%",
									borderRadius: 2,
									height: "90%",
								}}
							/>
						</Box>
					</Grid>
				)}

				<Grid item xs={12} md={7}>
					<Box
						sx={{
							maxWidth: isDesktop ? "80vw" : 400,
							mx: "auto",
							padding: isDesktop ? 6 : 4, // Adjust padding for desktop
							paddingTop: isDesktop ? 10 : 4,
							textAlign: isDesktop ? "left" : "center",
							overflowY: "auto",
						}}
					>
						{!isDesktop && <StepIndicator />}
						<Typography variant="h4" sx={{ mb: 4, textAlign: "left" }}>
							Welcome aboard!
						</Typography>
						<Typography variant="h6" sx={{ mb: 4, textAlign: "left" }}>
							Before we take off, we just need some basic information to
							personalize your cockpit experience. <br />
							It’ll take less than a minute.
						</Typography>
					</Box>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							mt: 6,
							padding: isDesktop ? "0 4rem" : "0 1rem",
							alignItems: "center",
							marginTop: isDesktop ? "0" : "",
						}}
					>
						<Button
							variant="contained"
							onClick={handleNext}
							sx={{ color: theme.palette.secondary.contrastText }}
						>
							Continue
						</Button>
						{isDesktop && <StepIndicator />}
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};
